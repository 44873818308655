<template>
  <div class="multisteps-form__panel p-3 border-radius-xl bg-white form-check" data-animation="FadeIn">
    <h5 class="font-weight-normal">{{ getCustomer.fullName }}, UC604 Form</h5>
    <h6>Request to lift a sanction for the following action:
      {{ getActionToMandate ? getActionToMandate.subject : "" }}</h6>
    <div class="multisteps-form__content">
      <div class="mt-2 row" style="display: flex; justify-content: center; min-width: 900px">
        <div class="form-check">
          <div class="card-header pb-0 p-3">
            UC participant has a saction applied for failing to meet the set
            requirement. This is a request to DWP to review that decision for
            the following reasons:
          </div>
          <form class="card-body">
            <div class="form-check">
              <div class="row">
                <div class="col-12 mt-2 text-start">
                  <div class="flex-container">
                    <div>Participant has now fully complied:</div>
                    <input class="form-check-input" type="checkbox" name="satisfy" v-model="store.participantComplied" />
                  </div>
                  <p class="required-colour">{{ store.errors.participantComplied }}</p>
                </div>
                <div class="col-12 mt-2 text-start">
              <div class="flex-container">
                <div>It is no longer appropriate for the participant to undertake that activity:</div>
                <input class="form-check-input" type="checkbox" name="Appropriate" v-model="store.activityNoLongerAppropriate" />
              </div>
              <p class="required-colour">{{ store.errors.activityNoLongerAppropriate }}</p>
            </div>
            </div>
            </div>

            <div class="form-check">
            <div class="row">
              <div class="col-12 mt-2 text-start">
                <div class="flex-container">
                  <div>Participant has fully complied with alternative activity on the UC193:</div>
                  <input class="form-check-input" type="checkbox" name="Alternative" v-model="store.alternativeActivityComplied" />
                </div>
                <p class="required-colour">{{ store.errors.alternativeActivityComplied }}</p>
              </div>
              <div class="col-12 mt-2 text-start">
                <div class="flex-container">
                  <div>I consider that the participant has not fully complied:</div>
                  <input class="form-check-input" type="checkbox" name="Consider" v-model="store.considerNotFullyComplied" />
                </div>
                <p class="required-colour">{{ store.errors.considerNotFullyComplied }}</p>
              </div>
            </div>
            </div>
            <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-check">
                <div class="mt-2 text-start">
                  <span>Date of compliance or activity no longer applicable</span>
                  <input class="form-control datepicker" placeholder="Please select date" type="date"
                    onfocus="focused(this)" onfocusout="defocused(this)" v-model="store.activityNoLongerApplicableDate" />
                </div>
                <p class="required-colour date-format">
                  {{ store.errors.activityNoLongerApplicableDate }}
                </p>
              </div>
            </div>

              <div class="col-12 col-md-6">
                <div class="form-check">
                  <div class="mt-2 text-start">
                    <span>Date of sanction decision (if known)</span>
                    <input class="form-control datepicker" placeholder="Please select date" type="date"
                      onfocus="focused(this)" onfocusout="defocused(this)" v-model="store.sanctionDecisionDate" />
                  </div>
                  <p class="required-colour date-format">
                    {{ store.errors.sanctionDecisionDate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-check">
                <div class="mt-2 text-start">
                  <span>Date of sanction (if known) From</span>
                  <input class="form-control datepicker" placeholder="Please select date" type="date" onfocus="focused(this)"
                    onfocusout="defocused(this)" v-model="store.sanctionStartDate" />
                </div>
                <p class="required-colour date-format">
                  {{ store.errors.sanctionStartDate }}
                </p>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-check">
                <div class="mt-2 text-start">
                  <span>Date of sanction (if known) To</span>
                  <input class="form-control datepicker" placeholder="Please select date" type="date" onfocus="focused(this)"
                    onfocusout="defocused(this)" v-model="store.sanctionEndDate" :min="store.sanctionStartDate"
                    :disabled="!store.sanctionStartDate" />
                </div>
                <p class="required-colour date-format">
                  {{ store.errors.sanctionEndDate }}
                </p>
              </div>
            </div>
          </div>

            <div class="form-check">
              <div class="mt-1 col-12 col-sm-10 mt-sm-2 text-start">
                <div>
                  <span>I confirm that the above is a full and accurate
                    statement</span>
                  <div class="form-check-inline ps-5">
                    <input id="confirm-604-form-checkbox-part-1" class="form-check-input" type="checkbox" v-model="store.uC604ConfirmStatement" />
                    <label class="form-check-label"> Yes </label>
                  </div>
                </div>
                <span class="required-colour">{{
                  store.errors.uC604ConfirmStatement
                }}</span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="button-row d-flex justify-content-between">
          <ArgonButton type="button" color="light" variant="gradient" class="js-btn-prev" @click="resetData()">Close
          </ArgonButton>
          <ArgonButton v-if="store.participantComplied || store.activityNoLongerAppropriate || store.alternativeActivityComplied" :disabled="!store.meta.valid" type="button" class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
            title="Next" @click="this.$parent.skipStep()">
            Next
          </ArgonButton>
          <ArgonButton v-else :disabled="!store.meta.valid" type="button" class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
                    title="Next" @click="this.$parent.nextStep()">
            Next
          </ArgonButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArgonButton from "@/components/Argoncomponents/ArgonButton.vue";
import { uc604FormStore } from "../../../store/UC604FormStore";
import { uc604FormStore2 } from "../../../store/UC604FormStore2";
import { useCommonStore } from "@/store/commonStore";
const store = uc604FormStore();
const store2 = uc604FormStore2();
const commonStore = useCommonStore()
const { getCustomer, setUc604Visibility } = useCommonStore();

function resetData() {
  setUc604Visibility(false);
  store.resetData();
  store2.resetData();
}

function getLatest604() {
  const dwp604Records = commonStore.getActionToMandate.mandationRecord.dwp604Records
  if (dwp604Records.length > 0) {
    return dwp604Records[dwp604Records.length - 1]
  }
}

onMounted(() => {
  // It is possible that there isn't a saved 604 at this point
  const latest = getLatest604()
  const recordId = latest?.recordId ?? null

  if (store.recordId != null && store.recordId != recordId) {
    store.resetData()
    store2.resetData()
  }

  if (latest != null && !store.loadedSavedForm) {
    store.loadFromSavedRecord(latest)
  }
})

</script>

<script>
import { mapState } from "pinia";
import { onMounted } from "vue";
// import { onMounted } from 'vue';

export default {
  data() {
    return {
      test: true
    }
  },
  computed: {
    ...mapState(useCommonStore, ["getActionToMandate"]),
  },
};
</script>

<style scoped>
.required-colour {
  color: red;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.form-check-input {
  margin-left: 10px;
}

.date-format {
  padding-right: 13.5rem !important;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}
</style>
