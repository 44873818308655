import axios from "axios";
import { TryGetCachedToken } from "../router/TokenManger";
import { useMainStore } from "../store/mainStore";
const BaseUrl = process.env.VUE_APP_CALENDAR_BASE_URL;
var envVars = process.env;
export default {
  async GetAllMeetings(customerId) {
    try {
      if (envVars.VUE_APP_AUTH_ON == "true") {
        const token = await TryGetCachedToken();
        axios.interceptors.request.use((config) => {
          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
        });
      }
      const resp = await axios({
        method: "get",
        url:
          BaseUrl +
          envVars.VUE_APP_GET_ALL_MEETINGS.replace("{customerId}", customerId),
        responseType: "json",
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      return resp;
    } catch (error) {
      return error;
    }
  },
  async GetUpcomingMeetings(customerId) {
    const s = useMainStore();
    try {
      if (envVars.VUE_APP_AUTH_ON == "true") {
        const token = await TryGetCachedToken();
        axios.interceptors.request.use((config) => {
          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
        });
      }
      const resp = await axios({
        method: "get",
        url:
          BaseUrl +
          s.getUpcomingMeetingsUrl.replace(
            "{customerId}",
            customerId
          ),
        responseType: "json",
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      return resp;
    } catch (error) {
      return error;
    }
  },
  async GetPastMeetings(customerId) {
    try {
      if (envVars.VUE_APP_AUTH_ON == "true") {
        const token = await TryGetCachedToken();
        axios.interceptors.request.use((config) => {
          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
        });
      }
      const resp = await axios({
        method: "get",
        url:
          BaseUrl +
          envVars.VUE_APP_GET_PAST_MEETINGS.replace("{customerId}", customerId),
        responseType: "json",
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      return resp;
    } catch (error) {
      return error;
    }
  },
};
