import axios from 'axios';
import { TryGetCachedToken } from "../router/TokenManger";
const envVars = process.env;
const BaseUrl = process.env.VUE_APP_ACTIONS_BASE_URL;


async function authorize() {
    if(envVars.VUE_APP_AUTH_ON == "true"){
        const token = await TryGetCachedToken();
        axios.interceptors.request.use(config => {
            config.headers["Authorization"] = `Bearer ${token}`;
            return config;
        });
    }
}

/**
 * This service has been created during the mandation document refactor.
 * It includes any additional methods that have been added to the mandation process.
 * Any new methods created for mandation should be added into here.
 */
export default {

    /**
     * Generates a MAN preview document.
     * @param {*} customerId - the customer the MAN document relates to.
     * @param {*} actionPlanItemId - the action plan item the MAN document relates to.
     * @param {*} formData - The data of the form to be created.
     * @returns the response of the request.
     */
    async createMANPreviewDocument(customerId, actionPlanItemId, formData) {
        await authorize()
        var resp = await axios({ 
            method: 'POST',
            headers: { 
                'content-type': 'application/json',
            },
            data: formData,
            url: BaseUrl + envVars.VUE_APP_POST_MANDATION_PREVIEW
                .replace("{customerId}", customerId)
                .replace("{actionPlanItemId}", actionPlanItemId)});
        return resp;
    },

    /**
     * Generates the final version of a MAN.
     * @param {*} customerId - the customer the MAN document relates to.
     * @param {*} actionPlanItemId - the action plan item the MAN document relates to.
     * @param {*} formData - The data of the form to be created.
     * @returns the response of the request.
     */
    async createMANRecord(customerId, actionPlanItemId, formData) {
        await authorize()
        var resp = await axios({ 
            method: 'POST',
            headers: { 
                'content-type': 'application/json',
            },
            data: formData,
            url: BaseUrl + envVars.VUE_APP_GET_CUSTOMER_MANDATION_SAVE_MAN_RECORD
                .replace("{customerId}", customerId)
                .replace("{actionPlanItemId}", actionPlanItemId)});
        return resp;
    },

    /**
     * Generates a DWP-604 preview document.
     * @param {*} customerId - the customer the MAN document relates to.
     * @param {*} actionPlanItemId - the action plan item the MAN document relates to.
     * @param {*} formData - The data of the form to be created.
     * @returns the response of the request.
     */
    async createDwp604PreviewDocument(customerId, actionPlanItemId, formData) {
        await authorize()
        var resp = await axios({ 
            method: 'POST',
            headers: { 
                'content-type': 'application/json',
            },
            data: formData,
            url: BaseUrl + envVars.VUE_APP_POST_MANDATION_604_PREVIEW
                .replace("{customerId}", customerId)
                .replace("{actionPlanItemId}", actionPlanItemId)});
        return resp;
    },

    /**
     * Generates the final version of a DWP-604.
     * @param {*} customerId - the customer the MAN document relates to.
     * @param {*} actionPlanItemId - the action plan item the MAN document relates to.
     * @param {*} formData - The data of the form to be created.
     * @returns the response of the request.
     */
    async createDwp604Record(customerId, actionPlanItemId, formData) {
        await authorize()
        var resp = await axios({ 
            method: 'POST',
            headers: { 
                'content-type': 'application/json',
            },
            data: formData,
            url: BaseUrl + envVars.VUE_APP_GET_CUSTOMER_MANDATION_SAVE_604
                .replace("{customerId}", customerId)
                .replace("{actionPlanItemId}", actionPlanItemId)});
        return resp;
    }
}