import axios from "axios";
import { TryGetCachedToken } from "../router/TokenManger";

const BaseUrl = process.env.VUE_APP_CONTACT_BASE_URL;
var envVars = process.env;

export default{
    async GetLoggedInAdvisorsTeams(){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url:BaseUrl
                + envVars.VUE_APP_GET_LOGGED_IN_ADVISORS_TEAMS,
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
              }); 
            return resp;
        }
        catch(error){
            return error;
        }
    },
    async GetTeam(teamId){
        try {
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
              }
            const resp = await axios({
                method: "get",
                url:
                BaseUrl +
                envVars.VUE_APP_GET_TEAM
                +`?teamId=${teamId}`,
                responseType: "json",
                Headers: {
                "Access-Control-Allow-Origin": "*",
                },
            });
            return resp;
        } catch (error) {
            return error;
        }
    }
}