<template>
<div style="padding: 0.5rem 1.5rem;">
    <navBarVue style="background-color: #6F9600;"/>
     <div class="themed-assessment-results-main card" style="margin: 1rem 0 0.75rem 0;">
        <div class="leave-bar">
            <svg @click="navBack()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
        </div>
        <div class="themed-assessment-results-cont">
            <div :style="getThemedAssessmentResults.answerSets.length % 2 == 1 ? 'background-color: #293541;' : 'background-color: #26313b;'" class="themed-response-table">
                <div class="themed-response-header">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm64 192c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V160zM320 288c17.7 0 32 14.3 32 32v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32z"/></svg>
                    Responses
                </div>
                <div class="assessment-headers">
                    <div class="themed-response-td" v-for="(assessment, index) in getThemedAssessmentResults.answerSets" :key="assessment">
                        <div><b>Themed Assesssment {{index +1}}</b></div>
                        <div>Taken On: {{formatDate(new Date(assessment.createdOn))}}</div>
                    </div>
                </div>
                <div v-for="set in getThemedAssessmentResults.questionSets" :key="set.category">
                    <div class="themed-response-row category-row" v-if="set.category != ''"><b>{{getTitleFromCamelCase(set.category)}}</b></div>
                    <div v-for="question in set.questions" :key="question.id">
                        <div class="themed-response-row question-row" v-if="question.id != 'declarationSignature'"><b>{{question.title}}</b></div>
                        <div class="themed-response-row answer-row" v-if="question.id != 'declarationSignature'">
                            <div class="themed-response-td" v-for="answer in getRelevantAnswers(question.id)" :key="answer">
                                <div v-if="typeof answer.answer != 'object'">Answer: {{answer.answer}}</div>
                                <div v-if="typeof answer.answer == 'object' && answer.answer.length > 1">
                                    <div>Answer:</div>
                                    <ul style="padding-left: 1rem;">
                                        <li v-for="item in answer.answer" :key="item">{{item}}</li>
                                    </ul>
                                </div>
                                <div v-if="typeof answer.answer == 'object' && answer.answer.length == 1">Answer: {{answer.answer[0]}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterVue/>
</div>
</template>
<script>
import navBarVue from '@/components/navBar.vue';
import FooterVue from '@/components/Footer.vue';
import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/store/commonStore';
import { NavBackwards } from "@/router/index";
export default {
    name: "themed-assessment-result",
    components:{
        navBarVue,
        FooterVue
    },
    computed:{
        ...mapState(useCommonStore,["getCustomer", "getThemedAssessmentResults"]),
    },
    methods:{
        ...mapActions(useCommonStore,["GetCustomer", "GetThemedAssessmentResults", "SetCustomerId"]),
        formatDate(date){
            return [
                    [
                    date.getDate() < 10 
                    ? '0' + (date.getDate()).toString() 
                    :date.getDate(),
                    date.getMonth()+1 < 10 
                    ? '0' + (date.getMonth()+1).toString() 
                    : date.getMonth()+1,
                    date.getFullYear(),].join('/'),
                    [
                    date.getHours() < 10 
                    ? '0' + date.getHours().toString()
                    :date.getHours().toString(),
                    date.getMinutes() < 10 
                    ? '0' + date.getMinutes().toString()
                    :date.getMinutes().toString()].join(':')
                    ].join(' ');
        },
        navBack(){
            NavBackwards();
        },
        getRelevantAnswers(questionId){
            var answers = [];
            for(var i =0; i < this.getThemedAssessmentResults.answerSets.length; i++){
                var answered = false;
                for(var j=0; j < this.getThemedAssessmentResults.answerSets[i].answers.length; j++){
                    if(this.getThemedAssessmentResults.answerSets[i].answers[j].questionId == questionId && questionId != "declarationSignature"){
                        var answer = '';
                        //if(typeof this.getThemedAssessmentResults.answerSets[i].answers[j].answer == "object"){
                        //    for(var f=0; f < this.getThemedAssessmentResults.answerSets[i].answers[j].answer.length; f++){
                        //        answer = answer + this.getThemedAssessmentResults.answerSets[i].answers[j].answer[f] + ", "
                        //    }
                        //    answer = answer.substring(0, answer.length-2);
                        //}
                        if(typeof this.getThemedAssessmentResults.answerSets[i].answers[j].answer == "boolean"){
                            if(this.getThemedAssessmentResults.answerSets[i].answers[j].answer){
                                answer = "Yes"
                            }
                            else{
                                answer = "No"
                            }
                        }
                        else{
                            answer = this.getThemedAssessmentResults.answerSets[i].answers[j].answer
                        }
                        answered = true;
                        answers.push({createdOn: this.getThemedAssessmentResults.answerSets[i].createdOn, answer: answer});
                    }
                }
                if(!answered){
                    answers.push({createdOn: this.getThemedAssessmentResults.answerSets[i].createdOn, answer: "N/A"});
                }
            }
            return answers;
        },
        getTitleFromCamelCase(title){
            return title .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => {
                return str.toUpperCase();
            })
        }
    },
    mounted(){
        this.SetCustomerId(this.$route.params.id)
        Promise.all([
            this.GetCustomer(),
        ]);
        this.GetThemedAssessmentResults()
    }
}
</script>
<style scoped>
.themed-assessment-results-main{
    width: 100%;
    background-image: url('../assets/img/wave2.svg');
    background-repeat:no-repeat;
    justify-content: center;
    display: flex;
    font-size: 0.9rem;
}

.themed-assessment-results-cont{
    padding: 5%;
    padding-top:  calc(5%-1rem);
}

.answer-row{
    display: flex;
    flex-direction: row;
}

.category-row{
    padding: 0.4rem 0.75rem;
    background-color: rgb(214, 214, 214);
    background-color: hsl(210, 21%, 16%);
    color: #fff;
    font-size: 1.1rem;
}

.question-row{
    padding: 0.4rem 0.75rem;
    background-color: rgb(214, 214, 214);
    background-color:#242e38;
    color: #fff;
}

.themed-response-table{
    /* background-color: rgb(248, 248, 248); */
    border: 1px solid rgb(190, 196, 216);
    border: 1px solid #232c36;
}

.themed-response-row{
    border-bottom:  1px solid rgb(190, 196, 216);
    border-bottom: 1px solid #232c36;
}

.themed-response-td{
    border-right:  1px solid rgb(190, 196, 216);
    border-right: 1px solid #232c36;
    width: fit-content;
    padding: 0.4rem 0.75rem;
    width: 18rem;
    word-wrap: normal;
    color: #fff;
}

.themed-response-td:nth-child(even){
    background-color: #293541;
}

.themed-response-td:nth-child(odd){
    background-color: #26313b;
}

.themed-response-header{
    padding: 0.4rem 0.75rem;
    background-color: rgb(214, 214, 214);
    background-color: #212a33;
    color: #fff;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
} 

.themed-response-header svg{
    width: 1.4rem;
    margin-right: 0.3rem;
    fill: #fff;
}

.assessment-headers{
    display: flex;
}


.leave-bar{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 0.3rem 0.3rem 0 0;
    padding-right: 0.6rem;
}

.leave-bar svg{
    width: 2rem;
    height: 2rem;
    z-index: 1;
    cursor: pointer;
}

.leave-bar svg:hover{
    fill: #243147;
}
</style>