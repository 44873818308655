import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: '2afc711d-cf50-4c3a-b5b8-e55eee1ba53a',
    authority: 'https://login.microsoftonline.com/d29df6b3-4e52-482a-8fab-bf51119f3da0',
    //redirectUri: 'http://localhost:8080/', // Must be registered as a SPA redirectURI on your app registration
    //postLogoutRedirectUri: 'http://localhost:8080/', // Must be registered as a SPA redirectURI on your app registration
    //redirectUri: 'https://actionplanmanagementui.azurewebsites.net/', // Must be registered as a SPA redirectURI on your app registration
    //postLogoutRedirectUri: 'https://actionplanmanagementui.azurewebsites.net/', // Must be registered as a SPA redirectURI on your app registration
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
    advisorAuthProfile:{
      name: "BKG-Advisors",
      id: "be6eb3fb-2420-4f8e-8cf2-92cb6e500116"
    },
    customerAuthProfile:{
      name: "BKG-Customers",
      id: "502264c6-e095-42cf-b77a-8c340f14c257"
    }
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
      loggerOptions: {
          loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
              if (containsPii) {	
                  return;	
              }
              switch (level) {	
                  case LogLevel.Error:	
                      console.error(message);	
                      return;	
                  case LogLevel.Info:	
                      console.info(message);	
                      return;	
                  case LogLevel.Verbose:	
                      console.debug(message);	
                      return;	
                  case LogLevel.Warning:	
                      console.warn(message);	
                      return;	
                  default:
                      return;
              }
          },
          logLevel: LogLevel.Warning
      }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read', 'User.ReadBasic.All'],
};

export const tokenLoginRequest = {
  scopes: ['2afc711d-cf50-4c3a-b5b8-e55eee1ba53a/.default'],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphGroupEndPoint: 'https://graph.microsoft.com/v1.0/me/getMemberGroups',
  graphDirectReportsEndpoint: 'https://graph.microsoft.com/v1.0/users/{userId}/directReports'
};