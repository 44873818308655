import {
  createRouter,
  createWebHistory,
  RouteRecordName,
  RouteRecordRaw,
} from "vue-router";
import AdvisorDashboard from "../views/AdvisorDashboard.vue";
import Error from "../views/Error.vue";
import SurveyView from "../views/SurveyView.vue";
import CustomerProfile from "../views/CustomerProfileView.vue";
import CustomerConfig from "../views/CustomerConfigView.vue";
import PRaPView from "../views/PRapOrder.vue";
import AssessmentResults from "../views/AssessmentResults.vue";
// import { registerGuard, registerAdvisorGuard, registerCustomerGuard } from "./Guard";
import { registerGuard } from "./Guard";
import signableContract from "../views/ContractView.vue";
import AdvisorDashboardSearch from "../views/AdvisorDashboardSearch.vue";
import ThemedAssessmentResults from "../views/ThemedAssessmentResults.vue";
import AllAssessmentResults from "../views/AllAssessmentResults.vue";
import ActionAssessmentResults from "../views/ActionAssessmentResults.vue";
import BetterOffCalculatorResults from "../views/BetterOffCalculatorResults.vue";
import CustomerSpend from "../views/customerSpend.vue";
import PreviewContract from "../views/ViewContract.vue";
import PreviewLiveContract from "../views/PreviewContract.vue";
import CustomerViewVue from "@/views/CustomerView.vue";

const advisorDashboardRoute = {
  path: "/AdvisorDashboard/:id",
  name: "Advisor Portal",
  component: AdvisorDashboard,
  meta: {
    isAdvisorHome: true,
    advisorView: true,
    isHomePage: true,
  },
};

const advisorDashboardSearchRoute = {
  path: "/AdvisorDashboard",
  name: "Home",
  component: AdvisorDashboardSearch,
  meta: {
    advisorView: true,
    isHomePage: true,
  },
};

const reportingCustomersRoute = {
  path: "/ReportingCustomers",
  name: "Reporting Customers",
  component: CustomerViewVue,
  meta: {
    advisorView: true,
    isHomePage: true,
  },
};

const routes: RouteRecordRaw[] = [
  advisorDashboardRoute,
  {
    path: "/error",
    name: "Error Page",
    component: Error,
  },
  {
    path: "/ThemedAssessment/:id/:assessmentId/:version",
    name: "Themed Assessment ",
    component: SurveyView,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/ThemedAssessment/:id/:version",
    name: "Themed Assessment",
    component: SurveyView,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/CustomerSpend/:id",
    name: "Customer Spend",
    component: CustomerSpend,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/",
    name: "/",
    redirect: "/AdvisorDashboard",
  },
  {
    path: "/signContract/:id/:type/:version",
    name: "Sign Contract",
    component: signableContract,
    meta: {
      signContract: true,
    },
  },
  {
    path: "/PreviewContract/:id/:version",
    name: "Preview Contract",
    component: PreviewContract,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/PreviewContract/:id",
    name: "Preview Live Contract",
    component: PreviewLiveContract,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/CustomerProfile/:id",
    name: "Customer Profile",
    component: CustomerProfile,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/CustomerConfig/:id",
    name: "Customer Config",
    component: CustomerConfig,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/PrapOrder/:id",
    name: "PRaP Order",
    component: PRaPView,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/AssessmentResults/:id",
    name: "Assessment Results",
    component: AssessmentResults,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/ThemedAssessmentResults/:id",
    name: "Themed Assessment Results",
    component: ThemedAssessmentResults,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/AllAssessmentResults/:id",
    name: "All Assessment Results",
    component: AllAssessmentResults,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/ActionAssessmentResults/:id/:relatedActionId",
    name: "Action Assessment Results",
    component: ActionAssessmentResults,
    meta: {
      advisorView: true,
    },
  },
  {
    path: "/BetterOffCalculatorResults/:id",
    name: "Better off Calculator Results",
    component: BetterOffCalculatorResults,
    meta: {
      advisorView: true,
    },
  },
  advisorDashboardSearchRoute,
  reportingCustomersRoute,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from) => {
  if (from.query.noSearch == "true") {
    to.query = {...{"noSearch":"true"}, ...to.query};
  }

  let navHistory = sessionStorage.getItem("navigationHistory");
  sessionStorage.removeItem("navigationHistory");

  if (navHistory == null) {
    navHistory = JSON.stringify({ value: [] });
  }

  let navigationHistory = JSON.parse(navHistory);
  const containsSearch =
    navigationHistory.value.filter(
      (v: any) => v.name == advisorDashboardSearchRoute.name
    ).length > 0;
  const containsResportingCustomers =
    navigationHistory.value.filter(
      (v: any) => v.name == reportingCustomersRoute.name
    ).length > 0;

  if (to.meta.isHomePage) {
    if (
      (to.name == reportingCustomersRoute.name ||
        from.name == reportingCustomersRoute.name ||
        containsResportingCustomers) &&
      to.name != advisorDashboardSearchRoute.name
    ) {
      navigationHistory = {
        value: [
          {
            name: reportingCustomersRoute.name,
            path: reportingCustomersRoute.path,
          },
        ],
      };
    } else {
      navigationHistory = {
        value: [
          {
            name: advisorDashboardSearchRoute.name,
            path: advisorDashboardSearchRoute.path,
          },
        ],
      };
    }
  } else if (!to.meta.isHomePage && navigationHistory.value.length <= 2) {
    if (
      (to.name == reportingCustomersRoute.name ||
        from.name == reportingCustomersRoute.name ||
        containsResportingCustomers) &&
      to.name != advisorDashboardSearchRoute.name
    ) {
      navigationHistory = {
        value: [
          {
            name: reportingCustomersRoute.name,
            path: reportingCustomersRoute.path,
          },
        ],
      };
    } else {
      navigationHistory = {
        value: [
          {
            name: advisorDashboardSearchRoute.name,
            path: advisorDashboardSearchRoute.path,
          },
        ],
      };
    }

    if (to.params.id != null && to.params.id != "") {
      const formattedUrl = FormatUrl(
        advisorDashboardRoute.path,
        to.params.id as string
      );
      if (formattedUrl != null) {
        navigationHistory.value.push({
          name: advisorDashboardRoute.name,
          path: formattedUrl,
        });
      }
    }
  }

  if (
    navigationHistory.value.find((v: any) => v.name == to.name) == undefined
  ) {
    navigationHistory.value.push({ name: to.name, path: to.fullPath });
  }
  sessionStorage.setItem(
    "navigationHistory",
    JSON.stringify(navigationHistory)
  );
  return true;
});

function FormatUrl(url: string, customerId: string) {
  if (url.includes(":id")) {
    return url.replace(":id", customerId);
  }
  return null;
}

export function NavBackwards() {
  if (sessionStorage.getItem("navigationHistory") != null) {
    let navHistory = JSON.parse(
      sessionStorage.getItem("navigationHistory") ||
        JSON.stringify({ value: [] })
    ).value;
    navHistory = navHistory.slice(0, navHistory.length - 1);
    sessionStorage.removeItem("navigationHistory");
    sessionStorage.setItem(
      "navigationHistory",
      JSON.stringify({ value: navHistory })
    );
    router.push(navHistory[navHistory.length - 1].path);
  }
}

export function RemoveNavItem(name: string) {
  if (sessionStorage.getItem("navigationHistory") != null) {
    let navHistory = JSON.parse(
      sessionStorage.getItem("navigationHistory") ||
        JSON.stringify({ value: [] })
    ).value;
    navHistory = navHistory.filter((i: { name: any }) => i.name != name);
    sessionStorage.removeItem("navigationHistory");
    sessionStorage.setItem(
      "navigationHistory",
      JSON.stringify({ value: navHistory })
    );
    router.push(navHistory[navHistory.length - 1].path);
  }
}

export function GoToHomePage() {
  if (sessionStorage.getItem("navigationHistory") != null) {
    const navHistory = JSON.parse(
      sessionStorage.getItem("navigationHistory") ||
        JSON.stringify({ value: [] })
    ).value;
    const advisor = navHistory.find(
      (i: { name: string }) => i.name == advisorDashboardRoute.name
    );
    if (advisor != undefined) {
      router.push(advisor.path);
      return;
    }
    router.push(navHistory[0].path);
  }
}

export async function RouteToAdvisor(id: string) {
  window.location.href = window.origin + "/AdvisorDashboard/" + id;
}

registerGuard(router);

export default router;
