<template>
  <router-view />
  <SessionTimeout v-if="getIsSessionTimeoutEnabled" />
</template>
<script>
import axios from 'axios';
import axiosRetry from 'axios-retry';
import SessionTimeout from "@/components/SessionTimeout.vue";

export default {
  name: "XauraApp",
  components: {
    SessionTimeout
  },
    computed: {
      getIsSessionTimeoutEnabled(){
        return this.$sessionTimeoutEnabled === 'true';
      }
    },

  

  mounted() {
    axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
  }
};
</script>
<style>
.btn-1{
  margin-right: 1.5rem;
}
</style>
