<template>
    <div>
        <!-- Desktop View -->
        <div class="table-responsive card desktop-logic" style="padding: 0 1rem">
            <!-- Table Header -->
            <div class="col-12" style="
            font-size: 1.5rem;
            padding: 0.7rem;
            font-weight: 600;
            display: flex;
            align-items: center;
          ">
                <div class="col-12" style="
            font-size: 1.5rem;
            padding: 0.7rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content:space-between;
          ">
                    <div>Your customers</div>
                    <!-- <div style="font-size: 0.9rem; display: flex; align-items: center">
                        Action plans due (or overdue). View a snapshot of the action plan.
                    </div> -->
                    <div>
                        <input style="width:20rem" type="text" class="form-control" v-model="searchValue" placeholder="Search for customer..."/>
                    </div>
                </div>
            </div>

            <!-- Table content (drops down) -->
            <div class="fit-content-l840">
                <!-- Search Filters -->
                <div style="
              display: flex;
              justify-content: space-between;
              flex-direction: row;
              padding: 0 1rem 0.3rem 1rem;
            ">
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                        <div style="display: flex; flex-direction: column">
                            <div style="display: flex; flex-direction: row"></div>
                            <div class="page-num-options"></div>
                        </div>
                    </div>
                </div>
                <table id="datatable-basic" class="table table-flush" style="table-layout: fixed; width: 100%">
                    <thead class="thead-light">
                        <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                <div id="firstName-sort" class="th-inner th-sort">
                                    First Name
                                    <SortingArrows :id="firstNameSortId" v-model:currentlyActive="sortBy"
                                        startingState="descending" triggerElementId="firstName-sort" :ascending="() => {
                                            items = reportingCustomers.sort(function (a, b) {
                                                var textA = a.firstName.toUpperCase();
                                                var textB = b.firstName.toUpperCase();
                                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                            }).reverse();
                                            $refs.tablePage.setPaginatedItems();
                                        }" :descending="() => {
    items = reportingCustomers.sort(function (a, b) {
        var textA = a.firstName.toUpperCase();
        var textB = b.firstName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    $refs.tablePage.setPaginatedItems();
}" />
                                </div>
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                <div class="th-inner th-sort" id="lastName-sort">
                                    Last Name
                                    <SortingArrows :id="lastNameSortId" v-model:currentlyActive="sortBy"
                                        triggerElementId="lastName-sort" :ascending="() => {
                                            items = reportingCustomers.sort(function (a, b) {
                                                var textA = a.lastName.toUpperCase();
                                                var textB = b.lastName.toUpperCase();
                                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                            });
                                            $refs.tablePage.setPaginatedItems();
                                        }" :descending="() => {
    items = reportingCustomers.sort(function (a, b) {
        var textA = a.lastName.toUpperCase();
        var textB = b.lastName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }).reverse();
    $refs.tablePage.setPaginatedItems();
}" />
                                </div>
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                <div class="th-inner">
                                    Memorable Id
                                </div>
                            </th>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                <div class="th-inner">
                                    View Portal
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="upcoming-audits" v-for="(customer, index) in items" :key="index">
                            <td style="padding: 0.75rem 1.5rem" class="text-sm">
                                {{ customer.firstName }}
                            </td>
                            <td style="padding: 0.75rem 1.5rem" class="text-sm font-weight-normal">
                                {{ customer.lastName }}
                            </td>
                            <td style="padding: 0.75rem 1.5rem" class="text-sm font-weight-normal">
                                {{ customer.memorableId }}
                            </td>
                            <td style="padding: 0.75rem 1.5rem" class="text-sm font-weight-normal">
                                <router-link class="contract-link" :to="getPortalHref(customer.contactId)">View
                                    Portal</router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- This uses 'page-change' and 'interface' events to communicate with the mobile pagination -->
                <TablePaginationVue ref="tablePage" :items="getFilteredReportingCustomers" :buttons="4"
                    @page-change="this.$options.interfaces.tablePaginationDesktop.setPage"
                    @interface="setDesktopPaginationInterface" v-model="this.items" />
            </div>
        </div>
    </div>
</template>
  
<script scoped>
import { mapState } from "pinia";
import { useCommonStore } from "@/store/commonStore";
import TablePaginationVue from "./TablePagination.vue";
import SortingArrows from "./SortingArrows.vue";

export default {
    name: "reporting-customers-table",
    components: {
        TablePaginationVue,
        SortingArrows
    },
    data() {
        return {
            items: [],
            currentPageLength: 5,
            sortBy: "firstName",
            firstNameSortId: "firstName",
            lastNameSortId: "lastName",
            searchValue: ""
        }
    },
    interfaces: {
        tablePaginationDesktop: {
            setPage: () => { },
        },

        tablePaginationMobile: {
            setPage: () => { },
        },
    },
    watch: {
        currentPageLength: function () {
            this.interfaces.tablePaginationDesktop.setPageSize(
                this.currentPageLength
            );
            this.interfaces.tablePaginationMobile.setPageSize(this.currentPageLength);
        },
    },
    computed: {
        ...mapState(useCommonStore, [
            "reportingCustomers",
        ]),
        getFilteredReportingCustomers() {
            if (this.searchValue != null && this.searchValue != "") {
                const search = this.searchValue.toLowerCase();
                return this.reportingCustomers.filter(c => {
                    return c.firstName.toLowerCase().includes(search)
                        || c.lastName.toLowerCase().includes(search)
                        || (`${c.firstName} ${c.lastName}`).toLowerCase().includes(search)
                })
            }
            return this.reportingCustomers;
        }
    },
    methods: {
        setDesktopPaginationInterface(childInterface) {
            this.$options.interfaces.tablePaginationDesktop = childInterface;
        },

        setMobilePaginationInterface(childInterface) {
            this.$options.interfaces.tablePaginationMobile = childInterface;
        },
        getPortalHref(id) {
            return `/AdvisorDashboard/${id}`;
        }
    },
}
</script>
  
  <!-- Common Styling -->
<style scoped>
.contract-link {
    text-decoration: underline;
    letter-spacing: -0.025rem;
    color: #344767;
}

.contract-link:hover {
    text-decoration: underline;
}

.hover-tooltip {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.hover-tooltip div:last-child {
    padding: 0.5rem;
    border: 1px solid #5b5b5b;
    color: #5b5b5b;
    background-color: rgb(245, 245, 245);
    font-size: smaller;
    max-width: 25rem;
    white-space: pre-wrap;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}

.hover-tooltip div:first-child {
    height: 1.8rem;
}

.upcoming-audits:hover .appointment-tooltip {
    display: block;
}

.appointment-tooltip {
    display: none;
    width: fit-content;
    background-color: #f5f5f5;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    margin-left: 50px;

    /* Position the tooltip  */
    position: absolute;
    z-index: 1000;
}

.appointment-tooltip p {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 4px;
    font-size: 12px;
}

.page-amount-input,
.search-type-dis {
    width: 4.5rem;
    height: 1.7rem;
    border: none;
    border-radius: 0.2rem;
    background-color: rgb(243 243 243);
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    padding: 0 0.7rem;
    color: #67748e;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 1.5rem;
}

.search-type-dis {
    width: 14rem;
}

.date-range-input {
    height: 1.7rem;
    border: none;
    border-radius: 0.2rem;
    background-color: rgb(243 243 243);
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    color: #67748e;
}

.tgl-page-dropdown {
    width: 0.78rem;
    margin-left: -1.3rem;
}

.tgl-page-dropdown:hover {
    cursor: pointer;
}

.page-num-options,
.search-type-options {
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    width: 4.5rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 2rem;
    max-height: 10rem;
    overflow-y: auto;
}

.search-type-options {
    width: 14rem;
}

.page-num-options div,
.search-type-options div {
    background-color: rgb(243 243 243);
    padding: 0.2rem 0.7rem;
    width: 100%;
}

.page-num-options div:first-child,
.search-type-options div:first-child {
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
}

.page-num-options div:last-child,
.search-type-options div:last-child {
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.page-num-options div:hover,
.search-type-options div:hover {
    cursor: pointer;
    background-color: rgb(235, 235, 235);
}

.view-contract:hover {
    cursor: pointer;
}

.overdue {
    color: crimson;
    font-weight: bold;
}

.due-today {
    color: goldenrod;
    font-weight: bold;
}

.table td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    text-align: center;
}

.table th {
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
}

.th-inner {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: center;
    width: 100%;
}

@media (max-width: 900px) {
    .fit-content-l840 {
        width: fit-content;
    }
}

@media (max-width: 900px) {
    .desktop-logic {
        display: none;
    }

    .mobile-logic {
        display: block;
    }
}

@media (min-width: 900px) {
    .desktop-logic {
        display: block;
    }

    .mobile-logic {
        display: none;
    }
}
</style>
  
  <!-- Mobile Styling -->
<style scoped>
.header {
    font-size: 1.5rem;
    font-weight: 400;
}

.memo-body {
    padding: 1rem;
    padding-top: 0.6rem;
}

.memo {
    padding: 0.8rem;
    margin-bottom: 0.8rem;
}

.memo:last-child {
    margin-bottom: 0;
}

#memo-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    background-color: rgba(15, 15, 15, 0.527);
    width: 100%;
    height: 100%;
    padding-top: 20%;
}

#memo-modal {
    border-radius: 1rem;
    background-color: rgb(240, 240, 240);
}

.memo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1rem;
}

.memo-header span {
    font-size: x-large;
}

.memo-header svg {
    width: 1rem;
    fill: #2c3e50;
    cursor: pointer;
}

.memo div:first-child {
    font-size: 0.8rem;
    word-break: break-all;
}

@media (max-width: 600px) {
    #memo-modal {
        min-width: 95%;
        max-width: 95%;
        margin: 0 2.5%;
    }
}

.tasks-card {
    padding: 0.5rem 1rem;
    background-color: #c8d3d8;
}

.page-sel-btn {
    font-weight: 0;
    background-color: #fff;
    padding: 0.2rem 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 0.3rem;
    transition: 1687ms;
    transform: translateY(0);
    cursor: pointer;
    font-size: 0.8rem;
    margin-right: 0.3rem;
}

.page-sel-btn+.selected {
    background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
    color: white;
}

.page-sel-btn+.disabled {
    background-color: #d0d0d0;
}

.page-sel-btn:last-child {
    margin-right: 0;
}

.page-selector {
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.page-arrow {
    width: 0.6rem;
    fill: #344767;
}

.page-sel-btn+.disabled .page-arrow {
    fill: #666d7a;
}

.open-action {
    margin-bottom: 1rem;
}

.open-action:last-child {
    margin-bottom: 0;
}

.open-actions-card {
    border-radius: 1rem;
    padding: 1rem;
    /* background-color: rgb(240, 240, 240); */
    /* background-color: rgba(159, 168, 184, 0.849);  
        backdrop-filter: blur(5px); */
}

.extend-action {
    width: 6%;
    padding: 0;
    margin: 0.2rem 2.5% auto 2.5%;
    cursor: pointer;
    fill: #344767;
}

.extend-tasks {
    width: 6%;
    padding: 0;
    margin: 0rem 2.5%;
    cursor: pointer;
    fill: #344767;
}

.subject {
    max-width: 89%;
    word-wrap: normal;
    margin: 0;
}

.open-action-header {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.open-action-header svg {
    width: 1.9rem;
    height: 1.9rem;
    fill: #2c3e50;
    margin-right: 0.5rem;
}

.open-action-cont {
    padding: 0.6rem 1rem;
    margin-bottom: 0.5rem;
}

.prog-upt-btn {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
}

@media (min-width: 600px) {
    .my-actions-main {
        padding: 0 20%;
    }

    .extend-action {
        width: 1.5rem;
    }

    .extend-tasks {
        width: 1.5rem;
    }

    #memo-modal {
        margin: 0 26%;
        width: 48%;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: calc(6rem / 3);
    height: calc(3.4rem / 3);
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

input[type="date"] {
    box-sizing: border-box;
    padding: 0 0.7rem;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(226, 60, 60);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.disabled-switch .slider {
    background-color: rgb(161, 73, 73);
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: calc(2.6rem / 3);
    width: calc(2.6rem / 3);
    left: calc(0.4rem / 3);
    bottom: calc(0.4rem / 3);
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #47e441;
}

input:focus+.slider {
    box-shadow: 0 0 1px #47e441;
}

.disabled-switch input:checked+.slider {
    background-color: #64be61;
}

.disabled-switch input:focus+.slider {
    box-shadow: 0 0 1px #64be61;
}

input:checked+.slider:before {
    -webkit-transform: translateX(calc(2.6rem / 3));
    -ms-transform: translateX(calc(2.6rem / 3));
    transform: translateX(calc(2.6rem / 3));
}

/* Rounded sliders */
.slider.round {
    border-radius: calc(3.4rem / 3);
}

.slider.round:before {
    border-radius: 50%;
}

.loader {
    border: 0.7rem solid #f3f3f3;
    /* Light grey */
    border-top: 0.7rem solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
    animation: spin 2s linear infinite;
}

.loader-container {
    height: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 600px) {
    .width-100-l600 {
        width: 100% !important;
    }

    .pd-t-l600 {
        padding: 0.75rem 0rem 0rem 0rem !important;
    }

    .pd-tb-l100 {
        padding: 0.75rem 0rem !important;
    }

    .pd-lr-l600 {
        padding: 0rem 0.75rem !important;
    }
}

.th-sort {
    text-decoration: underline;
    cursor: pointer;
}
</style>
  