<template>
  <div class="modal-overlay" v-if="isVisible">
    <div class="modal-content">
      <span class="close-button" @click="closeModal">&times;</span>
      <h2 class="modal-title">{{ selectedAssessment.assessmentSubject }}</h2>
      <div class="assessment-info">
        <div><strong>Taken On:</strong> {{ formatDate(new Date(selectedAssessment.createdOn)) }}</div>
      </div>
      <div class="themed-response-table">
        <div class="themed-response-header">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm64 192c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V160zM320 288c17.7 0 32 14.3 32 32v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32z"/>
          </svg>
          Response
        </div>
        <div v-for="set in filteredQuestionSets" :key="set.category">
          <div class="themed-response-row category-row" v-if="set.category"><b>{{ getTitleFromCamelCase(set.category) }}</b></div>
          <div v-for="question in set.questions" :key="question.id">
            <div class="themed-response-row question-row"><b>{{ question.title }}</b></div>
            <div class="themed-response-row answer-row">
              <div v-if="!Array.isArray(question.answer)">Answer: {{ question.answer }}</div>
              <div v-else>
                <div>Answer:</div>
                <ul style="padding-left: 1rem;">
                  <li v-for="item in question.answer" :key="item">{{ item }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isReassessment !== null" class="themed-response-row category-row"><b>Is this a re-assessment?</b></div>
        <div v-if="isReassessment !== null" class="themed-response-row answer-row">Answer: {{ isReassessment ? 'true' : 'false' }}</div>
        <div v-if="customerPathway" class="themed-response-row category-row"><b>Customer pathway:</b></div>
        <div v-if="customerPathway" class="themed-response-row answer-row">Answer: {{ customerPathway }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    selectedAssessment: {
      type: Object,
      required: true
    }
  },
  computed: {
    filteredQuestionSets() {
      const questionSets = [...this.selectedAssessment.questionSets];
      return questionSets.filter(set => set.category !== 'IsThisaReAssessment' && set.category !== 'PathwayForCustomerJourney');
    },
    isReassessment() {
      const reassessmentSet = this.selectedAssessment.questionSets.find(set => set.category === 'IsThisaReAssessment');
      return reassessmentSet ? reassessmentSet.questions[0]?.answer : null;
    },
    customerPathway() {
      const pathwaySet = this.selectedAssessment.questionSets.find(set => set.category === 'PathwayForCustomerJourney');
      return pathwaySet ? pathwaySet.questions[0]?.answer : '';
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    formatDate(date) {
      return [
        date.getDate().toString().padStart(2, '0'),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getFullYear()
      ].join('/');
    },
    getTitleFromCamelCase(title) {
      return title.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  width: 70%;
  max-width: 700px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
  max-height: 85%;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  font-size: 2rem;
  color: #aaa;
}

.close-button:hover {
  color: #000;
}

.modal-title {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.themed-response-header {
  display: flex;
  align-items: center;
  background-color: #212a33;
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.themed-response-header svg {
  width: 1.25rem;
  margin-right: 0.5rem;
  fill: #fff;
}

.assessment-info {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #666;
}

.themed-response-row {
  background-color: #f1f3f5;
  padding: 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  border: 1px solid #dee2e6;
}

.category-row {
  font-size: 1.125rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.25rem;
}

.question-row {
  font-size: 1rem;
  font-weight: 500;
  color: #495057;
  margin-bottom: 0.25rem;
}

.answer-row {
  font-size: 0.875rem;
  color: #555;
  padding-left: 1.25rem;
}
</style>
