<template>
    <div class="column-12 pd-lr-l600" style="height: fit-content; padding: 0.5rem 1.5rem 0px;height:100%;display:flex;flex-direction:column;">
        <Navbar class="column-12" style="background-color: #6f9600"/>
        <div class="mt-3 main-cont">
            <customerTable />
            <AppFooter />
        </div>
    </div>
</template>
<script setup>
import customerTable from "../components/CustomerTable";
import { useCommonStore } from "../store/commonStore.js";
import Navbar from "../components/navBar";
import AppFooter from "../components/Footer.vue";

const { GetReportingCustomers } = useCommonStore();

GetReportingCustomers();
</script>
<style scoped>
.main-cont{
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow:1;
}
</style>