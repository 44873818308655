<template>
  <div
    class="column-12 pd-lr-l600"
    style="height: fit-content; padding: 0.5rem 1.5rem 0 1.5rem"
  >
    <div v-if="showEmailModal" style="display: flex; justify-content: center">
      <EmailComponent @closeEmailModal="showEmailModal = false" />
    </div>
    <div
      v-if="showAdHocEmailModal"
      style="display: flex; justify-content: center"
    >
      <AdHocEmail @closeEmailModal="showAdHocEmailModal = false" />
    </div>
    <div v-if="showSMSModal" style="display: flex; justify-content: center">
      <SMSComponent @closeSMSModal="showSMSModal = false" />
    </div>
    <navbar class="column-12" style="background-color: #6f9600" />
    <main
      style="height: fit-content"
      class="main-content position-relative column-12"
    >
      <div
        class="column-12 pd-lr-21-9"
        style="
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
        "
      >
        <!-- <div class="column-5 pd-t l-21-9 width-100-l600">
          <RadarChartVue class="radar-chart-st l-1000-fc-h width-100-l600" title="Progress" :chart="{
            labels: [
              'Career Goals',
              'Family life',
              'Job search',
              'Realistic Jobs',
              'Job Opportunities',
              'Changing Job Outlook',
              'Job Search Effort',
              'Programme Outlook',
              'Financial Outlook',
              'Job Interviews',
              'Career Motivation',
              'Training Outlook'
            ],
            datasets: [
              {
                label: 'Customer',
                data: [0,0,0,0,0,0,0,0,0,0,0,0],
              },
              {
                label: 'Customer',
                data: [0,0,0,0,0,0,0,0,0,0,0,0],
              },
            ],
          }" />
        </div> -->
        <div
          class="column-4 l-1000-fc-w width-100-l600"
          style="display: flex; flex-direction: column; flex-wrap: wrap"
        >
          <div
            style="max-width: 100%; display: flex; flex: 1 auto"
            class="pd-tr pd-t-l600"
          >
            <div
              class="card"
              id="customer-info-card"
              style="height: 100%; width: 100%"
            >
              <!--<div style="display: flex; flex-direction: row; justify-content:space-between;"
                class="card-header p-3 pb-0">-->
              <!--<h6 class="mb-0">Customer Info</h6>-->
              <!-- <div class="view-cus-info" @click="openCustomerInfo()">View more info</div> -->
              <!--</div>-->
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 1rem 1rem 1rem;
                "
              >
                <div style="font-size: 1.8rem">{{ getCustomer.fullName }}</div>
              </div>
              <div
                style="font-size: 0.8rem; margin-right: 0.5rem; padding: 0 1rem"
              >
                <b>Memorable ID:</b>
                <span style="font-size: 0.8rem; padding: 0 0.5rem">
                  {{ getCustomer.memorableId }}</span
                >
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 0 1rem;
                "
              >
                <div style="font-size: 0.8rem; margin-right: 0.5rem">
                  <b>Email:</b>
                </div>
                <div style="font-size: 0.9rem">{{ getCustomer.email }}</div>
                <svg
                  v-if="getIsEmailEnabled"
                  class="mail-icon"
                  @click="showAdHocEmailModal = true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
                  />
                </svg>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 0 1rem;
                  padding-bottom: 1rem;
                "
              >
                <div style="font-size: 0.8rem; margin-right: 0.5rem">
                  <b>Phone:</b>
                </div>
                <div style="font-size: 0.9rem; margin-right: 0.5rem">
                  {{
                    getCustomer.mobile == "" || getCustomer.mobile == null
                      ? getCustomer.landline
                      : getCustomer.mobile
                  }}
                </div>
                <svg
                  v-if="
                    getIsSmsEnabled &&
                    getCustomer.mobile != '' &&
                    getCustomer.mobile != null
                  "
                  class="phone-icon"
                  @click="showSMSModal = true"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M256 32C114.6 32 .0272 125.1 .0272 240c0 47.63 19.91 91.25 52.91 126.2c-14.88 39.5-45.87 72.88-46.37 73.25c-6.625 7-8.375 17.25-4.625 26C5.818 474.2 14.38 480 24 480c61.5 0 109.1-25.75 139.1-46.25C191.1 442.8 223.3 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32zM256.1 400c-26.75 0-53.12-4.125-78.38-12.12l-22.75-7.125l-19.5 13.75c-14.25 10.12-33.88 21.38-57.5 29c7.375-12.12 14.37-25.75 19.88-40.25l10.62-28l-20.62-21.87C69.82 314.1 48.07 282.2 48.07 240c0-88.25 93.25-160 208-160s208 71.75 208 160S370.8 400 256.1 400z"
                  />
                </svg>
                <svg
                  v-if="
                    getIsSmsEnabled &&
                    (getCustomer.mobile == '' || getCustomer.mobile == null)
                  "
                  class="phone-icon disabled"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M256 32C114.6 32 .0272 125.1 .0272 240c0 47.63 19.91 91.25 52.91 126.2c-14.88 39.5-45.87 72.88-46.37 73.25c-6.625 7-8.375 17.25-4.625 26C5.818 474.2 14.38 480 24 480c61.5 0 109.1-25.75 139.1-46.25C191.1 442.8 223.3 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32zM256.1 400c-26.75 0-53.12-4.125-78.38-12.12l-22.75-7.125l-19.5 13.75c-14.25 10.12-33.88 21.38-57.5 29c7.375-12.12 14.37-25.75 19.88-40.25l10.62-28l-20.62-21.87C69.82 314.1 48.07 282.2 48.07 240c0-88.25 93.25-160 208-160s208 71.75 208 160S370.8 400 256.1 400z"
                  />
                </svg>
                <div style="position: absolute; right: 1rem">
                  <router-link
                    :to="getCustomerProfileHref"
                    style="text-decoration: underline"
                    >View Full Profile</router-link
                  >
                </div>
              </div>
              <!-- <div style="position: absolute; right: 1rem">
                  <router-link
                    :to="getCustomerConfigHref"
                    style="text-decoration: underline"
                    >View Config Profile</router-link
                  >
                </div> -->
              <div
                style="
                  font-size: 1rem;
                  margin-left: 1rem;
                  display: flex;
                  column-gap: 1rem;
                "
              >
                <button
                  @click="this.openCustomerPortalLink()"
                  class="btn"
                  v-if="getIsAdvisorCustomerLink"
                >
                  View Customer Portal
                </button>
              </div>
            </div>
          </div>
          <div class="pd-tr pd-t-l600">
            <div class="job-interests-cont card pd">
              <div class="job-interests-sect">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M320 336c0 8.844-7.156 16-16 16h-96C199.2 352 192 344.8 192 336V288H0v144C0 457.6 22.41 480 48 480h416c25.59 0 48-22.41 48-48V288h-192V336zM464 96H384V48C384 22.41 361.6 0 336 0h-160C150.4 0 128 22.41 128 48V96H48C22.41 96 0 118.4 0 144V256h512V144C512 118.4 489.6 96 464 96zM336 96h-160V48h160V96z"
                  />
                </svg>
                <div><b>Job Goals</b></div>
              </div>
              <!--<div class="job-interests-sect" :class="editing ? 'flex-ops-edit' : ''">-->
              <div class="job-interests-sect">
                <!--<div :class="editing ? 'flex-ops-edit-child' : ''" v-for="(SOC, index) in selectedSOCs" :key="SOC">-->
                <div
                  class="flex-ops-edit-child"
                  v-for="(SOC, index) in getCustomer.sectorInterests"
                  :key="SOC"
                >
                  <div
                    :class="
                      index == getCustomer.sectorInterests.length - 1
                        ? 'last-rmv-mrg'
                        : ''
                    "
                    class="card job-interest"
                  >
                    {{ SOC }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="pd-r columun-12 pd-t-l600"
            style="height: max-content; max-width: 100%"
          >
            <ProgressLineChart
              class="column-12"
              title="Actions Completed"
              :count="480"
              :progress="60"
              :chart="{
                labels: [
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
              }"
            />
          </div>
        </div>
        <div
          class="column-4 l-1000-fc-w width-100-l600"
          style="display: flex; flex-direction: column; flex-wrap: wrap"
        >
          <div
            style="max-width: 100%; display: flex; flex: 1 auto"
            class="pd-tr pd-t-l600"
          >
            <div class="card pd" style="height: 100%; width: 100%">
              <div
                class="card-header p-3 pb-0"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <h6 class="mb-0">History</h6>
                <router-link
                  :to="'/AssessmentResults/' + getCustomerId"
                  style="text-decoration: underline; margin-left: auto"
                  >View assessments & outputs</router-link
                >
              </div>
              <div class="card-body border-radius-lg p-3">
                <div class="d-flex">
                  <div>
                    <div
                      :class="`icon icon-shape bg-info-soft shadow text-center border-radius-md shadow-none`"
                    >
                      <i
                        :class="`ni ni-money-coins text-lg text-info text-gradient opacity-10`"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div class="ms-3">
                    <div class="numbers">
                      <h6 class="mb-1 text-dark text-sm">
                        Last Activity on Fedcap Hub:
                      </h6>
                      <span class="text-sm">{{
                        getLastLoggedInAbintegro != null &&
                        getLastLoggedInAbintegro != ""
                          ? formatDate(new Date(getLastLoggedInAbintegro))
                          : "N/A"
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div>
                    <div
                      :class="`icon icon-shape bg-info-soft shadow text-center border-radius-md shadow-none`"
                    >
                      <i
                        :class="`ni ni-book-bookmark text-lg text-info text-gradient opacity-10`"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div class="ms-3">
                    <div class="numbers">
                      <h6 class="mb-1 text-dark text-sm">
                        Last Assessment Completed
                      </h6>
                      <span class="text-sm">{{
                        getAssessmentHistory != null
                          ? getAssessmentHistory.completedAt != null
                            ? formatDate(
                                new Date(getAssessmentHistory.completedAt)
                              )
                            : "N/A"
                          : "N/A"
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div>
                    <div
                      :class="`icon icon-shape bg-info-soft shadow text-center border-radius-md shadow-none`"
                    >
                      <i
                        :class="`ni ni-bell-55 text-lg text-info text-gradient opacity-10`"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div class="ms-3">
                    <div class="numbers">
                      <h6 class="mb-1 text-dark text-sm">
                        Next Assessment Due
                      </h6>
                      <span class="text-sm">{{
                        getNextAssessmentDate != "N/A"
                          ? formatDate(new Date(getNextAssessmentDate))
                          : "N/A"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="max-width: 100%; display: flex; flex: 1 auto"
            class="pd-tr pd-t-l600"
          >
            <div class="card" style="width: 100%; height: 100%">
              <div class="card" style="height: 100%; overflow-y: auto">
                <div class="card-header p-3 pb-0">
                  <h6 class="mb-0">Upcoming Meetings</h6>
                </div>
                <div
                  class="card-body border-radius-lg p-3"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    max-height: 235px;
                    overflow-y: auto;
                  "
                >
                  <div
                    v-for="item in getUpcomingMeetings"
                    :key="item.id"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 1rem;
                    "
                  >
                    <DefaultItemVue
                      style="margin-bottom: 2vh"
                      :color="danger"
                      :icon="'ni ni-bell-55'"
                      :title="item.subject"
                      :description="
                        new Date(item.scheduledStart)
                          .toString()
                          .substring(0, 21)
                      "
                    />

                    <button
                      class="btn"
                      style="
                        background-color: white;
                        padding: 0.23rem 0.8rem;
                        font-size: 0.9rem;
                      "
                      @click="openCreateNewAppointmentAction(item)"
                      v-if="getIsMandationEnabled"
                      v-show="item.relatedActionId === null"
                    >
                      <svg
                        style="width: 1rem; margin-right: 0.2rem"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                          d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                        />
                      </svg>
                      Create Action
                    </button>
                  </div>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      margin-top: 1rem;
                      font-size: 1.3rem;
                    "
                    v-if="getUpcomingMeetings.length == 0"
                  >
                    <svg
                      style="width: 1.8rem; margin-right: 0.5rem"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <defs>
                        <linearGradient id="gradient">
                          <stop offset="0%" stop-color="#2dce89" />
                          <stop offset="100%" stop-color="#2dcecc" />
                        </linearGradient>
                      </defs>
                      <path
                        fill="url(#gradient)"
                        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                      />
                    </svg>
                    No Meetings
                  </div>
                </div>
                <div
                  class="card-body border-radius-lg p-3"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                  "
                >
                  <button
                    @click="this.triggerCTFASurveyPopup()"
                    class="btn"
                    v-if="getIsCustomerVoiceEnabled"
                    style="width: fit-content; margin-bottom: 0"
                  >
                    Send Continuous FTA Survey
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="column-12">
        <div class="pd-t pd-lr-21-9">
          <div class="card widget-calendar">
            <div
              style="font-size: 1.5rem; padding: 0.7rem 1.7rem; font-weight: 600; display: flex; align-items: center;"
              @click="() => { extendCalendar = !extendCalendar }">
              Events Calendar
              <svg v-show="!extendCalendar" style="height: 1.5rem; margin-left: auto;"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z" />
              </svg>
              <svg v-show="extendCalendar" style="height: 1.5rem; margin-left: auto;" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512">
                <path
                  d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z" />
              </svg>
            </div>
            <div v-show="extendCalendar" class="p-3 card-body">
              <div id="calendar" data-toggle="widget-calendar"></div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="column-12">
        <div class="pd-t pd-lr-21-9">
          <ActionTableVue
            id="recommended-table"
            :contextMenu="true"
            :showModal="false"
            tableType="Recommended"
            uniqueId="Recommended"
            :ea="true"
          />
        </div>
      </div>
      <div class="column-12">
        <div class="pd-t pd-lr-21-9">
          <ActionTableVue
            id="current-table"
            :contextMenu="true"
            :showModal="true"
            tableType="Current"
            uniqueId="Current"
            :ea="true"
          />
        </div>
      </div>
      <div class="column-12">
        <div class="pd-t pd-lr-21-9">
          <ActionTableVue
            id="completed-table"
            :contextMenu="true"
            :showModal="false"
            tableType="Completed"
            uniqueId="Completed"
            :ea="true"
          />
        </div>
      </div>
      <div class="column-12">
        <div class="pd-t pd-lr-21-9">
          <ExternalActivites uniqueId="externalActivites" />
        </div>
      </div>
      <div class="column-12">
        <div class="pd-t pd-lr-21-9">
          <ActionTableVue
            id="archived-table"
            :contextMenu="true"
            :showModal="false"
            tableType="Archived"
            uniqueId="Archived"
            :ea="true"
          />
        </div>
      </div>
      <div class="column-12" v-if="getIsFailedToCompleteEnabled">
        <div class="pd-t pd-lr-21-9">
          <ActionTableVue
            id="failed-table"
            :contextMenu="true"
            :showModal="false"
            tableType="Failed"
            uniqueId="Failed"
            :ea="true"
          />
        </div>
      </div>
      <div class="column-12">
        <div class="pd-t pd-lr-21-9">
          <AuditTableVue :ea="true" uniqueId="auditTable" />
        </div>
      </div>
      <div class="col-12">
        <div class="pd-tb pd-lr-21-9">
          <FileExplorerTable v-if="getIsCustomerFilesEnabled" />
        </div>
      </div>
      <AppFooter />
      <Configurator
        :class="[
          getShowConfig ? 'show' : '',
          getHideConfigButton ? 'd-none' : '',
        ]"
      />
    </main>
    <ModalVue />
    <!-- <Survey hidden /> -->
    <CalendarEventViewVue hidden />
    <CreateActionVue hidden />
    <CreateAppointmentActionVue hidden />
    <UpdateActivityVue hidden />
    <CustomerProfile hidden />
    <UpdateActionStart hidden />
    <MemoViewVue hidden />
    <ConvertActivityVue hidden />
    <UpdateAssessmentItemDueDate hidden />
    <div
      id="create-audit-desc"
      style="overflow-y: auto; max-height: 60vh"
      hidden
    >
      <div>Subject:</div>
      <div
        style="
          display: flex;
          margin-bottom: 1rem;
          max-width: 30rem;
          flex-wrap: wrap;
        "
      >
        <div
          style="margin-right: 0.8rem; margin-left: 0"
          class="audit-subj-container"
        >
          <input
            class="audit-subj-checkbox"
            type="checkbox"
            @change="
              ($event) => {
                if (newAuditSubj != null) {
                  clearAuditSubjCheckboxes($event);
                }
                if ($event.target.checked) {
                  newAuditSubj = '4 Weekly Review';
                }
              }
            "
          />
          <span class="audit-subj-text">4 Weekly Review</span>
        </div>
        <div
          style="margin-right: 0.8rem; margin-left: 0"
          class="audit-subj-container"
        >
          <input
            class="audit-subj-checkbox"
            type="checkbox"
            @change="
              ($event) => {
                if (newAuditSubj != null) {
                  clearAuditSubjCheckboxes($event);
                }
                if ($event.target.checked) {
                  newAuditSubj = 'Initial Action Plan';
                }
              }
            "
          />
          <span class="audit-subj-text">Initial Action Plan</span>
        </div>
        <div
          style="margin-right: 0.8rem; margin-left: 0"
          class="audit-subj-container"
        >
          <input
            class="audit-subj-checkbox"
            type="checkbox"
            @change="
              ($event) => {
                if (newAuditSubj != null) {
                  clearAuditSubjCheckboxes($event);
                }
                if ($event.target.checked) {
                  newAuditSubj = 'In Work Support';
                }
              }
            "
          />
          <span class="audit-subj-text">In Work Support</span>
        </div>
        <div
          style="margin-right: 0.8rem; margin-left: 0"
          class="audit-subj-container"
        >
          <input
            class="audit-subj-checkbox"
            type="checkbox"
            @change="
              ($event) => {
                if (newAuditSubj != null) {
                  clearAuditSubjCheckboxes($event);
                }
                if ($event.target.checked) {
                  newAuditSubj = 'Change of Circumstances';
                }
              }
            "
          />
          <span class="audit-subj-text">Change of Circumstances</span>
        </div>
        <div style="margin-left: 0" class="audit-subj-container">
          <input
            class="audit-subj-checkbox"
            type="checkbox"
            @change="
              ($event) => {
                if (newAuditSubj != null) {
                  clearAuditSubjCheckboxes($event);
                }
                if ($event.target.checked) {
                  newAuditSubj = 'Exit Plan';
                }
                this.showCompleterQuestionnaire = exitplanFlag;
              }
            "
          />
          <span class="audit-subj-text">Exit Plan</span>
        </div>

        <div class="completer-summary" v-show="showCompleterQuestionnaire">
          <div>
            <div
              class="completer-summary-overlay"
              @click="closeQuestionnaireAndReset"
            >
              <div
                style="
                  width: 100%;
                  margin-top: 2%;
                  text-align: right;
                  padding-right: 20px;
                "
              >
                <svg
                  @click="closeQuestionnaireAndReset"
                  style="width: 1.2rem"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                  />
                </svg>
              </div>
            </div>
            <div style="width: 100%; margin-top: 2%; text-align: center">
              <h2>Completer Summary Questionnaire</h2>
            </div>
            <div id="survey-form" style="margin: 20px auto; padding: 20px">
              <table style="width: 100%; border-collapse: collapse">
                <tbody>
                  <tr>
                    <td
                      style="border: 1px solid #ccc; padding: 8px; width: 35%"
                    >
                      <b>1- Does the Participant have an up-to-date CV?</b>
                    </td>
                    <td
                      style="border: 1px solid #ccc; padding: 8px; width: 65%"
                    >
                      <select
                        v-model="completerData.cvAnswer"
                        style="
                          width: 100%;
                          padding: 8px;
                          border: 1px solid #ccc;
                          border-radius: 4px;
                        "
                        required
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: 1px solid #ccc; padding: 8px">
                      <b
                        >2- What work sector(s) has the Participant shown an
                        interest in and/or is applying for?</b
                      >
                    </td>
                    <td style="border: 1px solid #ccc; padding: 8px">
                      <div
                        v-if="getCustomer.sectorInterests.length === 0"
                        style="
                          width: 100%;
                          padding: 8px;
                          border: 1px solid red;
                          border-radius: 4px;
                          color: red;
                        "
                      >
                        No job goals available. This field is required.
                      </div>
                      <div
                        v-else
                        style="
                          width: 100%;
                          padding: 8px;
                          border: 1px solid #ccc;
                          border-radius: 4px;
                        "
                      >
                        {{ getCustomer.sectorInterests.slice(0, 3).join(", ") }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="border: 1px solid #ccc; padding: 8px">
                      <b
                        >3- What are the top three priority actions you
                        recommend for the Participant to take forward?</b
                      >
                    </td>
                    <td style="border: 1px solid #ccc; padding: 8px">
                      <input
                        type="text"
                        v-model="completerData.priority1"
                        placeholder="1."
                        required
                        style="
                          width: 100%;
                          padding: 8px;
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          margin-bottom: 10px;
                        "
                      />
                      <input
                        type="text"
                        v-model="completerData.priority2"
                        placeholder="2."
                        required
                        style="
                          width: 100%;
                          padding: 8px;
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          margin-bottom: 10px;
                        "
                      />
                      <input
                        type="text"
                        v-model="completerData.priority3"
                        placeholder="3."
                        required
                        style="
                          width: 100%;
                          padding: 8px;
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          margin-bottom: 10px;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style="border: 1px solid #ccc; padding: 8px">
                      <b
                        >4- What are the Participant's current barriers to
                        Employment?</b
                      >
                    </td>
                    <td style="border: 1px solid #ccc; padding: 8px">
                      <input
                        type="text"
                        v-model="completerData.customerBarriers"
                        required
                        style="
                          width: 100%;
                          padding: 8px;
                          border: 1px solid #ccc;
                          border-radius: 4px;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style="border: 1px solid #ccc; padding: 8px">
                      <b>5- Work Readiness</b>
                      <p style="margin-bottom: 5px">
                        Please select the box below that mostly indicates this
                        Participant's work readiness status as they come to the
                        end of their time on the Restart Scheme.
                      </p>
                    </td>
                    <td style="border: 1px solid #ccc; padding: 8px">
                      <div
                        v-if="!completerData.workReadiness"
                        style="
                          width: 100%;
                          padding: 8px;
                          border: 1px solid red;
                          border-radius: 4px;
                          color: red;
                        "
                      >
                        This field is required
                      </div>
                      <div v-else></div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="my-3"
                style="width: 100%; border-collapse: collapse"
              >
                <thead>
                  <tr>
                    <th style="border: 1px solid #ccc; padding: 8px"></th>
                    <th style="border: 1px solid #ccc; padding: 8px">
                      Ready for work, fully confident
                    </th>
                    <th style="border: 1px solid #ccc; padding: 8px">
                      Ready for work, confidence could grow
                    </th>
                    <th style="border: 1px solid #ccc; padding: 8px">
                      Ready for work with support
                    </th>
                    <th style="border: 1px solid #ccc; padding: 8px">
                      Significant health issues to resolve
                    </th>
                    <th style="border: 1px solid #ccc; padding: 8px">
                      Further barriers to address
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style="border: 1px solid #ccc; padding: 8px">
                      Provider Response (please select an appropriate box)
                    </th>
                    <td
                      v-for="col in 5"
                      :key="col"
                      style="border: 1px solid #ccc; padding: 8px"
                    >
                      <input
                        type="radio"
                        name="workReadiness"
                        :value="col"
                        v-model="completerData.workReadiness"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="border: 1px solid #ccc; padding: 8px">
                <b>Additional Information to explain assessment</b>
                <textarea
                  v-model="completerData.additionalInfo"
                  style="
                    width: 100%;
                    height: 100px;
                    padding: 8px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                  "
                ></textarea>
              </div>
              <div style="text-align: center; margin-top: 20px">
                <button
                  @click="closeQuestionnaire"
                  style="
                    padding: 8px 16px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    background-color: #f0f0f0;
                  "
                  :disabled="
                    !isFormValid || getCustomer.sectorInterests.length === 0
                  "
                >
                  Save and Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="getIsModeOfInterventionEnabled">
        <div>Mode of intervention:</div>
        <div
          style="margin-right: 0.8rem; margin-left: 0"
          class="audit-subj-container"
        >
          <input
            type="radio"
            name="modeOfIntervention"
            v-model="modeOfIntervention"
            value="Virtual"
          />
          <span class="audit-subj-text">Virtual Appointment</span>
        </div>
        <div
          style="margin-right: 0.8rem; margin-left: 0; margin-bottom: 1rem"
          class="audit-subj-container"
        >
          <input
            type="radio"
            name="modeOfIntervention"
            v-model="modeOfIntervention"
            value="F2F"
          />
          <span class="audit-subj-text">F2F</span>
        </div>
        <span class="text-danger" v-show="!radioIsSelected"
          >Please select a mode of intervention.</span
        >
      </div>
      <div>Formal Review:</div>
      <div id="audit-desc-editor"></div>
      <span class="text-danger" v-show="!auditDescIsSentence"
        >Please enter at least 300 characters.</span
      ><br />
      <button
        class="btn"
        v-show="newAuditSubj != null"
        @click="
          () => {
            setReasonTrySave();
          }
        "
      >
        Confirm and Create
      </button>
      <button class="btn" v-show="newAuditSubj == null" disabled>
        Confirm and Create
      </button>
    </div>
    <div id="force-create-audit" style="max-width: 25rem" hidden>
      <div style="margin-bottom: 0.5rem; font-size: medium">
        {{ getAuditForceCreateWarning }}
      </div>
      <div style="width: 100%; display: flex; justify-content: space-between">
        <button
          class="btn bg-gradient-danger"
          @click="
            () => {
              HideAuditForceCreatePopup();
            }
          "
        >
          Cancel
        </button>
        <button
          class="btn bg-gradient-success"
          @click="
            () => {
              createAuditPoint(true);
              HideAuditForceCreatePopup();
            }
          "
        >
          Confirm and Create
        </button>
      </div>
    </div>
    <div id="cancellation-reason" hidden>
      <input id="actionIdToCancel" hidden />
      <div>Cancellation reason:</div>
      <textarea
        class="cancellation-reason-ta"
        v-model="cancellationReason"
        @input="isCancelSentence(cancellationReason)"
      /><br />
      <span class="text-danger" v-show="!cancellationIsSentence"
        >Please enter a sentence.</span
      ><br />
      <button
        class="btn"
        v-show="
          cancellationReason != null &&
          cancellationReason != '' &&
          cancellationIsSentence &&
          cancellationReason != null
        "
        @click="
          () => {
            setCancellationReason();
            toggleModal();
            cancellationReason = null;
          }
        "
      >
        Confirm and Move
      </button>
      <button
        class="btn"
        v-show="
          cancellationReason == null ||
          cancellationReason == '' ||
          !cancellationIsSentence ||
          cancellationReason == null
        "
        disabled
      >
        Confirm and Move
      </button>
    </div>
    <div>
      <div v-if="getMandationForm.UcMandation" class="wizards">
        <MandationWizard />
      </div>
      <div v-if="getMandationForm.JsaMandation" class="wizards">
        <JSA_Man_wizard />
      </div>
      <div v-if="getMandationForm.uc603" class="wizards">
        <UC603Wizard />
      </div>
      <div v-if="getMandationForm.jsa603" class="wizards">
        <JSA603Wizard />
      </div>
      <div v-if="getMandationForm.uc604" class="wizards">
        <UC604Wizard />
      </div>
    </div>
  </div>
</template>
<script>
import Configurator from "../components/Configurator.vue";
import Navbar from "../components/navBar";
//import RadarChartVue from "../components/RadarChart.vue";
import DefaultItemVue from "../components/DefaultItem.vue";
import ModalVue from "../components/PopupModal.vue";
import ProgressLineChart from "../components/ProgressLineChart.vue";
import AppFooter from "../components/Footer.vue";
import { useAdvisorDashboardStore } from "../store/advisorDashboard.js";
import { useCommonStore } from "../store/commonStore.js";
import { useMainStore } from "../store/mainStore";
import { GetSessionId } from "@/router/Guard.ts";
import { mapActions, mapState } from "pinia";
import ActionTableVue from "@/components/ActionTable.vue";
import AuditTableVue from "@/components/AuditTable.vue";
//import Survey from "../components/Survey.vue";
import CalendarEventViewVue from "@/components/CalendarEventView.vue";
import CreateActionVue from "@/components/CreateAction.vue";
import CreateAppointmentActionVue from "@/components/CreateAppointmentAction.vue";
import UpdateActivityVue from "@/components/UpdateActivity.vue";
import CustomerProfile from "@/components/CustomerProfile.vue";
import UpdateActionStart from "@/components/UpdateActionStart.vue";
import ExternalActivites from "@/components/ExternalActivitiesTable.vue";
import MemoViewVue from "@/components/MemoView.vue";
import ConvertActivityVue from "@/components/ConvertActivity.vue";
import Quill from "quill";
import EmailComponent from "../components/EmailComponent.vue";
import AdHocEmail from "@/components/AdHocEmail.vue";
import SMSComponent from "../components/SMSComponent.vue";
//import HtmlToRtfBrowser from "html-to-rtf-browser";
import Turndown from "turndown";
import MandationWizard from "../components/MandationWizard.vue";
import JSA_Man_wizard from "../components/JSA_MandationWizard.vue";
import UC603Wizard from "../components/UC603Wizard/UC603Wizard.vue";
import JSA603Wizard from "../components/JSA603Wizard/JSA603Wizard.vue";
import UC604Wizard from "../components/UC604_Wizard/UC604Wizard.vue";
import FileExplorerTable from "../components/FileExplorerTable";
import UpdateAssessmentItemDueDate from "../components/UpdateAssessmentItemDue";

let auditDesc = null;
let auditDescText = null;
var turndownService = new Turndown();
//var htmlToRtf = require('html-to-rtf');
export default {
  name: "AdvisorDashboard",
  components: {
    Configurator,
    //RadarChartVue,
    DefaultItemVue,
    ProgressLineChart,
    AppFooter,
    Navbar,
    ActionTableVue,
    AuditTableVue,
    ModalVue,
    // Survey,
    CalendarEventViewVue,
    CreateActionVue,
    CreateAppointmentActionVue,
    UpdateActivityVue,
    CustomerProfile,
    UpdateActionStart,
    ExternalActivites,
    MemoViewVue,
    ConvertActivityVue,
    MandationWizard,
    JSA_Man_wizard,
    UC603Wizard,
    JSA603Wizard,
    AdHocEmail,
    SMSComponent,
    EmailComponent,
    UC604Wizard,
    UpdateAssessmentItemDueDate,
    FileExplorerTable,
  },
  data() {
    return {
      extendR: {
        actions: false,
        memos: false,
        creatingMemo: false,
        newMemoText: "",
        tasks: false,
      },
      extendC: {
        actions: false,
        memos: false,
        creatingMemo: false,
        newMemoText: "",
        tasks: false,
      },
      extendCo: {
        actions: false,
        memos: false,
        creatingMemo: false,
        newMemoText: "",
        tasks: false,
      },
      abintegroActivitys: {
        actions: false,
        memos: false,
        creatingMemo: false,
        newMemoText: "",
        tasks: false,
      },
      extendArchived: {
        actions: false,
        memos: false,
        creatingMemo: false,
        newMemoText: "",
        tasks: false,
      },
      extendCalendar: true,
      selected: false,
      task: false,
      selectedActionId: "",
      selectedTaskId: "",
      showContextMenuMove: false,
      contextMenuActionId: "",
      newAuditDesc: null,
      newAuditSubj: null,
      modeOfIntervention: null,
      auditDescIsSentence: true,
      radioIsSelected: true,
      cancellationIsSentence: true,
      cancellationReason: null,
      appointmentInfo: null,
      // Indicates whether this view has loaded and initialized
      loaded: false,
      showEmailModal: false,
      showAdHocEmailModal: false,
      showSMSModal: false,
      showCompleterQuestionnaire: false,
      completerData: {
        cvAnswer: "",
        priority1: "",
        priority2: "",
        priority3: "",
        customerBarriers: "",
        workReadiness: null,
        additionalInfo: "",
      },
      assessmentResults: null,
      pathway: null,
    };
  },

  computed: {
    ...mapState(useCommonStore, [
      "getCustomerId",
      "getCustomer",
      "getCalendarEvents",
      "getActionPlan",
      "getActivity",
      "getActivities",
      "getUpcomingMeetings",
      "getNextMeetingDate",
      "getActionPlanResult",
      "getMemosForUi",
      "getNewActionPlanMemos",
      "getActions",
      "findActionAndMove",
      "getActivity",
      "getIsCalendarSet",
      "getAssessmentHistory",
      "getNextAssessmentDate",
      "getRecommendedActionTemplates",
      "getLastLoggedInAbintegro",
      "getMandationForm",
      "getAuditForceCreatePopupIsVisible",
      "getAuditForceCreateWarning",
      "getShouldCreateActions",
      "getUnfilteredAssessmentResults",
    ]),
    ...mapState(useAdvisorDashboardStore, [
      "getShowModal",
      "getContextMenuActionId",
      "getMeetingEventView",
      "getCreateAppointmentView",
      "getCustomerProfileView",
      "getAuditDescView",
      "getActionIdToCancel",
    ]),
    ...mapState(useMainStore, [
      "getShowConfig",
      "getHideConfigButton",
      "getIsSmsEnabled",
      "getIsEmailEnabled",
      "getIsAdvisorCustomerLink",
      "getIsFailedToCompleteEnabled",
      "getIsModeOfInterventionEnabled",
      "getIsCustomerVoiceEnabled",
      "getIsCustomerFilesEnabled",
      "getIsMandationEnabled",
    ]),
    isFormValid() {
      return (
        this.completerData.cvAnswer &&
        this.completerData.priority1 &&
        this.completerData.priority2 &&
        this.completerData.priority3 &&
        this.completerData.customerBarriers &&
        this.completerData.workReadiness
      );
    },
    exitplanFlag() {
      const recommendedActions = this.getRecommendedActionTemplates;

      if (!recommendedActions || recommendedActions.length === 0) {
        console.warn("No recommended action templates found.");
        return false;
      }

      return recommendedActions.some(
        (action) =>
          action.catalogName === "restart-v2" ||
          action.catalogName === "restart-v1"
      );
    },
    getCustomerProfileHref() {
      return `/CustomerProfile/${this.getCustomerId}`;
    },
    getCustomerConfigHref() {
      return `/CustomerConfig/${this.getCustomerId}`;
    },
  },

  methods: {
    ...mapActions(useCommonStore, [
      "GetData",
      "CreateAuditPoint",
      "SetCustomerId",
      "GetCustomer",
      "GetActivitiesAsync",
      "GetActionPlanActions",
      "GetUpcomingMeetings",
      "SetAllValues",
      "GetAllActions",
      "SetMemosForUI",
      "CreateNewActionPlanMemo",
      "createNewTaskMemo",
      "fireErrorAlert",
      "fireLoadingAlert",
      "closeAlert",
      "DestroyCalendar",
      "GetAuditPoints",
      "CreateNewAuditPoint",
      "TryGetActivity",
      "CreateActionPlanItemMemo",
      "UpdateActionStateCode",
      "GetPRaPOrder",
      "GetAllAdvisors",
      "StoreOneTimeUseAuthToken",
      "HideAuditForceCreatePopup",
      "TriggerCTFASurvey",
      "GetCustomerStrandOptions",
      "StoreAuthToken",
      "GetUnfilteredAssessmentResults",
      "setPathway",
    ]),
    ...mapActions(useAdvisorDashboardStore, [
      "toggleModal",
      "setModalContent",
      "setModalContentData",
      "setCreateAppointmentView",
      "setModalTitle",
      "setMeetingEventView",
      "setCustomerProfileView",
      "setAuditDescView",
      "setActionIdToCancel",
      "setCompleterSummaryData",
    ]),

    findPathway() {
      //assessmentName = ThemeId
      const mindsetAssessment =
        this.getUnfilteredAssessmentResults?.assessments?.find(
          (assessment) =>
            assessment.assessmentName ===
              process.env.VUE_APP_SURVEY_ASSESSMENT_NAME ||
            process.env.VUE_APP_SURVEY_ASSESSMENT_NAME_TAKE_INITIAL
        );

      if (!mindsetAssessment) {
        console.warn("No 'Mindset and Insight' assessment found.");
        return null;
      }

      const pathwaySet = mindsetAssessment.questionSets.find(
        (set) => set.category === process.env.VUE_APP_SURVEY_PATHWAY_CATEGORY
      );

      if (!pathwaySet) {
        console.warn("No 'PathwayForCustomerJourney' question set found.");
        return null;
      }

      const pathwayMatch =
        pathwaySet.questions[0]?.answer?.match(/Pathway [A-C]/);

      if (pathwayMatch) {
        this.pathway = pathwayMatch[0];
        this.setPathway(this.pathway);
      } else {
        console.warn("No valid pathway answer found.");
      }
    },

    closeQuestionnaire() {
      this.showCompleterQuestionnaire = false;
      this.setCompleterSummaryData(this.completerData);
    },

    closeQuestionnaireAndReset() {
      this.showCompleterQuestionnaire = false;
      // Reset form data here:
      this.completerData = {
        cvAnswer: "",
        priority1: "",
        priority2: "",
        priority3: "",
        customerBarriers: "",
        workReadiness: null,
        additionalInfo: "",
      };
      this.clearAuditSubjCheckboxes(null);
    },

    openCreateNewAppointmentAction(item) {
      if (this.getCreateAppointmentView == null) {
        this.setCreateAppointmentView(
          document.getElementById("create-appointment")
        );
      }
      this.setModalContent(this.getCreateAppointmentView);
      this.setModalContentData(item);
      this.setModalTitle("Create an Appointment");
      this.toggleModal();
      console.log(item);
    },

    goToCreateTask(parentActionId) {
      if (this.getCreateActionView == null) {
        this.setCreateAppointmentView(
          document.getElementById("create-appointment")
        );
      }
      this.setModalContent(this.getCreateAppointmentView);
      this.setModalTitle("Add New Task");
      this.setCurrentActionToAddTask(parentActionId);
      this.toggleModal();
    },

    triggerCTFASurveyPopup() {
      this.$swal({
        title: "Are you sure?",
        text: "By pressing yes a Continuous Failure to Attend survey will be sent to this customer.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-dark",
          cancelButton: "btn bg-gradient-dark btn-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.TriggerCTFASurvey();
        }
      });
    },

    async openCustomerPortalLink() {
      var loginHint = await this.StoreAuthToken();
      var sid = await GetSessionId();
      var customerLink = `${process.env.VUE_APP_CUSTOMER_PORTAL_LINK}/MyPortal/AdvisorView?sid=${sid}&cusId=${this.getCustomerId}&loginHint=${loginHint}`;
      window.open(customerLink, "_blank");
    },

    isAuditSentence(value) {
      var valid = true;
      if (value.length < 300) {
        valid = false;
      }
      this.auditDescIsSentence = valid;
    },
    isCancelSentence(value) {
      var valid = true;
      if (value.length < 10) {
        valid = false;
      }
      if (!value.includes(" ")) {
        valid = false;
      }
      this.cancellationIsSentence = valid;
    },
    getDateString(date) {
      return (
        [
          date.getDate() < 10
            ? "0" + date.getDate().toString()
            : date.getDate(),
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1).toString()
            : date.getMonth() + 1,
          date.getFullYear(),
        ].join("/") +
        " " +
        [
          date.getHours() + 1 < 10
            ? "0" + date.getHours().toString()
            : date.getHours(),
          date.getMinutes() + 1 < 10
            ? "0" + date.getMinutes().toString()
            : date.getMinutes(),
        ].join(":")
      );
    },
    openMeetingDetails(event) {
      var ev = event.target;
      var count = 0;
      while (
        !ev.classList.contains("calendar-event") &&
        count < 15 &&
        ev.parentNode != undefined
      ) {
        ev = ev.parentNode;
        count++;
      }
      var id = ev.classList[ev.classList.length - 1];
      this.TryGetActivity(id);
      //if(this.getActivity != null){
      if (this.getMeetingEventView == null) {
        this.setMeetingEventView(document.getElementById("cal-event"));
      }
      this.setModalContent(this.getMeetingEventView);
      //this.setModalContent(`<div><div style="font-size: larger;">${this.getActivity.activityTypeCode}</div><div><span style="margin-right: 1rem;">Starts:</span><span>${this.getDateString(new Date(this.getActivity.scheduledStart))}</span></div><div><span style="margin-right: 1rem;">Ends:</span><span>${this.getDateString(new Date(this.getActivity.scheduledEnd))}</span></div><div><span style="margin-right: 1rem;">Description:</span><span>${this.getActivity.description}</span></div></div>`);
      //}
      this.setModalTitle("View Meeting");
      this.toggleModal();
    },
    setCancellationReason() {
      this.UpdateActionStateCode(this.getActionIdToCancel, "Deferred", true);
      this.CreateActionPlanItemMemo(
        this.getActionIdToCancel,
        this.cancellationReason,
        "EA",
        "Archived"
      );
      this.cancellationReason = null;
      this.setActionIdToCancel(null);
    },

    createAuditPoint(force) {
      this.CreateAuditPoint(
        this.newAuditSubj,
        auditDesc,
        this.modeOfIntervention,
        true,
        force,
        this.completerData
        // this.newAuditSubj == "Exit Plan" ? this.completerData : null
      ).then(() => {
        this.newAuditDesc = null;
        var parent = document.getElementById("create-audit-desc");
        parent.getElementsByClassName("ql-editor")[0].innerHTML = "";
        this.newAuditSubj = null;
        this.modeOfIntervention = null;
        this.clearAuditSubjCheckboxes(null);
      });
    },

    setCalendarEventEL() {
      var events = document.getElementsByClassName("calendar-event");
      for (var i = 0; i < events.length; i++) {
        events[i].addEventListener("click", this.openMeetingDetails);
      }
    },
    openCustomerInfo() {
      if (this.getCustomerProfileView == null) {
        this.setCustomerProfileView(document.getElementById("customer-prof"));
      }
      this.setModalContent(this.getCustomerProfileView);
      this.setModalTitle("Customer Information");
      this.toggleModal();
    },
    openSetAuditDesc() {
      if (this.getAuditDescView == null) {
        this.setAuditDescView(document.getElementById("create-audit-desc"));
      }
      this.setModalContent(this.getAuditDescView);
      this.setModalTitle("");
      this.toggleModal();
    },
    clearAuditSubjCheckboxes(event) {
      this.newAuditSubj = null;
      for (
        var i = 0;
        i < document.getElementsByClassName("audit-subj-checkbox").length;
        i++
      ) {
        if (event == null) {
          document.getElementsByClassName("audit-subj-checkbox")[
            i
          ].checked = false;
        } else {
          if (
            document.getElementsByClassName("audit-subj-checkbox")[i] !=
            event.target
          ) {
            document.getElementsByClassName("audit-subj-checkbox")[
              i
            ].checked = false;
          }
        }
      }
    },
    formatDate(date) {
      return [
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
        date.getFullYear(),
      ].join("/");
    },

    setReasonTrySave() {
      if (
        auditDescText.length < process.env.VUE_APP_FORMAL_REVIEW_MIN_CHARACTERS
      ) {
        this.auditDescIsSentence = false;
        return;
      } else if (
        this.modeOfIntervention == null &&
        this.getIsModeOfInterventionEnabled
      ) {
        this.radioIsSelected = false;
        return;
      }
      this.toggleModal();
      this.createAuditPoint(false);
    },
  },
  watch: {
    getIsCalendarSet() {
      this.setCalendarEventEL();
    },
  },
  mounted() {
    if (document.getElementById("audit-desc-editor")) {
      var input = new Quill("#audit-desc-editor", {
        theme: "snow", // Specify theme in configuration
      });
      input.on("text-change", function () {
        auditDescText = input.getText();
        auditDesc = turndownService.turndown(input.root.innerHTML);
      });
    }
    document.getElementById("modal-close").addEventListener("click", () => {
      this.newAuditSubj = null;
      this.newAuditDesc = null;
      this.modeOfIntervention = null;
      this.radioIsSelected = true;
      var parent = document.getElementById("create-audit-desc");
      parent.getElementsByClassName("ql-editor")[0].innerHTML = "";
      this.clearAuditSubjCheckboxes(null);
      this.cancellationReason = null;
      this.setActionIdToCancel(null);
    });
    window.oncontextmenu = function () {
      return false;
    };
    //this.fireLoadingAlert("AdvisorDashboard");
    this.SetCustomerId(this.$route.params.id);
    if (this.getCustomerId == "") {
      this.fireErrorAlert("No id provided.", "AdvisorDashboard");
    } else {
      // Ensures store is loaded and ready before dependent components are initialized
      Promise.all([
        this.GetData("EA"),
        this.GetPRaPOrder(),
        this.GetCustomer(),
        this.GetAllAdvisors(),
      ]).then(() => {
        this.loaded = true;
        this.GetCustomerStrandOptions();
      });

      //this.SetAllValues(this.getCustomerId);
      //this.GetAllActions("EA");
      //this.GetAuditPoints(this.getCustomerId);
    }

    this.GetUnfilteredAssessmentResults();
  },

  beforeUnmount() {
    this.DestroyCalendar();
  },
};
</script>
<style scoped>
/* #contextMenu{
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    color: #5b5b5b;
    position: absolute;
    z-index: 2;
  }

  #contextMain{
    margin-bottom: auto;
  }

  #contextMain, #contextMove{
    background-color: rgb(245, 245, 245);
    width: fit-content;
    height: fit-content;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;  
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  }

  .contextItem:first-child{
    width: fit-content;
    border-radius: 2px 2px 0 0;
  }

  .contextItem{
    width: 100%;
    height: fit-content;
    padding: 0 0.75rem;
    border: 1px solid #f8f8f8;
    border-bottom: none;
  }

  .contextItem:hover{
    background-color: rgb(236, 236, 236);
    cursor: pointer;
  }

  .contextItem:last-child{
    border-bottom: 1px solid #f8f8f8;
    border-radius: 0 0 2px 2px;
  } */

/* .task{
    background-color: #f6f6f6;
  }

  .selectedAction{
    background-color: #f0f0f0;
  }

  .action:hover{
    cursor: pointer;
  }

  .memo-tab{
    display: flex;
    flex-direction:column;
    width: 2vw;
    min-height: 35vh;
    max-height: 75vh;
    background-color: #efefef;
    margin: 0 0 2vh 2vh; 
    justify-content: center; 
    align-items: center;
  }

  .memo-tab{
    margin-top: 3vh;
    margin-bottom: 1vh;
  }

    .memo-tab:hover{
      cursor: pointer;
    }

    .memo-tab div:hover{
      cursor: pointer;
    }

  .memo-tab-arrow{
    width: 1vw;
    margin-top: 0.6vh;
  } */
/* main{
  background-color: red;
} */
/* Customize the label (the container) */
.audit-subj-container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.audit-subj-container:first-of-type {
  margin-left: 0;
}

.audit-subj-container input {
  margin-right: 0.4rem;
}

html {
  overflow-x: hidden;
}

.audit-desc-ta,
.cancellation-reason-ta {
  border: none;
  border-radius: 0.2rem;
  background-color: rgb(235, 235, 235);
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
  color: #67748e;
  font-size: medium;
  padding: 0.2rem 0.5rem;
  margin: 0.5rem 0;
  width: 40rem;
  height: 13rem;
}

.radar-chart-st {
  height: 100%;
}

.view-cus-info {
  text-decoration: underline;
  margin-right: 0.5rem;
}

.view-cus-info:hover {
  cursor: pointer;
  color: #4d576c;
}

.pd-t {
  padding: 0.75rem 0rem 0rem 0rem;
}

.pd-b {
  padding: 0rem 0rem 0.75rem 0rem;
}

.pd-r {
  padding: 0rem 0.75rem 0rem 0rem;
}

.pd-l {
  padding: 0rem 0rem 0rem 0.75rem;
}

.pd-br {
  padding: 0rem 0.75rem 0.75rem 0rem;
}

.pd-bl {
  padding: 0rem 0rem 0.75rem 0.75rem;
}

.pd-tr {
  padding: 0.75rem 0.75rem 0rem 0rem;
}

.pd-tl {
  padding: 0.75rem 0rem 0rem 0.75rem;
}

.pd-lr {
  padding: 0rem 0.75rem;
}

.pd-tb {
  padding: 0.75rem 0rem;
}

.pd-tlr {
  padding: 0.75rem 0.75rem 0rem 0.75rem;
}

.pd-blr {
  padding: 0rem 0.75rem 0.75rem 0.75rem;
}

.pd {
  padding: 0.75rem;
}

@media (orientation: landscape) {
  .column-1 {
    width: 8.33%;
  }

  .column-2 {
    width: 16.66%;
  }

  .column-3 {
    width: 25%;
  }

  /* .column-4 {width: 33.33%;} */
  .column-4 {
    width: 35.7%;
  }

  .column-5 {
    width: 41.66%;
  }

  .column-6 {
    width: 50%;
  }

  .column-7 {
    width: 58.33%;
  }

  .column-8 {
    width: 66.66%;
  }

  .column-9 {
    width: 75%;
  }

  .column-10 {
    width: 83.33%;
  }

  .column-11 {
    width: 91.66%;
  }

  .column-12 {
    width: 100%;
  }
}

@media (orientation: portrait) {
  body {
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .l-1000-fc-w {
    width: fit-content;
  }

  .l-1000-fc-h {
    height: fit-content;
  }
}

@media (min-aspect-ratio: 21/9), (min-width: 1900px) {
  .l-21-9 {
    width: 45%;
    padding-left: 10%;
  }

  .r-21-9 {
    width: 35%;
    padding-right: 10%;
  }

  .m-21-9 {
    width: 20%;
  }

  .pd-lr-21-9 {
    padding-left: 10%;
    padding-right: 10%;
  }

  .column-4 {
    width: 36%;
  }
}

.contextOpenedOn {
  background-color: #f0f0f0;
}
</style>
<style>
.loader {
  border: 0.7rem solid #f3f3f3;
  /* Light grey */
  border-top: 0.7rem solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  animation: spin 2s linear infinite;
}

.loader-container {
  height: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .width-100-l600 {
    width: 100% !important;
  }

  .pd-t-l600 {
    padding: 0.75rem 0rem 0rem 0rem !important;
  }

  .pd-tb-l100 {
    padding: 0.75rem 0rem !important;
  }

  .pd-lr-l600 {
    padding: 0rem 0.75rem !important;
  }
}

.job-interests-cont {
  margin-bottom: 0.8rem;
}

.job-interests-cont,
.contacts-cont,
.contacts-sect {
  flex-direction: column;
}

.job-interests-sect:nth-child(1),
.contacts-sect:nth-child(1) {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.2rem;
  width: 100%;
}

.job-interests-sect:nth-child(1) svg {
  width: 1rem;
  margin-right: 0.6rem;
}

.job-interests-sect:nth-child(2) .job-interest,
.job-interest-selector,
.job-interest-search {
  background-color: #e6e6e6;
  border-radius: 0.8rem;
  margin-bottom: 0.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 100%;
  padding: 0.4rem 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.job-interest:last-child {
  margin-bottom: 0;
}

.job-interest {
  word-break: break-all;
}

.wizards {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  backdrop-filter: brightness(20%);
  overflow-y: auto;
}

.mail-icon {
  max-width: 1.2rem;
  margin-left: 1rem;
  cursor: pointer;
}

.mail-icon:hover {
  color: #47566f;
}

.phone-icon {
  max-width: 1.2rem;
  margin-left: 1rem;
  margin-right: auto;
  cursor: pointer;
}

.phone-icon.disabled {
  fill: #afb7cb;
  cursor: default;
}

.completer-summary {
  /* Modal Positioning */
  position: fixed;
  top: 10%; /* Adjust to control top margin */
  left: 50%;
  transform: translate(-50%, 0); /* Center horizontally only */
  z-index: 1000;

  /* Modal Appearance */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  /* Content Fit & Height Control */
  max-width: 80%;
  max-height: 80vh; /* 80% of viewport height */
  overflow-y: auto; /* Enable vertical scrollbar if content overflows */
}
</style>
// "[ // { // title: 'Welcome Meeting', // start: '2022-06-18', // end :
'2022-06-18', // className: 'bg-gradient-danger' // }]"
