import { ref, Ref } from 'vue';
import { defineStore } from "pinia";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import Dwp604Record from '../interfaces/Dwp604Record'

export const uc604FormStoreSchema = Yup.object({
  participantComplied: Yup.boolean(),
  alternativeActivityComplied: Yup.boolean(),
  considerNotFullyComplied: Yup.boolean(),
  activityNoLongerAppropriate: Yup.boolean(),
  sanctionDecisionDate: Yup.string(),
  sanctionStartDate: Yup.string(),
  sanctionEndDate: Yup.string(),
  uC604ConfirmStatement: Yup.boolean().oneOf([true], "This field is required*").required("This field is required*"),

  // Custom validation to ensure only one of the top four options is chosen
  onlyOneOptionSelected: Yup.mixed().test('only-one-option', 'Only one option can be selected*', function(value) {
    const { participantComplied, alternativeActivityComplied, considerNotFullyComplied, activityNoLongerAppropriate } = this.parent;
    const selectedCount = [participantComplied, alternativeActivityComplied, considerNotFullyComplied, activityNoLongerAppropriate].filter(option => option).length;
    return selectedCount === 1;
  }),

  activityNoLongerApplicableDate: Yup.string().when("activityNoLongerAppropriate", {
    is: true,
    then: Yup.string().required("Please Select a Date*"),
    otherwise: Yup.string(),
  }),
});

export const uc604FormStore = defineStore("uc604FormStore", () => {
  const { errors, useFieldModel, handleSubmit, meta } = useForm({
    validationSchema: uc604FormStoreSchema,
  });

  const loadedSavedForm = ref(false)
  const recordId: Ref<string | null> = ref(null)
  const participantComplied = useFieldModel("participantComplied");
  const activityNoLongerAppropriate = useFieldModel("activityNoLongerAppropriate");
  const alternativeActivityComplied = useFieldModel("alternativeActivityComplied");
  const considerNotFullyComplied = useFieldModel("considerNotFullyComplied");
  const activityNoLongerApplicableDate = useFieldModel("activityNoLongerApplicableDate");
  const sanctionDecisionDate = useFieldModel("sanctionDecisionDate");
  const sanctionStartDate = useFieldModel("sanctionStartDate");
  const sanctionEndDate = useFieldModel("sanctionEndDate");
  const uC604ConfirmStatement = useFieldModel("uC604ConfirmStatement");

  // Give default values
  participantComplied.value = false
  activityNoLongerAppropriate.value = false
  alternativeActivityComplied.value = false
  considerNotFullyComplied.value = false
  uC604ConfirmStatement.value = false

  const submitForm = handleSubmit(async (values, actions) => {
    console.log("submitted");
  });

  const loadFromSavedRecord = function(dwp604Record: Dwp604Record) {
    const sanctionDecisionReviewDetails = dwp604Record.sanctionDecisionReviewDetails

    participantComplied.value = convertBool(sanctionDecisionReviewDetails.participantComplied)
    activityNoLongerAppropriate.value = convertBool(sanctionDecisionReviewDetails.activityNoLongerAppropriate)
    alternativeActivityComplied.value = convertBool(sanctionDecisionReviewDetails.alternativeActivityComplied)
    considerNotFullyComplied.value = convertBool(sanctionDecisionReviewDetails.considerNotFullyComplied)
    activityNoLongerApplicableDate.value = convertDate(sanctionDecisionReviewDetails.activityNoLongerApplicableDate)
    sanctionDecisionDate.value = convertDate(sanctionDecisionReviewDetails.sanctionDecisionDate)
    sanctionStartDate.value = convertDate(sanctionDecisionReviewDetails.sanctionStartDate)
    sanctionEndDate.value = convertDate(sanctionDecisionReviewDetails.sanctionEndDate)
    loadedSavedForm.value = true
    recordId.value = dwp604Record.recordId
  }

  const resetData = function() {
    participantComplied.value = false;
    activityNoLongerAppropriate.value = false;
    alternativeActivityComplied.value = false;
    considerNotFullyComplied.value = false;
    activityNoLongerApplicableDate.value = "";
    sanctionDecisionDate.value = "";
    sanctionStartDate.value = "";
    sanctionEndDate.value = "";
    uC604ConfirmStatement.value = false;
    loadedSavedForm.value = false;
    recordId.value = null;
  }

  function convertDate(value: string) {
    if (value == "" || value == null) {
      return ""
    }

    const date = new Date(value)
    return date.toISOString().split('T')[0];
  }

  function convertBool(value: any) {
    return value === true;
  }

  
  return {
    participantComplied,
    activityNoLongerAppropriate,
    alternativeActivityComplied,
    considerNotFullyComplied,
    activityNoLongerApplicableDate,
    sanctionDecisionDate,
    sanctionStartDate,
    sanctionEndDate,
    uC604ConfirmStatement,
    errors,
    handleSubmit: submitForm, // Use submitForm instead of handleSubmit
    meta,
    resetData,
    loadFromSavedRecord,
  };

});
