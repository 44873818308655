import { RouteLocationNormalized, Router } from "vue-router";
import { msalInstance, loginRequest, tokenLoginRequest } from "../authConfig";
import {
  InteractionType,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  getDirectReportsFromGraph,
  getGroupsFromGraph,
} from "../utils/MsGraphApiCall";
import { msalConfig } from "../authConfig";

export const loggedIn = {
  ea: false,
  email: "",
  id: "",
  name: "",
};

export function registerGuard(router: Router) {
  router.beforeEach(
    async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      if (to.meta.test) {
        return true;
      }
      if (to.meta.requiresAuth) {
        const request = {
          ...loginRequest,
          redirectStartPage: to.fullPath,
        };
        const shouldProceed = await isAuthenticated(
          msalInstance,
          InteractionType.Redirect,
          request
        );
        return shouldProceed || "/error";
      }
      if (to.meta.advisorView) {
        const request = {
          ...loginRequest,
          redirectStartPage: to.fullPath,
        };
        const authenticated = await isAuthenticated(
          msalInstance,
          InteractionType.Redirect,
          request
        );
        let shouldProceed = false;
        if (authenticated) {
          const level = await getAuthLevel(msalInstance);
          if (level == 0 || level == 1) {
            loggedIn.ea = true;
            shouldProceed = true;
          }
        }
        return shouldProceed || "/error";
      }
      if (to.meta.customerView) {
        const request = {
          ...loginRequest,
          redirectStartPage: to.fullPath,
        };
        const authenticated = await isAuthenticated(
          msalInstance,
          InteractionType.Redirect,
          request
        );
        let shouldProceed = false;
        if (authenticated) {
          const level = await getAuthLevel(msalInstance);
          if (level == 0 || level == 2) {
            loggedIn.ea = false;
            shouldProceed = true;
          }
        }
        return shouldProceed || "/error";
      }
      if (to.meta.signContract) {
        const request = {
          ...loginRequest,
          redirectStartPage: to.fullPath,
        };
        const authenticated = await isAuthenticated(
          msalInstance,
          InteractionType.Redirect,
          request
        );
        let shouldProceed = false;
        if (authenticated) {
          const level = await getAuthLevel(msalInstance);
          if (to.params.type == "ea") {
            if (level == 0 || level == 1) {
              shouldProceed = true;
            }
          } else if (to.params.type == "customer") {
            if (level == 0 || level == 2) {
              shouldProceed = true;
            }
          }
        }
        return shouldProceed || "/error";
      }
      return true;
    }
  );
}

// export function registerAdvisorGuard(router:Router){
//     router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
//         if (to.meta.advisorView) {
//             const request = {
//                 ...loginRequest,
//                 redirectStartPage: to.fullPath
//             }
//             const result = await isAuthenticated(msalInstance, InteractionType.Redirect, request);
//             let shouldProceed = false;
//             if(result == 0){
//                 shouldProceed = true;
//             }
//             console.log(shouldProceed);
//             return shouldProceed || '/error';
//         }

//         return true;
//     });
// }

// export function registerCustomerGuard(router:Router){
//     router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
//         if (to.meta.customerView) {
//             const request = {
//                 ...loginRequest,
//                 redirectStartPage: to.fullPath
//             }
//             const result = await isAuthenticated(msalInstance, InteractionType.Redirect, request);
//             let shouldProceed = false;
//             if(result == 1){
//                 shouldProceed = true;
//             }
//             console.log(shouldProceed);
//             return shouldProceed || '/error';
//         }

//         return true;
//     });
// }

export async function isAuthenticated(
  instance: PublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest
): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        return true;
      }

      // User is not signed in and attempting to access protected route. Sign them in.
      if (interactionType === InteractionType.Popup) {
        return instance
          .loginPopup(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      } else if (interactionType === InteractionType.Redirect) {
        return instance
          .loginRedirect(loginRequest)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }

      return false;
    })
    .catch(() => {
      return false;
    });
}

export async function getAuthLevel(instance: PublicClientApplication) {
  const response = await instance
    .acquireTokenSilent({
      ...loginRequest,
    })
    .catch(async (e: any) => {
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest);
      }
      throw e;
    });
  loggedIn.email = response.account?.username || "";
  loggedIn.name = response.account?.name || "";
  loggedIn.id = response.uniqueId;

  const graphData = await getGroupsFromGraph(response.accessToken);
  let adv = false;
  let cus = false;

  if (graphData.value.includes(msalConfig.auth.advisorAuthProfile.id)) {
    adv = true;
  }
  if (graphData.value.includes(msalConfig.auth.customerAuthProfile.id)) {
    cus = true;
  }
  if (adv && cus) {
    return 0;
  } else if (adv) {
    return 1;
  } else if (cus) {
    return 2;
  }
}

export async function AquireApiAuthToken() {
  const response = await msalInstance
    .acquireTokenSilent({
      ...tokenLoginRequest,
    })
    .catch(async (e: any) => {
      if (e instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect(tokenLoginRequest);
      }
      throw e;
    });
  return response.accessToken;
}

export async function GetSessionId() {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    if (accounts[0].idTokenClaims != undefined) {
      return accounts[0].idTokenClaims.sid;
    }
  }
  return;
}

export async function GetDirectReports(
  instance: PublicClientApplication,
  userId: string
) {
  const response = await instance
    .acquireTokenSilent({
      ...loginRequest,
    })
    .catch(async (e: any) => {
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest);
      }
      throw e;
    });

  return await getDirectReportsFromGraph(response.accessToken, userId);
}

export async function GetBearerToken() {
  const response = await msalInstance
    .acquireTokenSilent({
      ...tokenLoginRequest,
    })
    .catch(async (e: any) => {
      if (e instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect(tokenLoginRequest);
      }
      throw e;
    });
  return response.accessToken;
}
