<template>
<div style="padding: 0.5rem 1.5rem;">
    <navBarVue style="background-color: #6F9600;"/>
    <div style="margin: 0.75rem 0;"> 
    <div class="leave-bar">
        <svg @click="navBack()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
    </div>
        <Survey />
    </div>
    <FooterVue/>
</div>
</template>
<script>
import Survey from '@/components/Survey.vue';
import navBarVue from '@/components/navBar.vue';
import FooterVue from '@/components/Footer.vue';
import { NavBackwards } from "@/router/index";
import { mapActions } from 'pinia';
import { useCommonStore } from '@/store/commonStore';

export default {
    name: "survey-view",
    data(){
        return{
            customerId: null,
            surveryName: null,
            surveyVersion: null
        }
    },
    components:{
        Survey,
        navBarVue,
        FooterVue
    },
    mounted(){
        this.customerId = this.$route.params.id;
        this.SetCustomerId(this.$route.params.id);
        this.surveryName = this.$route.params.assessmentId;
        this.surveyVersion = this.$route.params.version;
    },
    methods:{
        ...mapActions(useCommonStore,["SetCustomerId"]),
        navBack(){
            NavBackwards();
        }
    }
}
</script>
<style scoped>


.btn-reset {
    color: white;
    background-color: #de2219;
    border-color: #de2219;
}

.btn-delete {
    color: white;
    background-color: #de2219;
    border-color: #de2219;
}

.btn-filter {
    color: white;
    background-color: #5aa660;
    border-color: #5aa660;
}

.disclosure-title {
    background-color: #c11251
}

.disclosure-form {
    background-color: #F8F9FC;
}

.disclosure-form-footer {
    background-color: #F8F9FC;
    text-align: right;
}

.disclosure-table {
    /*width: 95%;
    margin: 0 auto;*/
}

.btn-prev {
    color: white;
    background-color: #c11251;
    border-color: #c11251;
    float: left;
}

.btn-next {
    color: white;
    background-color: #c11251;
    border-color: #c11251;
    /*float: right;*/
}

.btn-create {
    color: white;
    background-color: #5f2366;
    border-color: #5f2366;
}

.btn-download {
    color: white;
    background-color: #5f2366;
    border-color: #5f2366;
}

h2.disclosureheader {
    text-align: center;
}

tr:nth-child(even) {
    background: #f3f2f3
}

/*#d22552*/

tr:nth-child(odd) {
    background: #FFF
}

.form-create-left
{
    float:left;
}

.form-create-right {
    float: right;
}

a {
    color: black;
}

.action-left
{
    text-align:left;
}

.action-right {
    text-align: right;
}

.action-center {
    text-align: center;
}

#navBar {
    background-color: #ececec;
    height: fit-content;
    border-radius: 5px;
    padding: 10px 10px 10px 0;
}

.row {
    display: flex;
}

#navBar ul li:hover {
    cursor: pointer;
    color: #c11251;
}

#navBar ul li {
    margin-bottom: 10px;
    margin-right: 10px;
}

    #navBar ul li:last-of-type {
        margin-bottom: 0;
    }

#navBar ul {
    margin-bottom: 0;
}

.col {
    padding-left: 7.5px;
    width: 25%;
    margin-top: 24px;
}

.col-9 {
    padding-right: 7.5px;
    width: 75%;
}

#surveyContainer {
    width: 100%;
}



.leave-bar{
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: right;
    margin-bottom: -2.3rem;
    padding: 0.3rem 0.3rem 0 0;
    padding-right: 0.6rem;
}

.leave-bar svg{
    width: 2rem;
    height: 2rem;
    z-index: 1;
    cursor: pointer;
}

.leave-bar svg:hover{
    fill: #243147;
}
</style>