<template>
  <div class="dropdown">
    <i
      v-if="this.getCustomerNotifications.length === 0"
      id="notification-icon"
      class="fa fa-bell fa-2x"
      aria-hidden="true"
      @click="hideNotification = !hideNotification"
    >
    </i>
    <i
      v-if="this.getCustomerNotifications.length >= 1"
      id="notification-icon"
      class="fa fa-bell fa-2x"
      aria-hidden="true"
      @click="hideNotification = !hideNotification"
      style="color: #de1f12"
    >
    </i>

    <div class="notification-background" v-if="hideNotification">
      <div
        v-if="this.getCustomerNotifications.length === 0"
        id="hover"
        class="search-type notification"
        style="margin-bottom: 8px"
      >
        N/A
      </div>

      <div v-for="data in getCustomerNotifications" :key="data">
        <div
          id="hover"
          class="search-type notification"
          style="margin-bottom: 8px"
        >
          {{ data.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useCommonStore } from "@/store/commonStore";

export default {
  name: "CustomerNotifications",
  data() {
    return {
      hideNotification: false,
      dataLoaded: false,
    };
  },
  computed: {
    ...mapState(useCommonStore, [
      "getCustomer",
      "getCustomerNotifications",
      "getCustomerId",
    ]),
  },
  methods: {
    ...mapActions(useCommonStore, [
      "SetCustomerId",
      "GetCustomerNotifications",
    ]),
  },
  watch: {
    getCustomerId() {
      if (
        this.getCustomerId != null &&
        this.getCustomerId != "" &&
        !this.dataLoaded
      ) {
        this.GetCustomerNotifications();
        this.dataLoaded = true;
      }
    },
  },
  mounted() {
    if (
      this.getCustomerId != null &&
      this.getCustomerId != "" &&
      !this.dataLoaded
    ) {
      this.GetCustomerNotifications();
      this.dataLoaded = true;
    }
  },
  // formatDate(date){
  //   return [
  //     date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
  //     (date.getMonth()+1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1),
  //     date.getFullYear()
  //     ].join('/');
  // },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

#hover {
  cursor: pointer;
}

.notification {
  border-radius: 25px;
  padding: 8px;
  max-width: 500px;
}

.notification-background {
  position: absolute;
  border-radius: 25px;
  padding: 12px;
  background-color: #ffffff;
  width: 20rem;
  margin-left: -18rem;
  z-index: 20;
  margin-top: 1rem;
  -webkit-box-shadow: 3px 4px 23px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 23px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 4px 23px 1px rgba(0, 0, 0, 0.75);
}

#notification-icon:hover {
  color: #3d4555;
  cursor: pointer;
}

/* #notification-icon {
    position: fixed;
    width: 20rem;
    margin-left: -18rem;
    z-index: 20;
    margin-top: 1rem;
} */
</style>
