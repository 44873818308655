import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import './assets/css/nucleo-icons.css';
import router from "./router";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient";

//import VueAzureUploader from 'vue-azure-blob-upload';
//import ElementPlus from 'element-plus';

const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

var pinia = createPinia();
const appInstance = createApp(App);
//const azureUploader = VueAzureUploader.install(appInstance);
appInstance.config.globalProperties.$sessionTimeoutEnabled = process.env.VUE_APP_SESSION_TIMEOUT_ENABLED;

appInstance.use(pinia);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.config.globalProperties.$bearer = {date:null,value:null};
// appInstance.use(VueAzureUploader);
//appInstance.config.globalProperties.$azureUpload = VueAzureUploader;
//appInstance.provide('$azureUpload', VueAzureUploader);
appInstance.use(msalPlugin, msalInstance);
appInstance.mount('#app');

export let cachedBearer = appInstance.config.globalProperties.$bearer;
