<template>
    <div>
        <a id="download-link-anchor" hidden></a>
        <!-- Desktop View -->
        <div class="table-responsive card desktop-logic" style="padding: 0 1rem">
            <div class="overflow-x-scroll-l840">
                <div @click="() => {
                    show = !show;
                }
                    " style="
                font-size: 1.5rem;
                padding: 0.7rem;
                font-weight: 600;
                display: flex;
                align-items: center;
              ">
                    Customer Files
                    <svg v-show="!show" style="height: 1.5rem; margin-left: auto" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512">
                        <path
                            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z" />
                    </svg>
                    <svg v-show="show" style="height: 1.5rem; margin-left: auto" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512">
                        <path
                            d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z" />
                    </svg>
                </div>
                <div class="fit-content-l840 overflow-x-scroll-l840" v-show="show">
                    <div style="display: flex; flex-direction: row;">
                        <div style="display: flex; flex-direction: row; margin-left: 1rem">
                            <label style="font-size: medium; margin-right: 1rem">Items Per Page:</label>
                            <div style="display: flex; flex-direction: column">
                                <div style="display: flex; flex-direction: row">
                                    <input class="page-amount-input" :value="currentPageLength"
                                        @change="changePageLength($event.target.value)" />
                                    <svg v-show="!showPageAmountDrpDwn" @click="showPageAmountDrpDwn = true"
                                        class="tgl-page-dropdown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path
                                            d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                                    </svg>
                                    <svg v-show="showPageAmountDrpDwn" @click="showPageAmountDrpDwn = false"
                                        class="tgl-page-dropdown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path
                                            d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z" />
                                    </svg>
                                </div>
                                <div class="page-num-options" v-show="showPageAmountDrpDwn">
                                    <div v-for="index in Math.ceil(getItemAmount / pageIncrements)" :key="index" @click="() => {
                                        changePageLength(index * pageIncrements);
                                        showPageAmountDrpDwn = false;
                                    }
                                        ">
                                        {{ index * pageIncrements }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; margin-left: 1rem">
                            <label style="font-size: medium; margin-right: 1rem">Sort by:</label>
                            <div style="display: flex; flex-direction: column">
                                <div style="display: flex; flex-direction: row">
                                    <input class="page-amount-input sort-by-input" :value="currentSortType"
                                        disabled="true" />
                                    <svg v-show="!showSortByDrpDwn" @click="showSortByDrpDwn = true"
                                        class="tgl-page-dropdown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path
                                            d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                                    </svg>
                                    <svg v-show="showSortByDrpDwn" @click="showSortByDrpDwn = false"
                                        class="tgl-page-dropdown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path
                                            d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z" />
                                    </svg>
                                </div>
                                <div class="page-num-options sort-by-options" v-show="showSortByDrpDwn">
                                    <div v-for="option in getSortByTypes" :key="option" @click="() => {
                                        changeSortType(option);
                                        showSortByDrpDwn = false;
                                    }
                                        ">
                                        {{ option }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin:0 1rem 0 auto;cursor:pointer;background-color: #ededed;" class="btn" @click="triggerFileInput">
                            <span style="margin-right:0.5rem;">Upload</span>
                            <input type="file" ref="fileInput" @change="handleFileChange" style="display: none;" />
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z"/></svg>
                        </div>
                    </div>
                    <div style="margin-bottom: 1vh; display: flex; flex-direction: row">
                        <table style="height: fit-content; table-layout: fixed; width: 100%" id="datatable-basic"
                            class="table table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th style="width:5%;">
                                        <svg style="cursor:pointer;" v-if="lastViews.length > 0" @click="() => { currentView = lastViews.pop(); }"
                                            xmlns="http://www.w3.org/2000/svg" height="1em"
                                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                            <path
                                                d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM231 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-71 71L376 232c13.3 0 24 10.7 24 24s-10.7 24-24 24l-182.1 0 71 71c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L119 273c-9.4-9.4-9.4-24.6 0-33.9L231 127z" />
                                        </svg>
                                    </th>
                                    <th style="width: 45%;"
                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Name
                                    </th>
                                    <th style="width: 20%"
                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Created
                                    </th>
                                    <th style="width: 20%"
                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Modified
                                    </th>
                                    <th style="width: 10%"
                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                        Download
                                    </th>
                                </tr>
                            </thead>
                            <tbody style="border-top: 1px solid #e9ecef" v-for="item in items" :key="item">
                                <tr style="height: fit-content; padding: 0.5rem 0.5rem">
                                    <td style="padding: 0.75rem 1.5rem;">
                                        <svg v-if="item.type == 'folder'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z"/></svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/></svg>
                                    </td>
                                    <td style="padding: 0.75rem 1.5rem;word-wrap: break-word;white-space: break-spaces;"
                                        class="text-sm font-weight-normal">
                                        <span :class="item.type == 'folder' ? 'folder-link' : ''"
                                            @click="() => { if (item.type == 'folder') { lastViews.push(currentView); currentView = item.children; } }">{{
                                                item.name }}</span>
                                    </td>
                                    <td style="padding: 0.75rem 1.5rem; word-wrap: normal"
                                        class="text-sm font-weight-normal">
                                        {{ formatDate(new Date(item.dateCreated)) }}
                                    </td>
                                    <td style="padding: 0.75rem 1.5rem; word-wrap: normal"
                                        class="text-sm font-weight-normal">
                                        {{ formatDate(new Date(item.dateModified)) }}
                                    </td>
                                    <td style="padding: 0.75rem 1.5rem; word-wrap: normal; text-align: center;"
                                        class="text-sm font-weight-normal">
                                        <svg @click="downloadFile(item.url)" v-if="item.type == 'file'"
                                            style="cursor:pointer;" xmlns="http://www.w3.org/2000/svg" height="1.2rem"
                                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                            <path
                                                d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                        </svg>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- This uses 'page-change' and 'interface' events to communicate with the desktop pagination -->
                    <TablePaginationVue :items="getItems" :buttons="4"
                        @page-change="interfaces.tablePaginationDesktop.setPage" @interface="setDesktopPaginationInterface"
                        v-model="items" />
                </div>
            </div>
        </div>

        <!-- Mobile View -->
        <div class="card open-action-cont open-actions-card mobile-logic"
            style="padding: 0.6rem 1rem;margin-bottom: 0.5rem;">
            <!-- Table Header -->
            <div @click="() => {
                show = !show;
            }
                " style="
              font-size: 1.5rem;
              padding: 0.7rem;
              font-weight: 600;
              display: flex;
              align-items: center;
            ">
                Customer Files
                <svg v-show="!show" style="
                min-width: 24px;
                flex-basis: 24px;
                height: 1.5rem;
                margin-left: auto;
              " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z" />
                </svg>
                <svg v-show="show" style="
                min-width: 24px;
                flex-basis: 24px;
                height: 1.5rem;
                margin-left: auto;
              " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 310.6c-12.5 12.5-32.75 12.5-45.25 0L256 221.3L166.6 310.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l112-112C239.6 147.1 247.8 144 256 144s16.38 3.125 22.62 9.375l112 112C403.1 277.9 403.1 298.1 390.6 310.6z" />
                </svg>
            </div>

            <!-- Table content (drops down) -->
            <div v-show="show">
                <!-- Search Filters -->
                <div style="
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                padding: 0 0.7rem 0.3rem 0.7rem;
              ">
                    <div style="margin-right: 1.5rem; display: block">
                        <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                            <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                                <label style="font-size: medium; margin-right: 1rem; width: 8rem">Items Per Page:</label>
                                <div style="display: flex; flex-direction: column">
                                    <div style="display: flex; flex-direction: row">
                                        <input class="page-amount-input" :value="currentPageLength"
                                            @change="changePageLength($event.target.value)" />
                                        <svg v-show="!showPageAmountDrpDwn" @click="showPageAmountDrpDwn = true"
                                            class="tgl-page-dropdown" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512" :id="`${id}-showSvg`">
                                            <path
                                                d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                                        </svg>
                                        <svg v-show="showPageAmountDrpDwn" @click="showPageAmountDrpDwn = false"
                                            class="tgl-page-dropdown" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512" :id="`${id}-hideSvg`">
                                            <path
                                                d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z" />
                                        </svg>
                                    </div>
                                    <div class="page-num-options" v-show="showPageAmountDrpDwn">
                                        <div v-for="index in Math.ceil(
                                            getItemAmount / pageIncrements
                                        )" :key="index" @click="() => {
    changePageLength(index * pageIncrements);
    showPageAmountDrpDwn = false;
}
    ">
                                            {{ index * pageIncrements }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex; flex-direction: row;">
                                <label style="font-size: medium; margin-right: 1rem">Sort by:</label>
                                <div style="display: flex; flex-direction: column">
                                    <div style="display: flex; flex-direction: row">
                                        <input class="page-amount-input sort-by-input" :value="currentSortType"
                                            disabled="true" />
                                        <svg v-show="!showSortByDrpDwn" @click="showSortByDrpDwn = true"
                                            class="tgl-page-dropdown" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512">
                                            <path
                                                d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                                        </svg>
                                        <svg v-show="showSortByDrpDwn" @click="showSortByDrpDwn = false"
                                            class="tgl-page-dropdown" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512">
                                            <path
                                                d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z" />
                                        </svg>
                                    </div>
                                    <div class="page-num-options sort-by-options" v-show="showSortByDrpDwn">
                                        <div v-for="option in getSortByTypes" :key="option" @click="() => {
                                            currentSortType = option;
                                            showSortByDrpDwn = false;
                                        }
                                            ">
                                            {{ option }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Audit Item -->
                <div class="card h-100 open-action" v-for="(item, index) in items" :key="index"
                    style="margin-bottom:0.6rem;">
                    <div class="p-3" :style="'padding-bottom: 0;'">
                        <div style="display: flex; flex-direction: row">
                            <div style="overflow: hidden; display: block; width: 100%">
                                <h6 class="subject"
                                    style="width: 100%; display:flex; justify-content:space-between; align-items:center;">
                                    {{ item.name }}
                                    <span @click="downloadFile(item.url)" style="margin:0 0.5rem;cursor:pointer;">
                                        Download
                                        <svg style="margin-left:0.2rem;" xmlns="http://www.w3.org/2000/svg" height="1.2rem"
                                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                            <path
                                                d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                        </svg>
                                    </span>
                                </h6>
                                <h6 style="width: 100%; color: #67748e"></h6>
                            </div>

                            <div style="width: 24px; height: 100%">
                                <svg @click="extendedItems.push(item.name)" v-show="!extendedItems.includes(item.name)"
                                    class="extend-action" style="width: 100%;cursor:pointer;"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                                </svg>
                                <svg style="width: 100%;cursor:pointer;"
                                    @click="extendedItems = extendedItems.filter(i => i != (item.name))"
                                    v-show="extendedItems.includes(item.name)" class="extend-action"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <!-- Audit Item Content (drops down) -->
                    <div class="card-body" v-show="extendedItems.includes(item.name)" style="padding: 0 1rem 1rem 1rem">
                        <ul class="list-group">
                            <li style="cursor: pointer; display: block" class="pt-0 text-sm border-0 list-group-item ps-0">
                                <strong class="text-dark" style="text-overflow: ">Created on:</strong>
                                &nbsp;
                                <div style="color: limegreen">
                                    {{ formatDate(new Date(item.dateCreated)) }}
                                </div>
                            </li>
                            <li style="cursor: pointer; display: block" class="pt-0 text-sm border-0 list-group-item ps-0">
                                <strong class="text-dark" style="text-overflow: ">Modified on:</strong>
                                &nbsp;
                                <div style="color: limegreen">
                                    {{ formatDate(new Date(item.dateModified)) }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- This uses 'page-change' and 'interface' events to communicate with the desktop pagination -->
                <TablePaginationVue :items="getItems" :buttons="4" @page-change="interfaces.tablePaginationMobile.setPage"
                    @interface="setMobilePaginationInterface" v-model="items" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import TablePaginationVue from "@/components/TablePagination.vue";
import { storeToRefs } from 'pinia';
import { useCommonStore } from "@/store/commonStore.js";
const _ = require("lodash");

const { DownloadFileAsync, GetAllCustomerFilesAsync, UploadFileAsync} = useCommonStore();
const { customerFiles, getCustomerId} = storeToRefs(useCommonStore());

const currentView = ref(customerFiles.value?.children ?? []);
const lastViews = ref([]);

const interfaces = ref({
    tablePaginationDesktop: {
        setPage: () => { },
        setPageSize: () => { },
    },

    tablePaginationMobile: {
        setPage: () => { },
        setPageSize: () => { },
    },
})

const items = ref([]);
const extendedItems = ref([]);


const show = ref(false);
const currentPageLength = ref(5);
const showPageAmountDrpDwn = ref(false);
const sortByTypes = ref(['Name (A-Z)', 'Created (newest first)', 'Created (latest first)', 'Modified (newest first)', 'Modified (latest first)']);
const currentSortType = ref('Created (newest first)');
const showSortByDrpDwn = ref(false);
const pageIncrements = ref(5);

const getItems = computed(() => {
    let values = [...currentView.value];

    switch (currentSortType.value) {
        case 'Name (A-Z)':
            values = values.sort((a, b) => {
                const valueA = a.name.toUpperCase();
                const valueB = b.name.toUpperCase();
                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
                return 0;
            });
            break;
        case 'Created (newest first)':
            values = values.sort((a, b) =>
                new Date(a.dateCreated) -
                new Date(b.dateCreated))
                .reverse();
            break;
        case 'Created (latest first)':
            values = values.sort((a, b) =>
                new Date(a.dateCreated) -
                new Date(b.dateCreated));
            break;
        case 'Modified (newest first)':
            values = values.sort((a, b) =>
                new Date(a.dateModified) -
                new Date(b.dateModified))
                .reverse();
            break;
        case 'Modified (latest first)':
            values = values.sort((a, b) =>
                new Date(a.dateModified) -
                new Date(b.dateModified));
            break;
    }
    return values;
})




const fileInput = ref(null);

function triggerFileInput() {
  fileInput.value.click();
}

async function handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const fileBase64 = e.target.result; 
            try {
                await UploadFileAsync(file.name, getCustomerId.value, fileBase64);
                GetAllCustomerFilesAsync(getCustomerId.value);
            } catch (error) {
                console.error("File upload error:", error);
            }
        };
        reader.readAsDataURL(file);
    }
}

async function downloadFile(item) {

    await DownloadFileAsync(item, getCustomerId.value)
}


const getItemAmount = computed(() => {
    return getItems.value.length;
})

const getSortByTypes = computed(() => {
    return sortByTypes.value.filter(t => t != currentSortType.value);
})

function setDesktopPaginationInterface(childInterface) {
    interfaces.value.tablePaginationDesktop = childInterface;
}

function setMobilePaginationInterface(childInterface) {
    interfaces.value.tablePaginationMobile = childInterface;
}

function changePageLength(value) {
    const length = parseInt(value);
    currentPageLength.value = length;
}

function changeSortType(type) {
    currentSortType.value = type;
}

function formatDate(date) {
    return [[
        date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
        date.getFullYear(),
    ].join("/"),
    [
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
    ].join(":")
    ].join(" ");
}

watch(() => _.cloneDeep(customerFiles.value), () => {
    if (customerFiles.value) {
        currentView.value = customerFiles.value.children;
    }
})

watch(() => _.cloneDeep(getCustomerId.value), () => {
    if (getCustomerId.value) {
        GetAllCustomerFilesAsync(getCustomerId.value);
    }
})

watch(() => _.cloneDeep(currentPageLength.value), () => {
    interfaces.value.tablePaginationDesktop.setPageSize(
        currentPageLength.value
    );
    interfaces.value.tablePaginationMobile.setPageSize(currentPageLength.value);
})

onMounted(() => {
    if (getCustomerId.value) {
        GetAllCustomerFilesAsync(getCustomerId.value);
    }
}) 

</script>
<style scoped>
@media (max-width: 900px) {
    .desktop-logic {
        display: none !important;
    }

    .mobile-logic {
        display: block !important;
    }
}

@media (min-width: 900px) {
    .desktop-logic {
        display: block !important;
    }

    .mobile-logic {
        display: none !important;
    }
}

.fileLink {
    text-decoration: underline;
    cursor: pointer;
}

.file-type-input {
    width: 10rem;
}

.file-type-options {
    width: 10rem;
}

.sort-by-options {
    width: 13rem;
}

.sort-by-input {
    width: 13rem;
}

.folder-link {
    text-decoration: underline;
    cursor: pointer;
}

.folder-link:hover{
    color:#394152;
}
</style>

