import { defineStore } from "pinia";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { useCommonStore } from "./commonStore.js";

export const useMainStore = defineStore({
  id: 'mainStore',
  state: () => ({
    hideConfigButton: true,
    isPinned: true,
    showConfig: false,
    isRTL: false,
    color: "#147EC2",
    sidebarType: "bg-white",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: false,
    showNavbar: false,
    showFooter: false,
    showMain: true,
    layout: "default",
    bootstrap,
    upcomingMeetingsUrl: process.env.VUE_APP_GET_UPCOMING_MEETINGS_URL,
    isMandationEnabled: process.env.VUE_APP_MANDATION_ENABLED === "true",
    is603Enabled: process.env.VUE_APP_603_ENABLED === "true",
    is604Enabled: process.env.VUE_APP_604_ENABLED === "true",
    isEmailEnabled: process.env.VUE_APP_EMAIL_ENABLED === "true",
    isSmsEnabled: process.env.VUE_APP_SMS_ENABLED === "true",
    isFSSEnabled: process.env.VUE_APP_FSS_ENABLED === "true",
    isAdvisorCustomerLink: process.env.VUE_APP_ADVISOR_CUSTOMER_LINK === "true",
    isSessionTimeoutEnabled: process.env.VUE_APP_SESSION_TIMEOUT_ENABLED === "true",
    isCustomerProfileFeaturesEnabled: process.env.VUE_APP_CUSTOMER_PROFILE_FEATURES_ENABLED === "true",
    isCreatedOnEnabled: process.env.VUE_APP_CREATED_ON_ENABLED === "true",
    isReadOnlyActionEnabled: process.env.VUE_APP_READ_ONLY_ACTION_ENABLED === "true",
    isCustomerNotificationsEnabled: process.env.VUE_APP_CUSTOMER_NOTIFICATIONS_ENABLED === "true",
    isAuditTableFeaturesEnabled: process.env.VUE_APP_AUDIT_TABLE_FEATURES_ENABLED === "true",
    isFailedToCompleteEnabled: process.env.VUE_APP_FAILED_TO_COMPLETE_ENABLED === "true",
    isModeOfInterventionEnabled: process.env.VUE_APP_MODE_OF_INTERVENTION_ENABLED === "true",
    isCompletedByCustomerEnabled: process.env.VUE_APP_COMPLETED_BY_CUSTOMER === "true",
    isCustomerVoiceEnabled: process.env.VUE_APP_CUSTOMER_VOICE_ENABLED === "true",
    isCustomerFilesEnabled: process.env.VUE_APP_CUSTOMER_FILES_ENABLED === "true",
    isStrandEnabled: process.env.VUE_APP_STRAND_ENABLED === "true",
    isRightToWorkEnabled: process.env.VUE_APP_RIGHT_TO_WORK_ENABLED === "true",
    isPathwayEnabled: process.env.VUE_APP_PATHWAY_ENABLED === "true"
  }),
  getters: {
    getHideConfigButton: (state) => state.hideConfigButton,
    getIsPinned: (state) => state.isPinned,
    getShowConfig: (state) => state.showConfig,
    getIsRTL: (state) => state.isRTL,
    getColor: (state) => state.color,
    getSidebarType: (state) => state.sidebarType,
    getDarkMode: (state) => state.darkMode,
    getIsNavFixed: (state) => state.isNavFixed,
    getIsAbsolute: (state) => state.isAbsolute,
    getShowNavs: (state) => state.showNavs,
    getShowSidenav: (state) => state.showSidenav,
    getShowNavbar: (state) => state.showNavbar,
    getShowFooter: (state) => state.showFooter,
    getShowMain: (state) => state.showMain,
    getLayout: (state) => state.layout,
    getBootstrap: (state) => state.bootstrap,
    getUpcomingMeetingsUrl: (state) => state.upcomingMeetingsUrl,
    getIsMandationEnabled: (state) => 
    { 
      const commonStore = useCommonStore(); 
      return commonStore.getCustomer.featureFlags 
      ? commonStore.getCustomer.featureFlags.mandationEnabled && state.isMandationEnabled 
      : state.isMandationEnabled 
    },
    getIs603Enabled: (state) =>
    { 
      const mainStore = useMainStore(); 
      return state.is603Enabled && mainStore.getIsMandationEnabled
    },
    getIsFSSEnabled: (state) =>
    { 
      const mainStore = useMainStore(); 
      return state.isFSSEnabled && mainStore.getIsMandationEnabled
    },
    getIs604Enabled: (state) =>
    { 
      const mainStore = useMainStore(); 
      return state.is604Enabled && mainStore.getIsMandationEnabled
    },
    getIsEmailEnabled: (state) => state.isEmailEnabled,
    getIsSmsEnabled: (state) => state.isSmsEnabled,
    getIsAdvisorCustomerLink: (state) => state.isAdvisorCustomerLink,
    getIsSessionTimeoutEnabled: (state) => state.isSessionTimeoutEnabled,
    getIsCustomerProfileFeaturesEnabled: (state) => state.isCustomerProfileFeaturesEnabled,
    getIsCreatedOnEnabled: (state) => state.isCreatedOnEnabled,
    getIsReadOnlyActionEnabled: (state) => state.isReadOnlyActionEnabled,
    getIsCustomerNotificationsEnabled: (state) => state.isCustomerNotificationsEnabled,
    getIsAuditTableFeaturesEnabled: (state) => state.isAuditTableFeaturesEnabled,
    getIsFailedToCompleteEnabled: (state) => state.isFailedToCompleteEnabled,
    getIsModeOfInterventionEnabled: (state) => state.isModeOfInterventionEnabled,
    getIsCompletedByCustomerEnabled: (state) => state.isCompletedByCustomerEnabled,
    getIsCustomerFilesEnabled: (state) => 
    { 
      const commonStore = useCommonStore(); 
      return commonStore.getCustomer.featureFlags 
        ? commonStore.getCustomer.featureFlags.customerFiles && state.isCustomerFilesEnabled 
        : state.isCustomerFilesEnabled 
    },
    getIsCustomerVoiceEnabled: (state) => {
      return state.isCustomerVoiceEnabled && isCustomerFeatureFlagEnabled('customerVoice')
    },
    getIsStrandEnabled: (state) => 
    { 
      const commonStore = useCommonStore(); 
      return commonStore.getCustomer.featureFlags 
        ? commonStore.getCustomer.featureFlags.strandEnabled : state.isStrandEnabled
    },
    getIsRightToWorkEnabled: (state) => 
    { 
      const commonStore = useCommonStore(); 
      return commonStore.getCustomer.featureFlags 
        ? commonStore.getCustomer.featureFlags.rightToWorkEnabled && state.isRightToWorkEnabled : false
    },
    getIsPathwayEnabled: (state) => 
      { 
        const commonStore = useCommonStore(); 
        return commonStore.getCustomer.featureFlags 
          ? commonStore.getCustomer.featureFlags.pathwayEnabled && state.isPathwayEnabled : false
      },
  },
  actions: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleSidebarColor({ commit }, payload) {
      commit("setSidebarType", payload);
    },
    toggleConfigurator() {
      this.showConfig = !this.showConfig;
    },
    navbarMinimize() {
      const sidenav_show = document.querySelector("#app");

      if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        this.isPinned = true;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        this.isPinned = false;
      } else if (sidenav_show.classList.contains("g-sidenav-show")) {
        sidenav_show.classList.add("g-sidenav-pinned");
        this.isPinned = true;
      }
    },
    setSidebarType(payload) {
      this.sidebarType = payload;
    },
    navbarFixed() {
      if (this.isNavFixed === false) {
        this.isNavFixed = true;
      } else {
        this.isNavFixed = false;
      }
    },
    toggleDefaultLayout() {
      this.showNavbar = !this.showNavbar;
      this.showSidenav = !this.showSidenav;
      this.showFooter = !this.showFooter;
    },
  },
})


/**
 * Gets whether the specified feature flag is enabled for the active customer.
 * @param {*} featureFlag 
 * @returns 
 */
function isCustomerFeatureFlagEnabled(featureFlag) {
  const commonStore = useCommonStore(); 
  console.debug(commonStore.getCustomer.featureFlags )
  return commonStore.getCustomer.featureFlags 
    ? (commonStore.getCustomer.featureFlags[featureFlag] ?? true)
    : true
}