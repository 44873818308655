import { defineStore } from "pinia";
import ActionManager from "@/services/actionManager";
import actionManager from "../services/actionManager";
import CalendarManager from "../services/calendarManager";
import ContactManager from "../services/contactManager";
import CatalogManager from "../services/catalogManager";
import TeamManager from "../services/TeamManager";
import PipManager from "../services/PipManager";
import MandationManager from "../services/MandationManager";
import Swal from "sweetalert2";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import catalogManager from "../services/catalogManager";
import { RouteToAdvisor } from "../router/index";
import Chart from "chart.js/auto";
import ContractManager from "../services/ContractManager";
import { loggedIn } from "../router/Guard";
import { RTFJS } from "rtf.js";
import customerSpendManager from "../services/customerSpendManager";
import customerFilesManager from "../services/customerFilesManager";
import customerVoiceManager from "../services/customerVoiceManager";
import { marked } from "marked";
import { useMainStore } from "./mainStore";
import { useAdvisorDashboardStore } from "./advisorDashboard";
import contactManager from "../services/contactManager";
import handleAxiosError from "../utils/AxiosErrorHandler";
import { AxiosError } from "axios";
import { showErrorAlert } from "../utils/SwalUtil";

export const useCommonStore = defineStore({
  id: "commonStore",
  state: () => ({
    recommendedActionTemplates: [],
    themedAssessmentJson: null,
    allAssessmentResults: [],
    themedAssessmentResults: { questionSets: [], answerSets: [] },
    unfilteredAssessmentResults: { questionSets: [], answerSets: [] },
    actionAssessmentResults: { questionSets: [], answerSets: [] },
    customerId: "",
    customer: {
      firstName: "",
      lastName: "",
      fullName: "",
      email: "",
      mobile: "",
      landline: "",
      DoB: null,
      memorableId: "",
      caseStatus: "",
      sectorInterests: [],
      salutation: "",
      exForces: false,
      rightToWork: "",
      pathway:"",
      lifeCycle:"",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      postalCode: "",
      advisor: {
        id: "",
        firstName: "",
        lastName: "",
        fullName: "",
        email: "",
        phone: "",
      },
      workCoach: {
        firstName: "",
        lastName: "",
        fullName: "",
        email: "",
        phone: "",
      },
    },
    searchResult: [],
    filteredSearchResults: [],
    showContactLoad: false,
    PRaPOrder: null,
    assessmentResults: [],
    assessmentHistory: null,
    nextAssessmentDate: null,
    externalActivites: [],
    activity: null,
    activities: [],
    calendarEvents: [],
    actionPlan: {},
    upcomingMeetings: [],
    pastMeetings: [],
    nextMeetingDate: "N/A",
    actionPlanResult: [],
    actions: {
      recommended: [],
      current: [],
      completed: [],
      archived: [],
      failed: [],
    },
    monthlyCompletedData: {
      months: ["", "", "", "", "", "", "", "", ""],
      values: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    monthlyCompletedDataDateRange: "",
    memos: [],
    memosForUi: {
      recommended: [
        {
          id: "",
          createdBy: "",
          message: "Select an action.",
          visibility: "",
          stateCode: "",
          statusCode: "",
        },
      ],
      current: [
        {
          id: "",
          createdBy: "",
          message: "Select an action.",
          visibility: "",
          stateCode: "",
          statusCode: "",
        },
      ],
      completed: [
        {
          id: "",
          createdBy: "",
          message: "Select an action.",
          visibility: "",
          stateCode: "",
          statusCode: "",
        },
      ],
      archived: [
        {
          id: "",
          createdBy: "",
          message: "Select an action.",
          visibility: "",
          stateCode: "",
          statusCode: "",
        },
      ],
      abIntegro: [
        {
          id: "",
          createdBy: "",
          message: "Select an action.",
          visibility: "",
          stateCode: "",
          statusCode: "",
        },
      ],
    },
    newActionPlanMemos: [],
    loadingAlertActive: [],
    errorAlertActive: false,
    calendar: undefined,
    auditPoints: [],
    currentAuditPointActions: {
      recommended: [],
      current: [],
      completed: [],
      archived: [],
    },
    currentAuditToSign: {},
    currentContract: {},
    creatingMemo: {
      type: null,
      createdByEa: null,
      actionId: null,
      taskId: null,
    },
    assessmentGraphData: [],
    activityToConvert: null,
    lastLoggedInAbintegro: null,
    pipCase: {
      caseReference: "",
      caseUrl: "",
      inputs: {},
      outputs: {},
      notFound: [],
    },
    customerSpendHistory: [],
    customerSpendConfig: {
      paymentStatuses: [],
      paymentMethods: [],
      paymentTypes: [],
    },
    customerProjectedAudits: [],
    customerSignedAudits: [],
    customerGeneratedAudits: [],
    customerStatus: [],
    actionToMandate: null,
    dueDate: null,
    allAdvisors: [],
    mandation: {
      UcMandation: false,
      JsaMandation: false,
      JsaSavedDocument: false,
      UcSavedDocument: false,
      uc603: false,
      jsa603: false,
      uc604: false,
    },
    previewData: {
      selectedAdvisor: "",
      submittedEvidence: "",
      complianceCondition: "",
      evidenceCheck: false,
      dueDateLong: "",
    },
    savedManData: {
      createdOn: "",
      complianceDetails: [
        {
          name: "",
          value: "",
        },
      ],
    },
    saved603Data: {
      status: "",
    },
    saved604Data: {
      status: "",
    },
    validationErrors: {
      auditCreation: {
        errors: [],
      },
    },
    customerNotifications: [
      // {
      //   name: "",
      //   description: "",
      //   url: "",
      //   startDate: "",
      //   endDate: ""
      // }
    ],
    loggedInAdvisorsTeams: [],
    manFormData: {
      Title: "",
      activityContactName: "",
      inputValue: "",
      date: "",
      Duedate: "",
      Evidence: "",
      ComplianceCondition: "",
      isIssuedFaceToFace: "",
      isIssuedByPost: "",
      isGroupAppointment: false,
    },
    isEditingManForm: false,
    currentManFormId: "",
    ucManDirectFormData: null,
    jsaManDirectFormData: null,
    sectorDefinitions: [],
    auditForceCreateWarning: null,
    auditForceCreatePopupIsVisible: false,
    updateAssessmentItemDueCallback: null,
    customerStrandOptions: [],
    customerFiles: null,
    reportingCustomers: [],
    performPassportCheck: false,
    hasCreatedActions: false,
    pathway: null,
    actionSubjectById: null,
    configInformation: null,
    postconfiguration: null
  }),
  getters: {
    getRecommendedActionTemplates: (state) => state.recommendedActionTemplates,
    getThemedAssessmentJson: (state) => state.themedAssessmentJson,
    getThemedAssessmentResults: (state) => state.themedAssessmentResults,
    getUnfilteredAssessmentResults: (state) => state.unfilteredAssessmentResults,
    getActionSubjectById: (state) => state.actionSubjectById,
    getConfigInformation: (state) => state.configInformation,
    getpostConfiguration: (state) => state.postConfiguration,
    getAllAssessmentResults: (state) => state.allAssessmentResults,
    getActionAssessmentResults: (state) => state.actionAssessmentResults,
    getCustomerId: (state) => state.customerId,
    getCustomer: (state) => state.customer,
    getSearchResult: (state) => state.searchResult,
    getFilteredSearchResults: (state) => state.filteredSearchResults,
    getShowContactLoad: (state) => state.showContactLoad,
    getPRaPOrder: (state) => state.PRaPOrder,
    getAssessmentResults: (state) => state.assessmentResults,
    getAssessmentHistory: (state) => state.assessmentHistory,
    getNextAssessmentDate: (state) => state.nextAssessmentDate,
    getExternalActivites: (state) => state.externalActivites,
    getActivity: (state) => state.activity,
    getActivities: (state) => state.activities,
    getUpcomingMeetings: (state) => state.upcomingMeetings,
    getPastMeetings: (state) => state.pastMeetings,
    getCalendarEvents: (state) => state.calendarEvents,
    getActionPlan: (state) => state.actionPlan,
    getNextMeetingDate: (state) => state.nextMeetingDate,
    getActionPlanResult: (state) => state.actionPlanResult,
    getMemos: (state) => state.memos,
    getMemosForUi: (state) => state.memosForUi,
    getNewActionPlanMemos: (state) => state.newActionPlanMemos,
    getActions: (state) => state.actions,
    getMonthlyCompletedData: (state) => state.monthlyCompletedData,
    getMonthlyCompletedDataDateRange: (state) =>
      state.monthlyCompletedDataDateRange,
    getAuditPoints: (state) => state.auditPoints,
    getCurrentContract: (state) => state.currentContract,
    getCurrentAuditPointActions: (state) => state.currentAuditPointActions,
    getCurrentAuditToSign: (state) => state.currentAuditToSign,
    getIsCalendarSet: (state) => (state.calendar == undefined ? false : true),
    getCreatingMemo: (state) => state.creatingMemo,
    getAssessmentGraphData: (state) => state.assessmentGraphData,
    getActivityToConvert: (state) => state.activityToConvert,
    getLastLoggedInAbintegro: (state) => state.lastLoggedInAbintegro,
    getPipCase: (state) => state.pipCase,
    getActionToMandate: (state) => state.actionToMandate,
    getDueDate: (state) => state.dueDate,
    getCustomerSpendHistory: (state) => state.customerSpendHistory,
    getCustomerSpendConfig: (state) => state.customerSpendConfig,
    getCustomerProjectedAudits: (state) => state.customerProjectedAudits,
    getCustomerSignedAudits: (state) => state.customerSignedAudits,
    getCustomerGeneratedAudits: (state) => state.customerGeneratedAudits,
    getCustomerStatus: (state) => state.customerStatus,
    getAllAdvisors: (state) => state.allAdvisors,
    getMandationForm: (state) => state.mandation,
    getPreviewData: (state) => state.previewData,
    getSavedManData: (state) => state.savedManData,
    getSaved603Data: (state) => state.saved603Data,
    getSaved604Data: (state) => state.saved604Data,
    getValidationErrors: (state) => state.validationErrors,
    getCustomerNotifications: (state) => state.customerNotifications,
    getLoggedInAdvisorsTeams: (state) => state.loggedInAdvisorsTeams,
    getManFormData: (state) => state.manFormData,
    getUc603FormData: (state) => state.uc603FormData,
    getIsEditingManForm: (state) => state.isEditingManForm,
    getCurrentManFormId: (state) => state.currentManFormId,
    getUc603FormDataPart2: (state) => state.uc603FormDataPart2,
    getUcManDirectFormData: (state) => state.ucManDirectFormData,
    getJsaManDirectFormData: (state) => state.jsaManDirectFormData,
    getUc604FormData: (state) => state.uc604FormData,
    getUc604FormDataPart2: (state) => state.uc604FormDataPart2,
    getSectorDefinitions: (state) => state.sectorDefinitions,
    getAuditForceCreatePopupIsVisible: (state) =>
      state.auditForceCreatePopupIsVisible,
    getAuditForceCreateWarning: (state) => state.auditForceCreateWarning,
    getUpdateAssessmentItemDueCallback: (state) =>
      state.updateAssessmentItemDueCallback,
    getCustomerStrandOptions: (state) => state.customerStrandOptions,
    getCaseStatusOptions: () => JSON.parse(process.env.VUE_APP_CASE_STATUSES),
    getReportingCustomers: (state) => state.reportingCustomers,
    getPerformPassportCheck: (state) => state.performPassportCheck,
    getHasCreatedActions: (state) => state.hasCreatedActions,
    getPathway: (state) => state.pathway,
  },
  actions: {
    async GetAllCustomerFilesAsync(customerId) {
      this.fireLoadingAlert("GetAllCustomerFilesAsync");
      var resp = await customerFilesManager.GetAllCustomerFilesAsync(
        customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAllCustomerFilesAsync"
          );
          this.closeAlert("GetAllCustomerFilesAsync");
          return;
        }
      }
      this.closeAlert("GetAllCustomerFilesAsync");
      this.customerFiles = resp.data;
    },
    async DownloadFileAsync(fileUrl, customerId) {
      console.log(customerId);
      this.fireLoadingAlert("DownloadFileAsync");
      var resp = await customerFilesManager.DownloadFileAsync(
        fileUrl,
        customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "DownloadFileAsync"
          );
          this.closeAlert("DownloadFileAsync");
          return;
        }
      }
      this.closeAlert("DownloadFileAsync");
    },

    async UploadFileAsync(fileName, customerid, base64) {
      this.fireLoadingAlert("UploadFileAsync");
      var resp = await customerFilesManager.UploadFileAsync(
        fileName,
        customerid,
        base64
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UploadFileAsync"
          );
          this.closeAlert("UploadFileAsync");
          return;
        }
      }
      this.closeAlert("UploadFileAsync");
    },

    setPathway(value) {
      this.pathway = value;
    },

    setHasCreatedActions(value) {
      this.hasCreatedActions = value;
    },

    setPerformPassportCheck(value) {
      this.performPassportCheck = value;
    },

    setUpdateAssessmentItemDueCallback(value) {
      this.updateAssessmentItemDueCallback = value;
    },
    set604FormData(
      participantComplied,
      activityNoLongerAppropriate,
      alternativeActivityComplied,
      considerNotFullyComplied,
      activityNoLongerApplicableDate,
      sanctionDecisionDate,
      sanctionStartDate,
      sanctionEndDate,
      uC604ConfirmStatement
    ) {
      this.uc604FormData.participantComplied = participantComplied;
      this.uc604FormData.activityNoLongerAppropriate =
        activityNoLongerAppropriate;
      this.uc604FormData.alternativeActivityComplied =
        alternativeActivityComplied;
      this.uc604FormData.considerNotFullyComplied = considerNotFullyComplied;
      this.uc604FormData.activityNoLongerApplicableDate =
        activityNoLongerApplicableDate;
      this.uc604FormData.sanctionDecisionDate = sanctionDecisionDate;
      this.uc604FormData.sanctionStartDate = sanctionStartDate;
      this.uc604FormData.sanctionEndDate = sanctionEndDate;
      this.uc604FormData.uC604ConfirmStatement = uC604ConfirmStatement;
    },
    set604FormDataPart2(
      activityToldDoTo,
      activityFailedToDo,
      activityConsiderFailedReason,
      activityConsiderCompleteReason,
      uC604FinalConfirmStatement
    ) {
      this.uc604FormDataPart2.activityToldDoTo = activityToldDoTo;
      this.uc604FormDataPart2.activityFailedToDo = activityFailedToDo;
      this.uc604FormDataPart2.activityConsiderFailedReason =
        activityConsiderFailedReason;
      this.uc604FormDataPart2.activityConsiderCompleteReason =
        activityConsiderCompleteReason;
      this.uc604FormDataPart2.uC604FinalConfirmStatement =
        uC604FinalConfirmStatement;
    },
    set603FormData(
      isSatisfied,
      isNotSatisfied,
      isMandatory,
      isNotMandatory,
      isReasonable,
      isNotReasonable,
      isAttempted,
      isNotAttempted,
      face2FaceContact
    ) {
      this.uc603FormData.isSatisfied = isSatisfied;
      this.uc603FormData.isNotSatisfied = isNotSatisfied;
      this.uc603FormData.isMandatory = isMandatory;
      this.uc603FormData.isNotMandatory = isNotMandatory;
      this.uc603FormData.isReasonable = isReasonable;
      this.uc603FormData.isNotReasonable = isNotReasonable;
      this.uc603FormData.isAttempted = isAttempted;
      this.uc603FormData.isAttempted = isNotAttempted;
      this.uc603FormData.face2FaceContact = face2FaceContact;
    },
    set603FormDataPart2(
      isRearranged,
      isNotRearranged,
      OriginalDueDate,
      OriginalMandatedAction,
      RearrangementDenial,
      choiceYes,
      choiceNo,
      failActivity,
      fullDetails,
      volunteerInfo,
      otherInfo,
      submitYes,
      submitNo
    ) {
      this.uc603FormDataPart2.isRearranged = isRearranged;
      this.uc603FormDataPart2.isNotRearranged = isNotRearranged;
      this.uc603FormDataPart2.OriginalDueDate = OriginalDueDate;
      this.uc603FormDataPart2.OriginalMandatedAction = OriginalMandatedAction;
      this.uc603FormDataPart2.RearrangementDenial = RearrangementDenial;
      this.uc603FormDataPart2.choiceYes = choiceYes;
      this.uc603FormDataPart2.choiceNo = choiceNo;
      this.uc603FormDataPart2.failActivity = failActivity;
      this.uc603FormDataPart2.fullDetails = fullDetails;
      this.uc603FormDataPart2.volunteerInfo = volunteerInfo;
      this.uc603FormDataPart2.otherInfo = otherInfo;
      this.uc603FormDataPart2.submitYes = submitYes;
      this.uc603FormDataPart2.submitNo = submitNo;
    },
    setPreviewData(
      advisor,
      evidence,
      complianceCondition,
      isTheyEvidence,
      longDueDate
    ) {
      this.previewData.selectedAdvisor = advisor;
      this.previewData.submittedEvidence = evidence;
      this.previewData.complianceCondition = complianceCondition;
      this.previewData.evidenceCheck = isTheyEvidence;
      this.previewData.dueDateLong = longDueDate;
    },
    setJsaMandationFormVisability(visibility) {
      this.mandation.JsaMandation = visibility;
    },
    setUcMandationFormVisability(visibility) {
      this.mandation.UcMandation = visibility;
    },
    setUcSavedVisability(visibility) {
      this.mandation.UcSavedDocument = visibility;
    },
    setJsaSavedVisability(visibility) {
      this.mandation.JsaSavedDocument = visibility;
    },
    setUc603Visibility(visibility) {
      this.mandation.uc603 = visibility;
    },
    setJsa603Visibility(visibility) {
      this.mandation.jsa603 = visibility;
    },
    setUc604Visibility(visibility) {
      this.mandation.uc604 = visibility;
    },
    async GetAllAdvisors() {
      this.fireLoadingAlert("GetAllAdvisors");
      var resp = await ContactManager.GetAllAdvisors();
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAllAdvisors"
          );
          return;
        }
      }
      this.allAdvisors = resp.data;
      this.closeAlert("GetAllAdvisors");
    },

    SetActionToMandate(action) {
      this.actionToMandate = action;
    },
    setDueDate(dueDate) {
      this.dueDate = dueDate;
    },
    addDaysToDate(date, days) {
      days = parseInt(days);

      if (isNaN(days)) {
        console.error("A valid number of 'days' was not provided.");
      }

      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    setCreatingMemo(type, createdByEa, actionId, taskId) {
      this.creatingMemo.type = type;
      this.creatingMemo.createdByEa = createdByEa;
      this.creatingMemo.actionId = actionId;
      this.creatingMemo.taskId = taskId;
    },
    getUTCDateString(date) {
      return date.toISOString();
    },

    getLocalDateString(date) {
      return (
        [
          [
            date.getFullYear(),
            date.getMonth() + 1 < 10
              ? `0${date.getMonth() + 1}`
              : date.getMonth() + 1,
            date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
          ].join("-"),
          [
            date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
            date.getMinutes() < 10
              ? `0${date.getMinutes()}`
              : date.getMinutes(),
            date.getSeconds() < 10
              ? `0${date.getSeconds()}`
              : date.getSeconds(),
          ].join(":"),
        ].join("T") + "Z"
      );
    },
    formatLocalOffsetDateDB(date) {
      var sign = date.getTimezoneOffset() > 0 ? "-" : "+";
      var origOffset = Math.abs(date.getTimezoneOffset());
      var hours = Math.floor(origOffset / 60);
      var minutes = origOffset % 60;
      var offset =
        sign +
        (hours < 10 ? `0${hours}` : hours) +
        ":" +
        (minutes < 10 ? `0${minutes}` : minutes);
      return (
        [
          [
            date.getFullYear(),
            date.getMonth() + 1 < 10
              ? `0${date.getMonth() + 1}`
              : date.getMonth() + 1,
            date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
          ].join("-"),
          [
            date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
            date.getMinutes() < 10
              ? `0${date.getMinutes()}`
              : date.getMinutes(),
            date.getSeconds() < 10
              ? `0${date.getSeconds()}`
              : date.getSeconds(),
          ].join(":"),
        ].join("T") + `.00${offset}`
      );
    },
    formatUTCDateInputString(date) {
      return [
        date.getUTCFullYear(),
        date.getUTCMonth() + 1 < 10
          ? `0${date.getUTCMonth() + 1}`
          : date.getUTCMonth() + 1,
        date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate(),
      ].join("-");
    },
    formatLocalDateInputString(date) {
      return [
        date.getFullYear(),
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1,
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
      ].join("-");
    },
    SetCustomerId(id) {
      //TODO: Add check for valid guid
      this.customerId = id;
    },
    async GetData(level) {
      if (level == "Customer") {
        await Promise.all([
          //await this.GetActivitiesAsync(this.customerId),
          this.GetUpcomingMeetings(this.customerId),
          this.GetActionPlan(),
          this.GetAuditPoints(),
          this.GetMonthlyGraphData(),
          //this.GetAsessmentGraphData()
        ]);
        await this.GetPastMeetings(this.customerId);
        this.setCalendarMeetings();
      } else if (level == "EA") {
        await Promise.all([
          //await this.GetActivitiesAsync(this.customerId),
          this.GetUpcomingMeetings(this.customerId),
          this.GetPastMeetings(this.customerId),
          this.GetActionPlan(),
          this.GetAuditPoints(),
          this.GetRecommendedActionTemplates(),
          this.GetRelatedActivities(),
          this.GetMonthlyGraphData(),
          this.GetAsessmentGraphData(),
          this.GetAssessmentHistory(),
          this.GetLastLoggedInAbintegro(),
        ]);
      } else {
        this.fireErrorAlert("Please try again later", "GetData");
        return;
      }
      var date =
        this.upcomingMeetings.length > 0
          ? new Date(this.upcomingMeetings[0].scheduledStart)
          : new Date();
      this.nextMeetingDate =
        this.upcomingMeetings.length > 0
          ? [
              date.getDate() < 10
                ? "0" + date.getDate().toString()
                : date.getDate(),
              date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1).toString()
                : date.getMonth() + 1,
              date.getFullYear(),
            ].join("/") +
            " " +
            [
              date.getHours() + 1 < 10
                ? "0" + date.getHours().toString()
                : date.getHours(),
              date.getMinutes() + 1 < 10
                ? "0" + date.getMinutes().toString()
                : date.getMinutes(),
            ].join(":")
          : "N/A";
      this.SetMemos(level);
    },
    async GetCustomer() {
      this.fireLoadingAlert("GetContact");
      var resp = await ContactManager.GetContact(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert(
              "A customer with these details could not be found.",
              "GetRelatedActivities"
            );
          } else {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetContact"
            );
          }
          return false;
        }
      }
      var advisor = await ContactManager.GetContact(resp.data.owningUser);
      if (advisor.name != undefined) {
        if (advisor.name == "AxiosError") {
          this.fireErrorAlert(
            advisor.message + " - Please try again later.",
            "GetContact"
          );
          return false;
        }
      }
      var workCoach = await ContactManager.GetContact(resp.data.ownerId);
      if (workCoach.name != undefined) {
        if (workCoach.name == "AxiosError") {
          this.fireErrorAlert(
            workCoach.message + " - Please try again later.",
            "GetContact"
          );
          return false;
        }
      }
      var secondaryAdvisorcontact = null;
      if (resp.data.secondaryAdvisor != null) {
        var secondaryAdvisor = await ContactManager.GetContact(
          resp.data.secondaryAdvisor
        );
        if (secondaryAdvisor.name != undefined) {
          if (secondaryAdvisor.name == "AxiosError") {
            this.fireErrorAlert(
              secondaryAdvisor.message + " - Please try again later.",
              "GetContact"
            );
            return false;
          }
        }
        secondaryAdvisorcontact = {
          firstName: secondaryAdvisor.data.firstName,
          lastName: secondaryAdvisor.data.lastName,
          fullName:
            secondaryAdvisor.data.fullName != "" &&
            secondaryAdvisor.data.fullName != null
              ? secondaryAdvisor.data.fullName
              : secondaryAdvisor.data.firstName != null &&
                secondaryAdvisor.data.lastName != null
              ? `${secondaryAdvisor.data.firstName} ${secondaryAdvisor.data.lastName}`
              : this.GetNameFromEmailOrEmail(
                  secondaryAdvisor.data.eMailAddress1
                ),
          email: secondaryAdvisor.data.eMailAddress1,
          phone: secondaryAdvisor.data.mobilePhone,
        };
      }

      const customerTeamResp = await TeamManager.GetTeam(resp.data.owningTeam);
      if (customerTeamResp.name != undefined) {
        if (customerTeamResp.name == "AxiosError") {
          this.fireErrorAlert(
            customerTeamResp.message + " - Please try again later.",
            "GetContact"
          );
          return false;
        }
      }

      this.customer = {
        firstName: resp.data.firstName,
        lastName: resp.data.lastName,
        fullName:
          resp.data.fullName == "" || resp.data.fullName == null
            ? `${resp.data.firstName} ${resp.data.lastName}`
            : resp.data.fullName,
        email: resp.data.eMailAddress1,
        mobile: resp.data.mobilePhone,
        landline: resp.data.telephone1,
        DoB: resp.data.birthDate != null ? new Date(resp.data.birthDate) : null,
        memorableId: resp.data.memorableId,
        caseStatus: resp.data.caseStatus,
        sectorInterests: this.GetSectorInterestsFromCustomer(resp.data),
        salutation: resp.data.salutation,
        addressLine1: resp.data.addressLine1,
        addressLine2: resp.data.addressLine2,
        addressLine3: resp.data.addressLine3,
        postalCode: resp.data.postalCode,
        enrolledProgramme: resp.data.enrolledProgramme,
        exForces: resp.data.exForces,
        rightToWork: resp.data.rightToWork,
        pathway: resp.data.pathway,
        lifeCycle: resp.data.lifeCycle,
        strand: resp.data.strand,
        isAllowedExtendedEdits: resp.data.isAllowedExtendedEdits,
        owningTeam: customerTeamResp.data,
        advisor: {
          id: advisor.data.contactId,
          firstName: advisor.data.firstName,
          lastName: advisor.data.lastName,
          fullName:
            advisor.data.fullName != "" && advisor.data.fullName != null
              ? advisor.data.fullName
              : advisor.data.firstName != null && advisor.data.lastName != null
              ? `${advisor.data.firstName} ${advisor.data.lastName}`
              : this.GetNameFromEmailOrEmail(advisor.data.eMailAddress1),
          email: advisor.data.eMailAddress1,
          phone: advisor.data.mobilePhone,
        },
        workCoach: {
          firstName: workCoach.data.firstName,
          lastName: workCoach.data.lastName,
          fullName:
            workCoach.data.fullName != "" && workCoach.data.fullName != null
              ? workCoach.data.fullName
              : workCoach.data.firstName != null &&
                workCoach.data.lastName != null
              ? `${workCoach.data.firstName} ${workCoach.data.lastName}`
              : this.GetNameFromEmailOrEmail(workCoach.data.eMailAddress1),
          email: workCoach.data.eMailAddress1,
          phone: workCoach.data.mobilePhone,
        },
        secondaryAdvisor: secondaryAdvisorcontact,
        featureFlags: resp.data.featureFlags,
      };
      this.closeAlert("GetContact");
      return true;
    },

    GetCaseStatusDisplay(status) {
      const found = this.getCaseStatusOptions.find((c) => c.value == status);
      if (found == undefined) {
        return "N/A";
      }
      return found.display;
      // switch (status) {
      //   case "C":
      //     return "Completed";
      //   case "O":
      //     return "Pre Employment Support";
      //   case "255":
      //     return "Reffered";
      //   case "230":
      //     return "In Work Support";
      //   case "242":
      //     return "Reffered Did Not Start";
      //   case "243":
      //     return "Reffered Left Before Started";
      //   case "244":
      //     return "Reffered Did Not Attend";
      //   case "267":
      //     return "Suspended";
      //   default:
      //     return "N/A";
      // }
    },
    HandleUIError(err) {
      console.error("Error encountered: ", err);
      if (err instanceof AxiosError) {
        handleAxiosError(err);
      } else {
        showErrorAlert({
          title: "Error Encountered",
          text: "There was an unexpected issue on our side. Please try again later.",
        });
      }
    },

    GetNameFromEmailOrEmail(value) {
      if (value.includes("@")) {
        let clippedEmail = value.substring(0, value.indexOf("@"));
        let firstName = null;
        let lastName = null;
        if (clippedEmail.includes(".")) {
          firstName = clippedEmail.substring(0, clippedEmail.indexOf("."));
          lastName = clippedEmail.substring(clippedEmail.indexOf(".") + 1);
          if (firstName.length > 0) {
            firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
          }
          if (lastName.length > 0) {
            lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
          }
          value = `${firstName} ${lastName}`;
        }
      }
      return value;
    },
    async SearchForCustomer(searchType, searchValue, showLoad) {
      if (showLoad) {
        this.showContactLoad = true;
      }
      var resp = await ContactManager.SearchForContact(searchType, searchValue);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "SearchForCustomer"
          );
          return;
        }
      }
      this.searchResult = resp.data;
      this.filteredSearchResults = [];
      if (showLoad) {
        this.showContactLoad = false;
      }
    },
    async GetReportingCustomers() {
      this.fireLoadingAlert("GetReportingCustomers");
      var resp = await ContactManager.GetReportingCustomers(loggedIn.email);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetReportingCustomers"
          );
          return;
        }
      }
      this.reportingCustomers = resp.data;
      this.closeAlert("GetReportingCustomers");
    },
    async GetCoachingGuideRedirect(assessmentId) {
      this.fireLoadingAlert("GetCoachingGuideRedirect");
      var resp = await ContactManager.GetCoachingGuideRedirectUrl(assessmentId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetCoachingGuideRedirect"
          );
          return;
        }
      }
      this.closeAlert("GetCoachingGuideRedirect");
      return resp.data.path;
    },
    FilterContactSearchResult(searchType, searchValue, showLoad) {
      if (showLoad) {
        this.showContactLoad = true;
      }
      switch (searchType.toLowerCase()) {
        case "name":
          searchValue = searchValue.trim().toLowerCase();
          this.filteredSearchResults = this.searchResult.filter((r) => {
            let firstName = r.firstName != null ? r.firstName.trim() : "";
            let lastName = r.lastName != null ? r.lastName.trim() : "";

            let fullName = r.fullName != null ? r.fullName.trim() : "";
            if (fullName == null || fullName == "") {
              fullName = firstName + " " + lastName;
            }

            return fullName.toLowerCase().includes(searchValue);
          });
          break;
        case "phone":
          this.filteredSearchResults = this.searchResult.filter(
            (r) =>
              (r.mobilePhone != null && r.mobilePhone.includes(searchValue)) ||
              (r.telephone1 != null && r.telephone1.includes(searchValue))
          );
          break;
        case "email":
          this.filteredSearchResults = this.searchResult.filter(
            (r) =>
              r.eMailAddress1 != null &&
              r.eMailAddress1.toLowerCase().includes(searchValue.toLowerCase())
          );
          break;
        case "memorable id":
          this.filteredSearchResults = this.searchResult.filter(
            (r) =>
              r.memorableId != null &&
              r.memorableId.toLowerCase().includes(searchValue.toLowerCase())
          );
          break;
        case "contact id":
          this.filteredSearchResults = this.searchResult.filter(
            (r) =>
              r.contactId != null &&
              r.contactId.toLowerCase().includes(searchValue.toLowerCase())
          );
          break;
      }
      if (showLoad) {
        this.showContactLoad = false;
      }
    },
    ClearSearchValues() {
      this.searchResult = [];
      this.filteredSearchResults = [];
    },
    async GetCustomerGoToAdvisorView() {
      var found = await this.GetCustomer();
      if (found) {
        RouteToAdvisor(this.customerId);
      }
    },
    async GetPRaPOrder() {
      this.fireLoadingAlert("GetPRaPOrder");
      var resp = await ActionManager.GetPRaPOrder(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status != 404) {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetPRaPOrder"
            );
          }
          this.closeAlert("GetPRaPOrder");
          return;
        }
      }
      this.PRaPOrder = resp.data;
      this.closeAlert("GetPRaPOrder");
    },

    async StoreOneTimeUseAuthToken() {
      this.fireLoadingAlert("StoreOneTimeUseAuthToken");
      var resp = await ActionManager.StoreOneTimeUseAuthToken(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "StoreOneTimeUseAuthToken"
          );
          return;
        }
      }
      this.closeAlert("StoreOneTimeUseAuthToken");
      return resp.data;
    },

    async GetCustomerNotifications() {
      this.fireLoadingAlert("GetCustomerNotifications");
      var resp = await ActionManager.GetCustomerNotification(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetCustomerNotifications"
          );
          return;
        }
      }
      this.customerNotifications = resp.data;
      this.closeAlert("GetCustomerNotifications");
    },
    async GetThemedAssessmentResults() {
      this.fireLoadingAlert("GetThemedAssessmentResults");
      var resp = await catalogManager.GetThemedAssessmentResultsForCustomer(
        this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetThemedAssessmentResults"
          );
          return;
        }
      }

      var results = {
        questionSets: [],
        answerSets: [],
      };

      var questionSets = [];
      for (var i = 0; i < resp.data.length; i++) {
        resp.data[i].assessmentSubject;
        for (var j = 0; j < resp.data[i].rows.length; j++) {
          var cat = resp.data[i].rows[j].id.substring(
            0,
            resp.data[i].rows[j].id.indexOf(".")
          );
          var includes = false;
          for (var r = 0; r < questionSets.length; r++) {
            if (questionSets[r].category == cat) {
              includes = true;
              questionSets[r].questions.push({
                id: resp.data[i].rows[j].id,
                title: resp.data[i].rows[j].title,
              });
            }
          }
          if (!includes) {
            questionSets.push({
              category: cat,
              questions: [
                {
                  id: resp.data[i].rows[j].id,
                  title: resp.data[i].rows[j].title,
                },
              ],
            });
          }
        }
      }
      results.questionSets = questionSets;
      for (var h = 0; h < resp.data.length; h++) {
        var answerSet = {
          createdOn: resp.data[h].createdOn,
          assessmentSubject: resp.data[h].assessmentSubject || "",
          assessmentName: resp.data[h].assessmentName || "",
          assessmentVersion: resp.data[h].assessmentVersion || 0,
          answers: [],
        };
        for (var l = 0; l < resp.data[h].rows.length; l++) {
          answerSet.answers.push({
            questionId: resp.data[h].rows[l].id,
            answer: resp.data[h].rows[l].response,
          });
        }
        results.answerSets.push(answerSet);
      }
      this.themedAssessmentResults = results;
      this.closeAlert("GetThemedAssessmentResults");
    },
    async GetUnfilteredAssessmentResults() {
      try {
        this.fireLoadingAlert("GetUnfilteredAssessmentResults");

        const resp =
          await catalogManager.GetUnfilteredAssessmentResultsForCustomer(
            this.customerId
          );

        if (resp.name === "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetUnfilteredAssessmentResults"
          );
          return;
        }

        const results = {
          assessments: [],
        };

        resp.data.forEach((assessment) => {
          const questionSetsMap = {};

          assessment.rows.forEach((row) => {
            const category = row.id.split(".")[0];

            if (!questionSetsMap[category]) {
              questionSetsMap[category] = {
                category,
                questions: [],
              };
            }

            questionSetsMap[category].questions.push({
              id: row.id,
              title: row.title,
              answer: row.response,
            });
          });

          const assessmentObj = {
            createdOn: assessment.createdOn,
            assessmentSubject: assessment.assessmentSubject || "",
            assessmentName: assessment.assessmentName || "",
            assessmentVersion: assessment.assessmentVersion || 0,
            questionSets: Object.values(questionSetsMap),
          };

          results.assessments.push(assessmentObj);
        });

        this.unfilteredAssessmentResults = results;

        this.closeAlert("GetUnfilteredAssessmentResults");
      } catch (error) {
        this.fireErrorAlert(
          error.message + " - Please try again later.",
          "GetUnfilteredAssessmentResults"
        );
      }
    },

    async GetAllAssessmentResults() {
      this.fireLoadingAlert("GetAllAssessmentResults");
      var resp = await catalogManager.GetAllAssessmentResultsForCustomer(
        this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAllAssessmentResults"
          );
          return;
        }
      }
      var results = resp.data;
      this.allAssessmentResults = results;
      this.closeAlert("GetAllAssessmentResults");
    },

    async GetActionAssessmentResults(relatedActionId) {
      this.fireLoadingAlert("GetActionAssessmentResults");
      var resp = await catalogManager.GetActionAssessmentResultsFromId(
        this.customerId,
        relatedActionId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetActionAssessmentResults"
          );
          return;
        }
      }
      var results = { questionSets: [], answerSets: [] };
      var questionSets = [];
      for (var i = 0; i < resp.data.length; i++) {
        for (var j = 0; j < resp.data[i].rows.length; j++) {
          var cat = resp.data[i].rows[j].id.substring(
            0,
            resp.data[i].rows[j].id.indexOf(".")
          );
          var includes = false;
          for (var r = 0; r < questionSets.length; r++) {
            if (questionSets[r].category == cat) {
              includes = true;
              questionSets[r].questions.push({
                id: resp.data[i].rows[j].id,
                title: resp.data[i].rows[j].title,
              });
            }
          }
          if (!includes) {
            questionSets.push({
              category: cat,
              questions: [
                {
                  id: resp.data[i].rows[j].id,
                  title: resp.data[i].rows[j].title,
                },
              ],
            });
          }
        }
      }
      results.questionSets = questionSets;
      for (var h = 0; h < resp.data.length; h++) {
        var answerSet = { createdOn: resp.data[h].createdOn, answers: [] };
        for (var l = 0; l < resp.data[h].rows.length; l++) {
          answerSet.answers.push({
            questionId: resp.data[h].rows[l].id,
            answer: resp.data[h].rows[l].response,
          });
        }
        results.answerSets.push(answerSet);
      }
      this.actionAssessmentResults = results;
      this.closeAlert("GetActionAssessmentResults");
    },
    async GetCustomerSpendConfig() {
      this.fireLoadingAlert("GetCustomerSpendConfig");
      var resp = await customerSpendManager.GetConfigValues();
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetCustomerSpendConfig"
          );
          return false;
        }
      }
      this.customerSpendConfig = resp.data;
      this.closeAlert("GetCustomerSpendConfig");

      return true;
    },
    async CreateCustomerSpend(record, reload) {
      this.fireLoadingAlert("CreateCustomerSpend");
      var resp = await customerSpendManager.CreateCustomerSpendRecord(
        record,
        this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "CreateCustomerSpend"
          );
          return false;
        }
      }
      if (reload) {
        this.GetCustomerSpend();
      }
      this.closeAlert("CreateCustomerSpend");

      return true;
    },
    async GetCustomerSpend() {
      this.fireLoadingAlert("GetCustomerSpend");
      var resp = await customerSpendManager.GetCustomerSpend(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetCustomerSpend"
          );
          return false;
        }
      }
      this.customerSpendHistory = resp.data;
      this.closeAlert("GetCustomerSpend");
      return true;
    },
    async UpdateCustomerAsync(customerUpdateDto, reload) {
      this.fireLoadingAlert("UpdateCustomerAsync");
      var resp = await contactManager.UpdateCustomerAsync(
        this.customerId,
        customerUpdateDto,
        loggedIn.email
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 400) {
            var lines = resp.response.data.detail.split("\n");
            for (var i = 0; i < lines.length; i++) {
              lines[i] = lines[i].replace("\n", "");
            }
            var message =
              '<ul style="width:fit-content;margin: 0 auto;"><li>' +
              lines.join("</li><li>") +
              "</li></ul>";
            this.fireErrorAlert(message, "UpdateCustomerAsync");
            return false;
          }
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateCustomerAsync"
          );
          return false;
        }
      }
      if (reload) {
        await this.GetCustomer();
      }
      this.closeAlert("UpdateCustomerAsync");
      return true;
    },
    async UpdateCustomerSpend(record, spendId, reload) {
      this.fireLoadingAlert("UpdateCustomerSpend");
      var resp = await customerSpendManager.UpdateCustomerSpendRecord(
        record,
        spendId,
        this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          console.log(resp);
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateCustomerSpend"
          );
          return false;
        }
      }
      if (reload) {
        this.GetCustomerSpend();
      }
      this.closeAlert("UpdateCustomerSpend");
      return true;
    },
    async DeleteCustomerSpendRecord(spendId, reload) {
      this.fireLoadingAlert("DeleteCustomerSpendRecord");
      var resp = await customerSpendManager.DeleteCustomerSpendRecord(
        spendId,
        this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          console.log(resp);
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "DeleteCustomerSpendRecord"
          );
          return;
        }
      }
      if (reload) {
        this.GetCustomerSpend();
      }
      this.closeAlert("DeleteCustomerSpendRecord");
    },
    fireConfirmDeleteCustomerSpendAlert(spendId, reload) {
      var deleteRecord = this.DeleteCustomerSpendRecord;
      var func = function (deleteRecord, spendId, reload) {
        deleteRecord(spendId, reload);
      };
      if (this.loadingAlertActive.length == 0 && !this.errorAlertActive) {
        Swal.fire({
          icon: "info",
          title: "<strong>Complete Action</strong>",
          html: "Are you sure you want to delete this record?",
          //type: "custom-html",
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Yes",
          confirmButtonAriaLabel: "Yes",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          closeOnClickOutside: false,
        }).then((result) => {
          if (result.isConfirmed) {
            func(deleteRecord, spendId, reload);
          }
        });
      }
    },
    async GetAssessmentResults() {
      this.fireLoadingAlert("GetAssessmentResults");
      var resp = await ContactManager.GetAssessmentResults(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          console.log(resp);
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAssessmentResults"
          );
          return;
        }
      }
      this.assessmentResults = resp.data;
      this.closeAlert("GetAssessmentResults");
    },
    async GetAssessmentHistory() {
      this.fireLoadingAlert("GetAssessmentHistory");
      var resp = await ContactManager.GetAssessmentHistoryData(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAssessmentHistory"
          );
          return;
        }
      }

      var nextAssessmentResp = await ContactManager.GetNextAssessmentDue(
        this.customerId
      );
      var noDueDate = false;
      if (nextAssessmentResp.name != undefined) {
        if (nextAssessmentResp.name == "AxiosError") {
          if (nextAssessmentResp.response.status != 404) {
            this.fireErrorAlert(
              nextAssessmentResp.message + " - Please try again later.",
              "GetAssessmentHistory"
            );
            return;
          } else {
            noDueDate = true;
          }
        }
      }

      this.assessmentHistory = resp.data;
      this.nextAssessmentDate = noDueDate ? "N/A" : nextAssessmentResp.data;

      this.closeAlert("GetAssessmentHistory");
    },
    async GetLastLoggedInAbintegro() {
      this.fireLoadingAlert("GetLastLoggedInAbintegro");
      var resp = await ActionManager.GetLastLoggedInAbintegro(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          console.log(resp);
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetLastLoggedInAbintegro"
          );
          return;
        }
      }
      this.lastLoggedInAbintegro = resp.data;
      this.closeAlert("GetLastLoggedInAbintegro");
    },
    async GetRelatedActivities() {
      this.fireLoadingAlert("GetRelatedActivities");
      var resp = await ContactManager.GetRelatedActivities(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetRelatedActivities"
          );
          return;
        }
      }
      this.externalActivites = resp.data;
      this.externalActivites = this.externalActivites
        .sort(
          (a, b) =>
            new Date(a.timeline.scheduledStart) -
            new Date(b.timeline.scheduledStart)
        )
        .reverse();
      this.closeAlert("GetRelatedActivities");
    },
    async GetMonthlyGraphData() {
      this.fireLoadingAlert("GetMonthlyGraphData");
      var resp = await ActionManager.GetMonthlyActionPlanData(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetMonthlyGraphData"
          );
          return;
        }
      }
      var data = { months: [], values: [] };
      for (var i = 0; i < resp.data.monthyResultsList.length; i++) {
        data.months.push(resp.data.monthyResultsList[i].date.month);
        data.values.push(resp.data.monthyResultsList[i].completedActions);
      }
      data.months = data.months.reverse();
      data.values = data.values.reverse();
      this.monthlyCompletedData = data;
      this.monthlyCompletedDataDateRange = `${
        resp.data.monthyResultsList[resp.data.monthyResultsList.length - 1].date
          .month
      } ${
        resp.data.monthyResultsList[resp.data.monthyResultsList.length - 1].date
          .year
      } - ${resp.data.monthyResultsList[0].date.month} ${
        resp.data.monthyResultsList[0].date.year
      }`;
      this.CreateNewChart();
      this.closeAlert("GetMonthlyGraphData");
    },
    async GetAsessmentGraphData() {
      this.fireLoadingAlert("GetAsessmentGraphData");
      var resp = await ContactManager.GetAssessmentGraphData(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAsessmentGraphData"
          );
        }
      }
      this.assessmentGraphData = resp.data;
      //this.CreateNewSpiderGraph();
      this.closeAlert("GetAsessmentGraphData");
    },
    GetPathway(customer) {
      var assessments = customer.unfilteredAssessmentResults;
      return assessments;
    },
    GetSectorInterestsFromCustomer(customer) {
      var interests = [];
      var interestsString = customer.sectorInterests;
      var otherInterestsString = customer.otherSectorInterests;

      if (otherInterestsString == null || otherInterestsString == undefined) {
        otherInterestsString = "";
      }

      if (interestsString != null) {
        while (interestsString.includes(",")) {
          interests.push(
            interestsString.substring(0, interestsString.indexOf(","))
          );
          interestsString = interestsString.substring(
            interestsString.indexOf(",") + 1
          );
        }
        if (interestsString.length > 0) {
          interests.push(interestsString);
          interestsString = "";
        }
        if (interests.includes("Other")) {
          while (otherInterestsString.includes(",")) {
            interests.push(
              otherInterestsString.substring(
                0,
                otherInterestsString.indexOf(",")
              )
            );
            otherInterestsString = otherInterestsString.substring(
              otherInterestsString.indexOf(",") + 1
            );
          }
          if (otherInterestsString.length > 0) {
            interests.push(otherInterestsString);
            otherInterestsString = "";
          }
        }
      }
      return interests;
    },
    async GetUpcomingMeetings(id) {
      this.fireLoadingAlert("GetUpcomingMeetings");
      try {
        var resp = await CalendarManager.GetUpcomingMeetings(id);
        if (resp.name != undefined) {
          if (resp.name == "AxiosError") {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetUpcomingMeetings"
            );
            return;
          }
        }
        this.upcomingMeetings =
          resp.data.length > 0
            ? resp.data.sort(
                (a, b) =>
                  new Date(b.scheduledStart) - new Date(a.scheduledStart)
              )
            : this.upcomingMeetings;
        this.upcomingMeetings.reverse();
      } catch (e) {
        console.log(e);
        this.fireErrorAlert(
          "We ran into an error, please try again later.",
          "GetUpcomingMeetings"
        );
        return;
      }
      this.closeAlert("GetUpcomingMeetings");
    },
    async GetPastMeetings(id) {
      this.fireLoadingAlert("GetPastMeetings");
      try {
        var resp = await CalendarManager.GetPastMeetings(id);
        if (resp.name != undefined) {
          if (resp.name == "AxiosError") {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetPastMeetings"
            );
            return;
          }
        }
        this.pastMeetings = resp.data;
        // this.getAllMeetings.past = resp.data;
      } catch (e) {
        console.log(e);
        this.fireErrorAlert(
          "We ran into an error, please try again later.",
          "GetPastMeetings"
        );
        return;
      }
      this.closeAlert("GetPastMeetings");
    },
    async GetActionPlan(showAlert) {
      if (showAlert) {
        this.fireLoadingAlert("GetActionPlan");
      }

      var resp = await ActionManager.GetActionPlan(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          console.log(resp);
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetActionPlan"
          );
          return;
        }
      }
      this.actionPlan = resp.data;
      this.SetActionValues();
      this.SortActions();

      if (showAlert) {
        this.closeAlert("GetActionPlan");
      }
    },

    async CreateNewAction(action, reload) {
      this.fireLoadingAlert("CreateNewAction");
      var resp = await ActionManager.CreateActionPlanItem(
        action,
        this.customerId,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "CreateNewAction"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("CreateNewAction");
      return resp.data;
    },
    async CreateNewAppointment(action, reload, payload) {
      this.fireLoadingAlert("CreateNewAppointment");
      var resp = await ActionManager.CreateAppointmentPlanItem(
        action,
        this.customerId,
        loggedIn.ea ? loggedIn.email : this.customerId,
        payload
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          console.log(resp);
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "CreateNewAppointment"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
        this.GetUpcomingMeetings(this.customerId);
      }
      this.closeAlert("CreateNewAppointment");
      return resp.data;
    },
    async CreateNewTask(actionPlanItemId, task, reload) {
      this.fireLoadingAlert("CreateNewTask");
      var resp = await ActionManager.CreateActionPlanTask(
        task,
        actionPlanItemId,
        this.customerId,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "CreateNewTask"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("CreateNewTask");
    },
    async UpdateAction(actionPlanItemId, action, reload) {
      this.fireLoadingAlert("UpdateAction");
      var resp = await ActionManager.UpdateActionPlanItem(
        actionPlanItemId,
        this.customerId,
        action,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateAction"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
        this.GetUpcomingMeetings(this.customerId);
      }
      this.closeAlert("UpdateAction");
    },
    async UpdateTask(actionPlanItemId, taskId, task, reload) {
      this.fireLoadingAlert("UpdateTask");
      var resp = await ActionManager.UpdateActionPlanTask(
        actionPlanItemId,
        taskId,
        this.customerId,
        task,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateTask"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("UpdateTask");
    },
    async UpdateActionMandated(actionPlanItemId, isMandated, reload) {
      this.fireLoadingAlert("UpdateActionMandated");
      var resp = await ActionManager.UpdateActionProperty(
        actionPlanItemId,
        this.customerId,
        "IsMandated",
        isMandated,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateActionMandated"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("UpdateActionMandated");
    },
    async UpdateActionCompletedByCustomer(actionPlanItemId, completed, reload) {
      this.fireLoadingAlert("UpdateActionCompletedByCustomer");
      let value = completed ? "PendingComplete" : "Open";
      var resp = await ActionManager.UpdateActionProperty(
        actionPlanItemId,
        this.customerId,
        "StateCode",
        value,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateActionCompletedByCustomer"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("UpdateActionCompletedByCustomer");
    },
    async UpdateActionStateCode(actionPlanItemId, stateCode, reload) {
      this.fireLoadingAlert("UpdateActionStateCode");
      var resp = await ActionManager.UpdateActionProperty(
        actionPlanItemId,
        this.customerId,
        "StateCode",
        stateCode,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateActionStateCode"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("UpdateActionStateCode");
    },
    async UpdateActionStartDate(startDate, actionPlanItemId, reload) {
      this.fireLoadingAlert("UpdateActionStartDate");
      var resp = await ActionManager.UpdateActionProperty(
        actionPlanItemId,
        this.customerId,
        "ScheduledStart",
        startDate,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateActionStartDate"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("UpdateActionStartDate");
    },
    async UpdateActionCompletedDate(completedDate, actionPlanItemId, reload) {
      this.fireLoadingAlert("UpdateActionCompletedDate");
      var resp = await ActionManager.UpdateActionProperty(
        actionPlanItemId,
        this.customerId,
        "ActualEnd",
        completedDate,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateActionCompletedDate"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("UpdateActionCompletedDate");
    },

    UpdateEndThenMoveToComplete(completedDate, actionPlanItemId, reload) {
      this.UpdateActionCompletedDate(
        completedDate,
        actionPlanItemId,
        false
      ).then(() => {
        this.UpdateActionStateCode(actionPlanItemId, "Complete", reload);
      });
    },

    async UpdateStartThenMoveToCurrent(startDate, actionPlanItemId, reload) {
      this.UpdateActionStartDate(startDate, actionPlanItemId, false).then(
        () => {
          this.UpdateActionStateCode(actionPlanItemId, "Open", reload);
        }
      );
    },
    async UpdateTaskMandated(actionPlanItemId, taskId, isMandated, reload) {
      this.fireLoadingAlert("UpdateTaskMandated");
      var resp = await ActionManager.UpdateTaskProperty(
        actionPlanItemId,
        taskId,
        this.customerId,
        "IsMandated",
        isMandated,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateTaskMandated"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("UpdateTaskMandated");
    },
    async UpdateTaskCompletedByCustomer(
      actionPlanItemId,
      taskId,
      completed,
      reload
    ) {
      this.fireLoadingAlert("UpdateTaskCompletedByCustomer");
      let value = completed ? "PendingComplete" : "Open";
      var resp = await ActionManager.UpdateTaskProperty(
        actionPlanItemId,
        taskId,
        this.customerId,
        "StateCode",
        value,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateTaskCompletedByCustomer"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("UpdateTaskCompletedByCustomer");
    },
    async UpdateTaskStateCode(actionPlanItemId, taskId, stateCode, reload) {
      this.fireLoadingAlert("UpdateTaskStateCode");
      var resp = await ActionManager.UpdateTaskProperty(
        actionPlanItemId,
        taskId,
        this.customerId,
        "StateCode",
        stateCode,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateTaskStateCode"
          );
          return;
        }
      }
      if (reload) {
        this.GetActionPlan();
      }
      this.closeAlert("UpdateTaskStateCode");
    },
    async CreateActionPlanItemMemo(
      actionPlanItemId,
      memoContent,
      visibility,
      type
    ) {
      this.fireLoadingAlert("CreateActionPlanItemMemo");
      var resp = await ActionManager.CreateActionPlanItemMemo(
        this.customerId,
        actionPlanItemId,
        memoContent,
        visibility,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "CreateActionPlanItemMemo"
          );
          return;
        }
      }
      //this.SortNewMemo(resp.data, actionPlanItemId, type);
      console.log(type);
      this.RefreshDataAfterMemoChanged(visibility, actionPlanItemId);
      this.closeAlert("CreateActionPlanItemMemo");
    },
    async DeleteActionPlanItemMemo(memoContent, visibility, memoId) {
      this.fireLoadingAlert("DeleteActionPlanItemMemo");
      var resp = await ActionManager.EditActionPlanItemMemo(
        memoId,
        this.customerId,
        this.creatingMemo.actionId,
        memoContent,
        visibility,
        "Deferred",
        "Deleted",
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "DeleteActionPlanItemMemo"
          );
          return;
        }
      }
      this.RefreshDataAfterMemoChanged(visibility, this.creatingMemo.actionId);
      this.closeAlert("DeleteActionPlanItemMemo");
    },
    async GetActionSubjectById(actionId) {
      this.fireLoadingAlert("GetAssessmentSubjectById");
      var resp = await catalogManager.GetAssessmentSubjectById(
        actionId,
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAssessmentSubjectById"
          );
          return;
        }
      }
      this.actionSubjectById = resp.data
      this.closeAlert("GetAssessmentSubjectById");
    },
    async GetConfigInformation() {
      this.fireLoadingAlert("GetConfigInformation");
      var resp = await contactManager.GetConfigInformation();
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetConfigInformation"
          );
          return;
        }
      }
      this.configInformation = resp.data;
      this.closeAlert("GetConfigInformation");
    },

    async PutConfigInformation(payload) {
      this.fireLoadingAlert("PutConfigInformation");
      var resp = await contactManager.PutConfigInformation(payload.data);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "PutConfigInformation"
          );
          return;
        }
      }
      this.postConfiguration = resp.data;
      this.closeAlert("PutConfigInformation");
      return resp;
    },
    async RefreshDataAfterMemoChanged(visibilty, id) {
      await this.GetActionPlan();
      this.SetMemos(visibilty);
      this.SetMemosForUI(id, this.creatingMemo.type);
    },
    async CreateTaskItemMemo(
      actionPlanItemId,
      taskId,
      memoContent,
      visibility,
      type
    ) {
      this.fireLoadingAlert("CreateTaskItemMemo");
      var resp = await ActionManager.CreateTaskMemo(
        this.customerId,
        actionPlanItemId,
        taskId,
        memoContent,
        visibility,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "CreateTaskItemMemo"
          );
          return;
        }
      }
      //this.SortNewMemo(resp.data, taskId, type);
      console.log(type);
      this.RefreshDataAfterMemoChanged(visibility, taskId);
      this.closeAlert("CreateTaskItemMemo");
    },
    async DeleteTaskItemMemo(memoContent, visibility, memoId) {
      this.fireLoadingAlert("DeleteTaskItemMemo");
      var resp = await ActionManager.EditTaskMemo(
        memoId,
        this.customerId,
        this.creatingMemo.actionId,
        this.creatingMemo.taskId,
        memoContent,
        visibility,
        "Deferred",
        "Deleted",
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "DeleteTaskItemMemo"
          );
          return;
        }
      }
      this.RefreshDataAfterMemoChanged(visibility, this.creatingMemo.taskId);
      this.closeAlert("DeleteTaskItemMemo");
    },
    async CreateAuditPoint(
      reason,
      desc,
      modeOfIntervention,
      reload,
      ignoreOpenAssessmentWarning,
      completerData
    ) {
      this.fireLoadingAlert("CreateAuditPoint");
      var resp = await ActionManager.CreateNewAuditPoint(
        this.customerId,
        reason,
        desc,
        "text/markdown",
        modeOfIntervention,
        ignoreOpenAssessmentWarning,
        completerData
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          // This happens when Audit Point validation fails
          if (resp.response.status == 422 || resp.response.status == 409) {
            const respJson = JSON.parse(resp.request.response);

            console.log(resp.request.response);
            console.log(respJson);

            const validationResult = JSON.parse(respJson.detail);

            console.log(validationResult);

            const messages = validationResult.Errors;

            //const messages = respJson.detail.split("\r")

            if (messages.length > 0) {
              let errorMessages = "";
              for (let i = 0; i < messages.length; i++) {
                errorMessages += "● " + messages[i] + "<br><br>";
              }
              this.fireCustomErrorAlert(
                "Error creating Action plan",
                "<strong><u>Action Plan Creation Failed</u></strong><br><br>" +
                  errorMessages,
                "CreateAuditPoint"
              );
            } else {
              this.ShowAuditForceCreatePopup(
                validationResult.OpenAssessmentWarningMessage
              );
              this.closeAlert("CreateAuditPoint");
            }
          } else {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "CreateAuditPoint"
            );
          }
          // This makes sure that the 'catch' on the promise is called
          throw resp;
        }
      }
      if (reload) {
        await this.GetAuditPoints();
      }

      this.closeAlert("CreateAuditPoint");
      return resp;
    },

    ShowAuditForceCreatePopup(warningMessage) {
      const advisorDashboardStore = useAdvisorDashboardStore();
      if (advisorDashboardStore.getForceCreateAuditView == null) {
        advisorDashboardStore.setForceCreateAuditView(
          document.getElementById("force-create-audit")
        );
      }
      advisorDashboardStore.setModalContent(
        advisorDashboardStore.getForceCreateAuditView
      );
      advisorDashboardStore.setModalTitle("");
      advisorDashboardStore.toggleModal();
      this.auditForceCreateWarning = warningMessage;
    },
    HideAuditForceCreatePopup() {
      this.auditForceCreateWarning = null;
      const advisorDashboardStore = useAdvisorDashboardStore();
      advisorDashboardStore.toggleModal();
    },
    async GetAuditPoints() {
      this.fireLoadingAlert("GetAuditPoint");
      var resp = await ActionManager.GetAuditPoints(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAuditPoints"
          );
          return;
        }
      }
      for (var i = 0; i < resp.data.length; i++) {
        if (resp.data[i].contentType == "text/rtf") {
          const stringToArrayBuffer = (string) => {
            const buffer = new ArrayBuffer(string.length);
            const bufferView = new Uint8Array(buffer);
            for (let i = 0; i < string.length; i++) {
              bufferView[i] = string.charCodeAt(i);
            }
            return buffer;
          };
          const doc = new RTFJS.Document(
            stringToArrayBuffer(resp.data[i].description)
          );
          let htmlElements = await doc.render();
          var parent = htmlElements[0];
          htmlElements = htmlElements.slice(1);
          htmlElements.map((elem) => parent.appendChild(elem));
          resp.data[i].description = parent.outerHTML;
        }
        if (resp.data[i].contentType == "text/markdown") {
          resp.data[i].description = marked(resp.data[i].description);
        }
      }
      this.auditPoints = resp.data
        .sort(function (a, b) {
          return new Date(a.created) - new Date(b.created);
        })
        .reverse();
      this.closeAlert("GetAuditPoint");
    },
    async GetAuditByVersion(version) {
      this.fireLoadingAlert("GetAuditByVersion");
      var resp = await ActionManager.GetAuditPointByVersion(
        this.customerId,
        version
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAuditByVersion"
          );
          return;
        }
      }
      if (resp.data.contentType == "text/rtf") {
        const stringToArrayBuffer = (string) => {
          const buffer = new ArrayBuffer(string.length);
          const bufferView = new Uint8Array(buffer);
          for (let i = 0; i < string.length; i++) {
            bufferView[i] = string.charCodeAt(i);
          }
          return buffer;
        };
        const doc = new RTFJS.Document(
          stringToArrayBuffer(resp.data.description)
        );
        let htmlElements = await doc.render();
        var parent = htmlElements[0];
        htmlElements = htmlElements.slice(1);
        htmlElements.map((elem) => parent.appendChild(elem));
        resp.data.description = parent.outerHTML;
      }
      if (resp.data.contentType == "text/markdown") {
        resp.data.description = marked(resp.data.description);
      }
      this.currentAuditToSign = resp.data;
      this.closeAlert("GetAuditByVersion");
    },
    async UpdateAuditAcceptedOn(date, version, reload) {
      this.fireLoadingAlert("UpdateAuditAcceptedOn");
      var resp = await ActionManager.UpdateAuditAcceptedDate(
        date,
        this.customerId,
        version
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateAuditAcceptedOn"
          );
          return;
        }
      }
      if (reload) {
        this.GetAuditPoints();
      }
      this.closeAlert("UpdateAuditAcceptedOn");
    },
    async UpdateAuditDescription(version, desc, reload) {
      this.fireLoadingAlert("UpdateAuditDescription");
      var resp = await ActionManager.UpdateAuditDescription(
        desc,
        "text/markdown",
        this.customerId,
        version,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "UpdateAuditDescription"
          );
          return;
        }
      }
      if (reload) {
        this.GetAuditPoints();
      }
      this.closeAlert("UpdateAuditDescription");
    },
    async GetAuditActions(version) {
      this.fireLoadingAlert("GetAuditActions");
      var resp = await ActionManager.GetAuditPointActions(
        this.customerId,
        version
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAuditActions"
          );
          return;
        }
      }
      this.SetAuditActionValues(resp.data);
      this.closeAlert("GetAuditActions");
    },
    async GetContract(version) {
      this.fireLoadingAlert("getContract");
      var resp = await ContractManager.GetContract(this.customerId, version);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "getContract"
          );
          return;
        }
      }
      this.currentContract = resp.data.items;
      this.closeAlert("getContract");
    },
    async UploadContract(version, contract) {
      this.fireLoadingAlert("Uploadcontract");
      var resp = await ContractManager.UploadContract(
        this.customerId,
        version,
        contract
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "Uploadcontract"
          );
          return;
        }
      }
      this.UpdateAuditAcceptedOn(
        this.getUTCDateString(new Date()),
        version,
        true
      );
      this.closeAlert("Uploadcontract");
    },
    async GetRecommendedActionTemplates() {
      this.fireLoadingAlert("GetRecommendedActionTemplates");
      var resp = await CatalogManager.GetRecommendedActions(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetRecommendedActionTemplates"
          );
          return;
        }
      }
      this.recommendedActionTemplates = resp.data;
      this.recommendedActionTemplates.unshift({
        actionUrl: "",
        catalogName: "",
        description: "",
        estimatedEffort: 0,
        estimatedEffortUnit: "Minute",
        id: "CustomItem",
        isMandated: false,
        isRequired: false,
        motivation: "",
        name: "Custom",
      });
      for (var i = 0; i < this.recommendedActionTemplates.length; i++) {
        if (
          this.recommendedActionTemplates[i].actionUrl.includes("dynamic://")
        ) {
          var urlResp = await catalogManager.ResolveURL(
            this.recommendedActionTemplates[i].actionUrl,
            this.customerId
          );
          if (urlResp.name != undefined) {
            if (urlResp.name == "AxiosError") {
              this.fireErrorAlert(
                urlResp.message + " - Please try again later.",
                "GetRecommendedActionTemplates"
              );
              return;
            }
          }
          this.recommendedActionTemplates[i].actionUrl = urlResp.data;
        }
      }
      this.recommendedActionTemplates = this.recommendedActionTemplates.filter(
        (t) => t.id != "initialAssessment"
      );
      this.closeAlert("GetRecommendedActionTemplates");
    },
    async GetThemedAssessmentJson(version, relatedActionId) {
      this.fireLoadingAlert("GetThemedAssessmentJson");
      let resp;
      if (version.toLowerCase() == "current") {
        resp = await CatalogManager.GetCurrentThemedAssessment(
          this.customerId,
          relatedActionId
        );
      } else {
        resp = await CatalogManager.GetThemedAssessmentByVersion(
          this.customerId,
          version
        );
      }
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetThemedAssessmentJson"
          );
          return;
        }
      }
      this.themedAssessmentJson = resp.data;
      //this.recommendedActionTemplates = developmentOptions.TargetedActivities;
      this.closeAlert("GetThemedAssessmentJson");
    },
    async SendThemedAssessmentAnswers(results, relatedItemId) {
      this.fireLoadingAlert("SendThemedAssessmentAnswers");
      var resp = await catalogManager.PostThemedAssessment(
        this.getCustomerId,
        this.themedAssessmentJson.themeId,
        this.themedAssessmentJson.version,
        results,
        relatedItemId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "SendThemedAssessmentAnswers"
          );
          return;
        }
      }
      this.closeAlert("SendThemedAssessmentAnswers");
    },
    setCalendarMeetings() {
      try {
        const calendarMeeting = [];
        for (let i = 0; i < this.activities.length; i++) {
          calendarMeeting.push({
            title: this.activities[i].activityTypeCode,
            start: new Date(this.activities[i].scheduledStart)
              .toISOString()
              .split("T")[0],
            end: new Date(this.activities[i].scheduledEnd)
              .toISOString()
              .split("T")[0],
            className:
              "bg-gradient-danger calendar-event " +
              this.activities[i].activityId,
          });
        }
        this.calendarEvents = calendarMeeting;
        this.DestroyCalendar();
        this.RenderCalendar();
      } catch (e) {
        console.log(e);
        this.fireErrorAlert(
          "We ran into an error, please try again later.",
          "setCalendarMeetings"
        );
      }
    },
    SetActionValues() {
      try {
        //this.actions.current = this.actionPlan.itemCollection;
        this.actions.recommended = this.actionPlan.itemCollection.filter(
          (i) => i.state.stateCode == "Available"
        );
        this.actions.current = this.actionPlan.itemCollection.filter(
          (i) => i.state.stateCode == "Open"
        );
        this.actions.current = this.actions.current.concat(
          this.actionPlan.itemCollection.filter(
            (i) => i.state.stateCode == "PendingComplete"
          )
        );
        // this.actions.current = this.actions.current.sort(
        //   (a, b) =>
        //     new Date(a.timeline.scheduledEnd) -
        //     new Date(b.timeline.scheduledEnd)
        // );
        this.actions.completed = this.actionPlan.itemCollection.filter(
          (i) => i.state.stateCode == "Complete"
        );
        // this.actions.completed = this.actions.completed.concat(this.actionPlan.itemCollection
        // .filter(i => i.state.stateCode == "Completed"));
        this.actions.archived = this.actionPlan.itemCollection.filter((i) => {
          if (useMainStore().getIsFailedToCompleteEnabled) {
            return i.state.stateCode === "Deferred"; //|| i.state.stateCode == "Canceled"
          }
          return (
            i.state.stateCode === "Deferred" || i.state.stateCode === "Canceled"
          );
        });

        this.actions.failed = this.actionPlan.itemCollection.filter(
          (i) => i.state.stateCode == "Canceled"
        );
      } catch (e) {
        console.log(e);
        this.fireErrorAlert(
          "We ran into an error, please try again later.",
          "SetActionValues"
        );
      }
    },
    SortActions() {
      this.actions.recommended = this.actions.recommended.sort(
        (a, b) =>
          new Date(a.timeline.scheduledEnd) - new Date(b.timeline.scheduledEnd)
      );
      this.actions.current = this.actions.current.sort(
        (a, b) =>
          new Date(a.timeline.scheduledEnd) - new Date(b.timeline.scheduledEnd)
      );
      this.actions.completed = this.actions.completed
        .sort(
          (a, b) =>
            new Date(a.timeline.actualEnd) - new Date(b.timeline.actualEnd)
        )
        .reverse();
      this.actions.archived = this.actions.archived
        .sort(
          (a, b) =>
            new Date(a.timeline.scheduledEnd) -
            new Date(b.timeline.scheduledEnd)
        )
        .reverse();
      this.actions.failed = this.actions.failed
        .sort(
          (a, b) =>
            new Date(a.timeline.scheduledEnd) -
            new Date(b.timeline.scheduledEnd)
        )
        .reverse();
    },
    SetAuditActionValues(auditActions) {
      try {
        //this.actions.current = this.actionPlan.itemCollection;
        this.currentAuditPointActions.recommended = auditActions.filter(
          (i) => i.state.stateCode == "Available"
        );
        this.currentAuditPointActions.current = auditActions.filter(
          (i) => i.state.stateCode == "Open"
        );
        this.currentAuditPointActions.current =
          this.currentAuditPointActions.current.concat(
            auditActions.filter((i) => i.state.stateCode == "PendingComplete")
          );
        this.currentAuditPointActions.completed = auditActions.filter(
          (i) => i.state.stateCode == "Complete"
        );
        // this.actions.completed = this.actions.completed.concat(this.actionPlan.itemCollection
        // .filter(i => i.state.stateCode == "Completed"));
        this.currentAuditPointActions.archived = auditActions.filter(
          (i) => i.state.stateCode == "Deferred"
        );
      } catch (e) {
        console.log(e);
        this.fireErrorAlert(
          "We ran into an error, please try again later.",
          "SetActionValues"
        );
      }
    },
    SetMemos(level) {
      // Could be multiple visibilities in level, such as 'EA|Customer'
      let visibilities = level.split("|");

      try {
        var memos = [];
        for (var i = 0; i < this.actionPlan.itemCollection.length; i++) {
          var memosToAdd = {
            id: this.actionPlan.itemCollection[i].id,
            memos: this.actionPlan.itemCollection[i].memos.filter(
              (m) => {
                if (m.stateCode == "Deferred") return false;

                return (
                  m.visibility
                    .split("|")
                    .filter((v) => visibilities.indexOf(v) != -1).length > 0
                );
              }
              //m.visibility.includes(level) && m.stateCode != "Deferred"
            ),
          };
          memos = memos.concat(memosToAdd);
          for (
            var j = 0;
            j < this.actionPlan.itemCollection[i].tasks.length;
            j++
          ) {
            memosToAdd = {
              id: this.actionPlan.itemCollection[i].tasks[j].id,
              memos: this.actionPlan.itemCollection[i].tasks[j].memos.filter(
                (m) => {
                  if (m.stateCode == "Deferred") return false;

                  return (
                    m.visibility
                      .split("|")
                      .filter((v) => visibilities.indexOf(v) != -1).length > 0
                  );
                }

                //(m) => m.visibility.includes(level) && m.stateCode != "Deferred"
              ),
            };
            memos = memos.concat(memosToAdd);
          }
        }
        this.memos = memos;
      } catch (e) {
        console.log(e);
        this.fireErrorAlert(
          "We ran into an error, please try again later.",
          "SetMemos"
        );
      }
    },
    SetMemosForUI(id, type) {
      var memos = [];
      var relatedMemos = this.memos.find((m) => m.id == id);
      if (relatedMemos != undefined) {
        for (var j = 0; j < relatedMemos.memos.length; j++) {
          memos.push({
            id: relatedMemos.memos[j].Id,
            createdBy: relatedMemos.memos[j].createdBy,
            message: relatedMemos.memos[j].body,
            visibility: relatedMemos.memos[j].visibility,
            stateCode: relatedMemos.memos[j].stateCode,
            statusCode: relatedMemos.memos[j].statusCode,
          });
        }
      }

      switch (type) {
        case "Recommended":
          this.memosForUi.recommended =
            memos.length > 0
              ? memos.reverse()
              : [
                  {
                    id: "",
                    createdBy: "",
                    message: "No notes.",
                    visibility: "",
                    stateCode: "",
                    statusCode: "",
                  },
                ];
          break;
        case "Current":
          this.memosForUi.current =
            memos.length > 0
              ? memos.reverse()
              : [
                  {
                    id: "",
                    createdBy: "",
                    message: "No notes.",
                    visibility: "",
                    stateCode: "",
                    statusCode: "",
                  },
                ];
          break;
        case "Completed":
          this.memosForUi.completed =
            memos.length > 0
              ? memos.reverse()
              : [
                  {
                    id: "",
                    createdBy: "",
                    message: "No notes.",
                    visibility: "",
                    stateCode: "",
                    statusCode: "",
                  },
                ];
          break;
        case "Archived":
          this.memosForUi.archived =
            memos.length > 0
              ? memos.reverse()
              : [
                  {
                    id: "",
                    createdBy: "",
                    message: "No notes.",
                    visibility: "",
                    stateCode: "",
                    statusCode: "",
                  },
                ];
          break;
        case "Ab Integro":
          this.memosForUi.abIntegro =
            memos.length > 0
              ? memos.reverse()
              : [
                  {
                    id: "",
                    createdBy: "",
                    message: "No notes.",
                    visibility: "",
                    stateCode: "",
                    statusCode: "",
                  },
                ];
          break;
      }
    },
    SortNewMemo(newMemo, ActionPlanId, type) {
      this.CheckForDefaultUiMemo(type);
      if (this.memos.find((m) => m.id == ActionPlanId) != undefined) {
        this.memos.find((m) => m.id == ActionPlanId).memos.push(newMemo);
      } else {
        this.memos.push({ id: ActionPlanId, memos: newMemo });
      }
      switch (type) {
        case "Recommended":
          this.memosForUi.recommended.push({
            createdBy: newMemo.createdBy,
            message: newMemo.body,
          });
          break;
        case "Current":
          this.memosForUi.current.push({
            createdBy: newMemo.createdBy,
            message: newMemo.body,
          });
          break;
        case "Completed":
          this.memosForUi.completed.push({
            createdBy: newMemo.createdBy,
            message: newMemo.body,
          });
          break;
        case "Archived":
          this.memosForUi.archived.push({
            createdBy: newMemo.createdBy,
            message: newMemo.body,
          });
          break;
        case "Ab Integro":
          this.memosForUi.abIntegro.push({
            createdBy: newMemo.createdBy,
            message: newMemo.body,
          });
          break;
      }
    },
    CheckForDefaultUiMemo(type) {
      switch (type) {
        case "Recommended":
          this.memosForUi.recommended = this.memosForUi.recommended.filter(
            (m) => m.message != "No notes."
          );
          break;
        case "Current":
          this.memosForUi.current = this.memosForUi.current.filter(
            (m) => m.message != "No notes."
          );
          break;
        case "Completed":
          this.memosForUi.completed = this.memosForUi.completed.filter(
            (m) => m.message != "No notes."
          );
          break;
        case "Archived":
          this.memosForUi.createdBy = this.memosForUi.createdBy.filter(
            (m) => m.message != "No notes."
          );
          break;
        case "Ab Integro":
          this.memosForUi.abIntegro = this.memosForUi.abIntegro.filter(
            (m) => m.message != "No notes."
          );
          break;
      }
    },
    findAction(actionId) {
      this.fireLoadingAlert("findAction");
      for (var r = 0; r < this.actions.recommended.length; r++) {
        if (this.actions.recommended[r].id == actionId) {
          var recommendedAction = this.actions.recommended[r];
          this.closeAlert("findAction");
          return recommendedAction;
        }
      }
      for (var cu = 0; cu < this.actions.current.length; cu++) {
        if (this.actions.current[cu].id == actionId) {
          var currentAction = this.actions.current[cu];
          this.closeAlert("findAction");
          return currentAction;
        }
      }
      for (var co = 0; co < this.actions.completed.length; co++) {
        if (this.actions.completed[co].id == actionId) {
          var completedAction = this.actions.completed[co];
          this.closeAlert("findAction");
          return completedAction;
        }
      }
      for (var a = 0; a < this.actions.archived.length; a++) {
        if (this.actions.archived[a].id == actionId) {
          var archivedAction = this.actions.archived[a];
          this.closeAlert("findAction");
          return archivedAction;
        }
      }
      this.fireErrorAlert(
        "Error loading action - please try again later",
        "findAction"
      );
      return null;
    },
    findTask(taskId) {
      this.fireLoadingAlert("findTask");
      for (var r = 0; r < this.actions.recommended.length; r++) {
        var recommendedAction = this.actions.recommended[r].tasks.find(
          (t) => t.id == taskId
        );
        if (recommendedAction != undefined) {
          this.closeAlert("findTask");
          return recommendedAction;
        }
      }
      for (var cu = 0; cu < this.actions.current.length; cu++) {
        var currentAction = this.actions.current[cu].tasks.find(
          (t) => t.id == taskId
        );
        if (currentAction != undefined) {
          this.closeAlert("findTask");
          return currentAction;
        }
      }
      for (var co = 0; co < this.actions.completed.length; co++) {
        var completedAction = this.actions.completed[co].tasks.find(
          (t) => t.id == taskId
        );
        if (completedAction != undefined) {
          this.closeAlert("findTask");
          return completedAction;
        }
      }
      for (var a = 0; a < this.actions.archived.length; a++) {
        var archivedAction = this.actions.archived[a].tasks.find(
          (t) => t.id == taskId
        );
        if (archivedAction != undefined) {
          this.closeAlert("findTask");
          return archivedAction;
        }
      }
      this.fireErrorAlert(
        "Error loading action - please try again later",
        "findTask"
      );
      return null;
    },
    findTasksParentAction(taskId) {
      this.fireLoadingAlert("findTask");
      for (var r = 0; r < this.actions.recommended.length; r++) {
        var recommendedAction = this.actions.recommended[r].tasks.find(
          (t) => t.id == taskId
        );
        if (recommendedAction != undefined) {
          this.closeAlert("findTask");
          return this.actions.recommended[r];
        }
      }
      for (var cu = 0; cu < this.actions.current.length; cu++) {
        var currentAction = this.actions.current[cu].tasks.find(
          (t) => t.id == taskId
        );
        if (currentAction != undefined) {
          this.closeAlert("findTask");
          return this.actions.current[cu];
        }
      }
      for (var co = 0; co < this.actions.completed.length; co++) {
        var completedAction = this.actions.completed[co].tasks.find(
          (t) => t.id == taskId
        );
        if (completedAction != undefined) {
          this.closeAlert("findTask");
          return this.actions.completed[co];
        }
      }
      for (var a = 0; a < this.actions.archived.length; a++) {
        var archivedAction = this.actions.archived[a].tasks.find(
          (t) => t.id == taskId
        );
        if (archivedAction != undefined) {
          this.closeAlert("findTask");
          return this.actions.archived[a];
        }
      }
      this.fireErrorAlert(
        "Error loading action - please try again later",
        "findTask"
      );
      return null;
    },
    async CreateNewMemo(text, visibleToAll) {
      if (this.creatingMemo.taskId != null) {
        await this.CreateTaskItemMemo(
          this.creatingMemo.actionId,
          this.creatingMemo.taskId,
          text,
          visibleToAll
            ? "EA|Customer"
            : this.creatingMemo.createdByEa
            ? "EA"
            : !this.creatingMemo.createdByEa
            ? "Customer"
            : "EA",
          this.creatingMemo.type
        );
      } else {
        await this.CreateActionPlanItemMemo(
          this.creatingMemo.actionId,
          text,
          visibleToAll
            ? "EA|Customer"
            : this.creatingMemo.createdByEa
            ? "EA"
            : !this.creatingMemo.createdByEa
            ? "Customer"
            : "EA",
          this.creatingMemo.type
        );
      }
    },

    fireLoadingAlert(caller) {
      if (this.loadingAlertActive.length == 0 && !this.errorAlertActive) {
        Swal.fire({
          title: "",
          html: '<div class="loader-container"><div class="loader"></div></div>',
          //type: "custom-html",
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          focusConfirm: false,
          buttonsStyling: false,
          allowOutsideClick: false,
        });
      }
      if (!this.loadingAlertActive.includes(caller)) {
        this.loadingAlertActive.push(caller);
      }
    },
    fireErrorAlert(message, caller) {
      if (!this.errorAlertActive) {
        this.errorAlertActive = true;
        this.closeAlert(caller);
        Swal.fire({
          icon: "error",
          title: "<strong>Problem while loading.</strong>",
          html: message,
          //type: "custom-html",
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "Okay",
          confirmButtonAriaLabel: "Okay",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then(() => {
          this.errorAlertActive = false;
        });
      }
    },
    fireCustomErrorAlert(title, message, caller) {
      if (!this.errorAlertActive) {
        this.errorAlertActive = true;
        this.closeAlert(caller);
        Swal.fire({
          icon: "error",
          title: "<strong>" + title + "</strong>",
          html: message,
          //type: "custom-html",
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "Okay",
          confirmButtonAriaLabel: "Okay",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then(() => {
          this.errorAlertActive = false;
        });
      }
    },
    closeAlert(caller) {
      this.loadingAlertActive = this.loadingAlertActive.filter(
        (i) => i != caller
      );
      if (!this.errorAlertActive && this.loadingAlertActive.length == 0) {
        Swal.close();
      }
    },
    fireConfirmCustomerCompleteAlert(id, parentActionId, completed, isTask) {
      var updateTask = this.UpdateTaskCompletedByCustomer;
      var updateAction = this.UpdateActionCompletedByCustomer;
      var func = function (
        updateTask,
        updateAction,
        id,
        parentActionId,
        completed,
        isTask
      ) {
        if (isTask) {
          updateTask(parentActionId, id, completed, true);
        } else {
          updateAction(id, completed, true);
        }
      };
      if (this.loadingAlertActive.length == 0 && !this.errorAlertActive) {
        Swal.fire({
          icon: "info",
          title: "<strong>Complete Action</strong>",
          html: "Are you sure you want to change the status of this action?",
          //type: "custom-html",
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Yes",
          confirmButtonAriaLabel: "Yes",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            func(
              updateTask,
              updateAction,
              id,
              parentActionId,
              completed,
              isTask
            );
          }
        });
      }
    },
    async fireConfirmEditMemoAlert(
      memoId,
      memoContent,
      visibility,
      stateCode,
      statusCode,
      isTask
    ) {
      var customerId = this.customerId;
      var actionId = this.creatingMemo.actionId;
      var taskId = this.creatingMemo.taskId;
      var refreshData = this.RefreshDataAfterMemoChanged;
      var func = async function (
        memoId,
        actionId,
        taskId,
        memoContent,
        visibility,
        stateCode,
        statusCode,
        isTask,
        customerId,
        refreshData
      ) {
        if (isTask) {
          await actionManager.EditTaskMemo(
            memoId,
            customerId,
            actionId,
            taskId,
            memoContent,
            visibility,
            stateCode,
            statusCode,
            customerId
          );
          refreshData(visibility, taskId);
        } else {
          await actionManager.EditActionPlanItemMemo(
            memoId,
            customerId,
            actionId,
            memoContent,
            visibility,
            stateCode,
            statusCode,
            customerId
          );
          refreshData(visibility, actionId);
        }
      };
      if (this.loadingAlertActive.length == 0 && !this.errorAlertActive) {
        Swal.fire({
          icon: "info",
          title: "<strong>Edit Note</strong>",
          html: "Are you sure you want to save your changes?",
          //type: "custom-html",
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Yes",
          confirmButtonAriaLabel: "Yes",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          closeOnClickOutside: false,
        }).then((result) => {
          if (result.isConfirmed) {
            func(
              memoId,
              actionId,
              taskId,
              memoContent,
              visibility,
              stateCode,
              statusCode,
              isTask,
              customerId,
              refreshData
            );
          }
        });
      }
    },
    async fireConfirmDeleteMemoAlert(memoId, memoContent, visibility, isTask) {
      var deleteteTaskMemo = this.DeleteTaskItemMemo;
      var deleteActionPlanItemMemo = this.DeleteActionPlanItemMemo;
      var func = async function (memoContent, visibility, memoId, isTask) {
        if (isTask) {
          await deleteteTaskMemo(memoContent, visibility, memoId);
        } else {
          deleteActionPlanItemMemo(memoContent, visibility, memoId);
        }
      };
      if (this.loadingAlertActive.length == 0 && !this.errorAlertActive) {
        Swal.fire({
          icon: "info",
          title: "<strong>Delete Note</strong>",
          html: "Are you sure you want to delete this Note?",
          //type: "custom-html",
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Yes",
          confirmButtonAriaLabel: "Yes",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          closeOnClickOutside: false,
        }).then((result) => {
          if (result.isConfirmed) {
            func(memoContent, visibility, memoId, isTask);
          }
        });
      }
    },
    SetActivityToConvert(activityId) {
      var activity = this.externalActivites.find((i) => i.id == activityId);
      if (activity == undefined) {
        return;
      }
      this.activityToConvert = activity;
    },
    UpdateRelevantCompletedByCustomer(id, parentActionId, completed, isTask) {
      if (isTask) {
        this.UpdateTaskCompletedByCustomer(parentActionId, id, completed, true);
      } else {
        this.UpdateActionCompletedByCustomer(id, completed, true);
      }
    },

    moveAction(action, destination) {
      this.memosForUi.recommended = [
        { createdBy: "", message: "Select an action." },
      ];
      this.memosForUi.current = [
        { createdBy: "", message: "Select an action." },
      ];
      this.memosForUi.completed = [
        { createdBy: "", message: "Select an action." },
      ];
      this.memosForUi.archived = [
        { createdBy: "", message: "Select an action." },
      ];
      switch (destination) {
        case "Recommended":
          this.actions.recommended.push(action);
          break;
        case "Current":
          this.actions.current.push(action);
          break;
        case "Completed":
          this.actions.completed.push(action);
          break;
        case "Archived":
          this.actions.archived.push(action);
          break;
      }
    },
    async GetAllActions(level) {
      this.fireLoadingAlert("GetAllActions");
      var resp = await ActionManager.GetCurrentActionsAsync(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAllActions"
          );
          return;
        }
      }
      this.actionPlanResult = resp.data;
      this.filterActions();
      var memos = [];
      for (var i = 0; i < this.actionPlanResult.length; i++) {
        var memosToAdd = {
          id: this.actionPlanResult[i].id,
          memo: this.actionPlanResult[i].memos.filter((m) =>
            m.visibility.includes(level)
          ),
        };
        memos = memos.concat(memosToAdd);
        for (var j = 0; j < this.actionPlanResult[i].tasks.length; j++) {
          memosToAdd = {
            id: this.actionPlanResult[i].tasks[j].id,
            memo: this.actionPlanResult[i].tasks[j].memos.filter((m) =>
              m.visibility.includes(level)
            ),
          };
          memos = memos.concat(memosToAdd);
        }
      }
      this.memos = memos;
      this.closeAlert("GetAllActions");
    },
    filterActions() {
      this.actions.recommended = this.actionPlanResult.filter(
        (i) => i.state.stateCode == "Open"
      );
      this.actions.current = this.actionPlanResult.filter(
        (i) => i.state.stateCode == "In Progress"
      );
      this.actions.completed = this.actionPlanResult.filter(
        (i) => i.state.stateCode == "Completed"
      );
      this.actions.archived = this.actionPlanResult.filter(
        (i) => i.state.stateCode == "Archive"
      );
    },
    // async SetAllValues(id){
    //     await Promise.all([
    //         await this.GetActivitiesAsync(id),
    //         await this.GetUpcomingMeetings(id)]);
    //     this.setCalendarMeetings();
    //     var date = this.upcomingMeetings.length > 0
    //     ? new Date(this.upcomingMeetings[0].scheduledStart)
    //     : new Date();
    //     this.nextMeetingDate = this.upcomingMeetings.length > 0
    //     ? [date.getDate() < 10
    //         ? '0' + (date.getDate()).toString()
    //         :date.getDate(),
    //         date.getMonth()+1 < 10
    //         ? '0' + (date.getMonth()+1).toString()
    //         : date.getMonth()+1,
    //         date.getFullYear()].join('/')+' '+
    //         [date.getHours()+1 < 10
    //         ? '0' + (date.getHours()).toString()
    //         : date.getHours(),
    //         date.getMinutes()+1 < 10
    //         ? '0' + (date.getMinutes()).toString()
    //         : date.getMinutes(),].join(':')
    //     : 'N/A';
    // },
    async GetActivitiesAsync(id) {
      this.fireLoadingAlert("GetActivitiesAsync");
      var resp = await CalendarManager.GetAllMeetings(id);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetActivitiesAsync"
          );
          return;
        }
      }
      this.activities = resp.data;
      this.closeAlert("GetActivitiesAsync");
    },
    TryGetActivity(id) {
      if (this.activities.length > 0) {
        var act = this.activities.find((a) => a.activityId == id);
        if (act != undefined) {
          this.activity = act;
        }
      }
      // console.log(id);
      // if(this.activities.length > 0){
      //     this.activity = this.activities[0];
      // }
    },
    // async GetAuditPoints(id){
    //     this.fireLoadingAlert("getAuditPoints");
    //     var resp = await actionManager.GetAllAuditPoints(id);
    //     if(resp.name != undefined){
    //         if(resp.name == "AxiosError"){
    //             this.fireErrorAlert(resp.message + " - Please try again later.", "getAuditPoints");
    //               return;
    //         }
    //     }
    //     this.auditPoints = resp.data.length > 0
    //     ? resp.data.sort((a, b) => new Date(b.create) - new Date(a.create))
    //     : this.auditPoints;
    //     this.auditPoints.reverse();
    //     this.closeAlert("getAuditPoints");
    // },
    async GetAuditPoint(customerId, auditId) {
      this.fireLoadingAlert("GetAuditPoint");
      var resp = await actionManager.GetAuditPoint(customerId, auditId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetAuditPoint"
          );
          return;
        }
      }
      this.auditPoints.push(resp.data);
      this.closeAlert("GetAuditPoint");
    },
    async CreateNewAuditPoint(customerId, tasks) {
      this.fireLoadingAlert("CreateNewAuditPoint");
      var resp = await actionManager.CreateNewAuditPoint(customerId, tasks);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "CreateNewAuditPoint"
          );
          return;
        }
      }
      this.GetAuditPoint(customerId, resp.data.auditPointId);
      this.closeAlert("CreateNewAuditPoint");
    },

    async createNewTaskMemo(actionPlanId, taskId, memo, type) {
      this.fireLoadingAlert("createNewTaskMemo");
      var resp = await ActionManager.CreateNewTaskMemoAsync(
        this.customerId,
        actionPlanId,
        taskId,
        memo
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "createNewTaskMemo"
          );
          return;
        }
      }
      console.log(type);
      //this.sortMemosAfterCreation(resp, type);
      this.closeAlert("createNewTaskMemo");
    },
    async CreateNewActionPlanMemo(actionPlanId, memo, type) {
      this.fireLoadingAlert("CreateNewActionPlanMemo");
      var resp = await ActionManager.CreateNewActionPlanMemoAsync(
        this.customerId,
        actionPlanId,
        memo
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "CreateNewActionPlanMemo"
          );
          return;
        }
      }
      console.log(type);
      //this.sortMemosAfterCreation(resp, type);
      this.closeAlert("CreateNewActionPlanMemo");
    },
    RenderCalendar() {
      this.calendar = new Calendar(document.getElementById("calendar"), {
        contentHeight: "auto",
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        selectable: true,
        editable: true,
        events: this.getCalendarEvents,
        initialDate: "2022-07-04",
        headerToolbar: {
          start: "title",
          center: "",
          end: "today prev,next",
        },
        views: {
          month: {
            titleFormat: {
              month: "long",
              year: "numeric",
            },
          },
          agendaWeek: {
            titleFormat: {
              month: "long",
              year: "numeric",
              day: "numeric",
            },
          },
          agendaDay: {
            titleFormat: {
              month: "short",
              year: "numeric",
              day: "numeric",
            },
          },
        },
      });
      this.calendar.render();
    },
    DestroyCalendar() {
      if (this.calendar != undefined) {
        this.calendar.destroy();
        this.calendar = undefined;
      }
    },
    CreateNewChart() {
      var ctx = document.getElementById("progress-line-chart").getContext("2d");
      var gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke1.addColorStop(1, "rgba(33,82,255,0.1)");
      new Chart(ctx, {
        type: "line",
        data: {
          labels: this.getMonthlyCompletedData.months,
          datasets: [
            {
              label: "Tasks",
              tension: 0.3,
              pointRadius: 2,
              pointBackgroundColor: "#2dce89 ",
              borderColor: "#2dce89 ",
              borderWidth: 2,
              backgroundColor: gradientStroke1,
              data: this.getMonthlyCompletedData.values,
              maxBarThickness: 6,
              fill: true,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                color: "#252f40",
                padding: 10,
              },
            },
            // eslint-disable-next-line no-dupe-keys
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            // eslint-disable-next-line no-dupe-keys
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
          },
        },
      });
    },
    CreateNewSpiderGraph() {
      var ctx = document.getElementById("radar-chart").getContext("2d");

      let chartStatus = Chart.getChart("radar-chart");
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      var values = [];
      for (var i = 0; i < this.assessmentGraphData.length; i++) {
        values.push(this.assessmentGraphData[i].item2);
      }

      var labels = this.MapSpiderGraphLabels();

      new Chart(ctx, {
        type: "radar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Assessment Results",
              backgroundColor: "rgba(58,65,111,0.2)",
              data: values,
              borderDash: [5, 5],
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            r: {
              suggestedMin: 0,
              suggestedMax: 10,
            },
          },
        },
      });
    },
    MapSpiderGraphLabels() {
      var labels = [];
      for (var i = 0; i < this.assessmentGraphData.length; i++) {
        switch (this.assessmentGraphData[i].item1) {
          case "I am interested in thinking about my interests and career goals":
            labels.push("Career Goals");
            break;
          case "If I started work, it might upset my family life":
            labels.push("Family life");
            break;
          case "I am putting alot of effort into looking for a job":
            labels.push("Job search");
            break;
          case "I have started to think about careers and jobs I could do":
            labels.push("Realistic Jobs");
            break;
          case "I am following up on my current list of job opportunities":
            labels.push("Job Opportunities");
            break;
          case "If I change from my career goal, people will think I have failed":
            labels.push("Changing Job Outlook");
            break;
          case "I am working really hard to get a job":
            labels.push("Job Search Effort");
            break;
          case "Maybe this programme can help me to get a job":
            labels.push("Programme Outlook");
            break;
          case "I believe I might be worse off financially it I got a job":
            labels.push("Financial Outlook");
            break;
          case "I am setting up job interviews with employers":
            labels.push("Job Interviews");
            break;
          case "I don't understand why I need to look for a job":
            labels.push("Career Motivation");
            break;
          case "I would think about doing some training if it helped me to get a job":
            labels.push("Training Outlook");
            break;
          default:
            labels.push(this.assessmentGraphData[i].item1);
            break;
        }
      }
      return labels;
    },

    /**
     * Updates this store with the customer's latest Better off Calculator (PIP case) results.
     * @returns Whether the request was successful.
     */
    async GetPipCase() {
      this.fireLoadingAlert("GetPipCase");
      var resp = await PipManager.GetPipCase(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetPipCase");
          } else if (resp.response.status != 404) {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetPipCase"
            );
          }
          this.closeAlert("GetPipCase");
          return false;
        }
      }

      this.pipCase = {
        id: resp.data.id,
        createdOn: resp.data.createdOn,
        timestamp: resp.data.timestamp,
        customerId: resp.data.customerId,
        customerDisplay: resp.data.customerDisplay,
        customerMemorableId: resp.data.customerMemorableId,
        caseReference: resp.data.caseReference,
        caseUrl: resp.data.caseUrl,
        inputs: resp.data.inputs,
        outputs: resp.data.outputs,
        notFound: resp.data.notFound,
      };

      this.closeAlert("GetPipCase");
      return true;
    },

    async GetSectorDefinitions() {
      this.fireLoadingAlert("GetSectorDefinitions");
      var resp = await ActionManager.getSectorDefinitions(this.customerId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetSectorDefinitions");
          } else if (resp.response.status != 404) {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetSectorDefinitions"
            );
          }
          this.closeAlert("GetSectorDefinitions");
          return false;
        }
      }
      this.closeAlert("GetSectorDefinitions");
      this.sectorDefinitions = resp.data;
      return resp.data;
    },

    async GetGroupCustomerGeneratedAudits(
      owners,
      generatedFrom,
      generatedBefore
    ) {
      this.fireLoadingAlert("GetGroupCustomerGeneratedAudits");
      var resp = await ActionManager.GetGroupCustomerGeneratedAudits(
        owners,
        generatedFrom,
        generatedBefore
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetGroupCustomerGeneratedAudits");
          } else {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetGroupCustomerGeneratedAudits"
            );
          }
          this.closeAlert("GetGroupCustomerGeneratedAudits");
          return false;
        }
      }

      this.customerGeneratedAudits = resp.data;

      this.closeAlert("GetGroupCustomerGeneratedAudits");
      return true;
    },
    async GetCustomerGeneratedAudits(owner, generatedFrom, generatedBefore) {
      this.fireLoadingAlert("GetCustomerGeneratedAudits");
      var resp = await ActionManager.GetCustomerGeneratedAudits(
        owner,
        generatedFrom,
        generatedBefore
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetCustomerGeneratedAudits");
          } else {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetCustomerGeneratedAudits"
            );
          }
          this.closeAlert("GetCustomerGeneratedAudits");
          return false;
        }
      }

      this.customerGeneratedAudits = resp.data;

      this.closeAlert("GetCustomerGeneratedAudits");
      return true;
    },

    async GetGroupCustomerProjectedAudits(owners, dueFrom, dueBefore) {
      this.fireLoadingAlert("GetGroupCustomerProjectedAudits");
      var resp = await ActionManager.GetGroupCustomerProjectedAudits(
        owners,
        dueFrom,
        dueBefore
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetGroupCustomerProjectedAudits");
          } else {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetGroupCustomerProjectedAudits"
            );
          }
          this.closeAlert("GetGroupCustomerProjectedAudits");
          return false;
        }
      }

      this.customerProjectedAudits = resp.data;

      this.closeAlert("GetGroupCustomerProjectedAudits");
      return true;
    },
    async GetCustomerProjectedAudits(owner, dueFrom, dueBefore) {
      this.fireLoadingAlert("GetCustomerProjectedAudits");
      var resp = await ActionManager.GetCustomerProjectedAudits(
        owner,
        dueFrom,
        dueBefore
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetCustomerProjectedAudits");
          } else {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetCustomerProjectedAudits"
            );
          }
          this.closeAlert("GetCustomerProjectedAudits");
          return false;
        }
      }

      this.customerProjectedAudits = resp.data;

      this.closeAlert("GetCustomerProjectedAudits");
      return true;
    },

    async GetGroupCustomerSignedAudits(owners, signedFrom, signedBefore) {
      this.fireLoadingAlert("GetGroupCustomerSignedAudits");
      var resp = await ActionManager.GetGroupCustomerSignedAudits(
        owners,
        signedFrom,
        signedBefore
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetGroupCustomerSignedAudits");
          } else if (resp.response.status != 404) {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetGroupCustomerSignedAudits"
            );
          }
          this.closeAlert("GetGroupCustomerSignedAudits");
          return false;
        }
      }

      this.customerSignedAudits = resp.data;

      this.closeAlert("GetGroupCustomerSignedAudits");
      return true;
    },
    async GetCustomerStatus() {
      this.fireLoadingAlert("GetCustomerStatus");
      var resp = await ActionManager.GetCustomerStatus();
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetCustomerStatus");
          } else if (resp.response.status != 404) {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetCustomerStatus"
            );
          }
          this.closeAlert("GetCustomerStatus");
          return false;
        }
      }

      this.customerStatus = resp.data;

      this.closeAlert("GetCustomerStatus");
      return true;
    },
    async GetCustomerSignedAudits(owner, signedFrom, signedBefore) {
      this.fireLoadingAlert("GetCustomerSignedAudits");
      var resp = await ActionManager.GetCustomerSignedAudits(
        owner,
        signedFrom,
        signedBefore
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetCustomerSignedAudits");
          } else if (resp.response.status != 404) {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetCustomerSignedAudits"
            );
          }
          this.closeAlert("GetCustomerSignedAudits");
          return false;
        }
      }

      this.customerSignedAudits = resp.data;

      this.closeAlert("GetCustomerSignedAudits");
      return true;
    },

    getStoredActionPlanItem(actionPlanItemId) {
      var found = this.actionPlan.itemCollection.filter(
        (a) => a.id == actionPlanItemId
      );
      if (found.length == 0) {
        throw new Error(
          "action plan item could not be found " + actionPlanItemId
        );
      } else if (found.length > 1) {
        throw new Error("action plan item duplicate " + actionPlanItemId);
      }
      console.log(this.actionPlan);
      console.log(found);

      return found[0];
    },

    /**
     * Generates a MAN preview document.
     * @param {*} actionPlanItemId
     * @param {*} formData
     * @returns
     */
    async createMANPreviewDocument(actionPlanItemId, formData) {
      var resp = await MandationManager.createMANPreviewDocument(
        this.customerId,
        actionPlanItemId,
        formData
      );
      return resp.data;
    },

    /**
     * Generates a DWP-604 preview document.
     * @param {*} actionPlanItemId
     * @param {*} formData
     * @returns
     */
    async createDwp604PreviewDocument(actionPlanItemId, formData) {
      var resp = await MandationManager.createDwp604PreviewDocument(
        this.customerId,
        actionPlanItemId,
        formData
      );
      return resp.data;
    },

    /**
     * Generates the final version of a MAN.
     * @param {*} actionPlanItemId - the action plan item the MAN document relates to.
     * @param {*} formData - The data of the form to be created.
     * @returns
     */
    async createMANRecord(actionPlanItemId, formData) {
      await MandationManager.createMANRecord(
        this.customerId,
        actionPlanItemId,
        formData
      ).then(() => {
        this.GetActionPlan();
      });
    },

    /**
     * Generates a DWP-604 preview document.
     * @param {*} actionPlanItemId
     * @param {*} formData
     * @returns
     */
    async createDwp604Record(actionPlanItemId, formData) {
      await MandationManager.createDwp604Record(
        this.customerId,
        actionPlanItemId,
        formData
      ).then(() => {
        this.GetActionPlan(false);
      });
    },

    /**
     * @deprecated Use createMANPreviewDocument instead
     * @param {*} actionPlanItemId
     * @param {*} formData
     * @returns
     */
    async PostMandationForm(actionPlanItemId, formData) {
      var resp = await ActionManager.PostMandationForm(
        this.customerId,
        actionPlanItemId,
        loggedIn.ea ? loggedIn.email : this.customerId,
        formData
      ).then(() => {
        this.GetActionPlan();
      });

      return resp.data;
    },

    async GetSavedMandationForm(actionPlanItemId) {
      var resp = await ActionManager.GetSavedMandationForm(actionPlanItemId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "GetSavedMandationForm");
          } else if (resp.response.status != 404) {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "GetSavedMandationForm"
            );
          }
          this.closeAlert("GetSavedMandationForm");
          return false;
        }
      }
      this.savedManData = resp.data;
      return true;
    },

    async postMandation603(actionPlanItemId, owningAdvisor, formData) {
      await ActionManager.postMandation603(
        this.customerId,
        actionPlanItemId,
        owningAdvisor,
        formData
      ).then(() => {
        this.GetActionPlan();
      });
    },

    async postMandation604(actionPlanItemId, owningAdvisor, formData) {
      var resp = await ActionManager.postMandation604(
        this.customerId,
        actionPlanItemId,
        owningAdvisor,
        formData
      ).then(() => {
        this.GetActionPlan();
      });
      return resp;
    },

    async post604ToLogicApp(actionPlanItemId, recordId, blob) {
      console.log(loggedIn);
      var resp = await ActionManager.post604ToLogicApp(
        this.customerId,
        actionPlanItemId,
        recordId,
        blob,
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.closeAlert("post604ToLogicApp");

          throw resp;
        }
      }

      return resp;
    },

    async getSaved604Form(actionPlanItemId) {
      var resp = await ActionManager.getSaved604Form(actionPlanItemId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "getSaved604Form");
          } else if (resp.response.status != 404) {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "getSaved604Form"
            );
          }
          this.closeAlert("getSaved604Form");
          return false;
        }
      }
      this.saved604Data = resp.data;
      return true;
    },

    async getSaved603Form(actionPlanItemId) {
      var resp = await ActionManager.getSaved603Form(actionPlanItemId);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          if (resp.response.status == 500) {
            this.fireErrorAlert("", "getSaved603Form");
          } else if (resp.response.status != 404) {
            this.fireErrorAlert(
              resp.message + " - Please try again later.",
              "getSaved603Form"
            );
          }
          this.closeAlert("getSaved603Form");
          return false;
        }
      }
      this.saved603Data = resp.data;
      return true;
    },

    async post603ToLogicApp(actionPlanItemId, recordId, blob) {
      var base64 = await this.blobToBase64(blob);
      console.log(loggedIn);
      var resp = await ActionManager.post603ToLogicApp(
        this.customerId,
        actionPlanItemId,
        recordId,
        base64.substr(base64.indexOf(",") + 1),
        loggedIn.ea ? loggedIn.email : this.customerId
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.closeAlert("post603ToLogicApp");

          throw resp;
        }
      }
      return resp;
    },

    async postMandationFormToSharepoint(actionPlanItemId, memorableId, blob) {
      var base64 = await this.blobToBase64(blob);
      var resp = await ActionManager.postMandationFormToSharepoint(
        actionPlanItemId,
        memorableId,
        base64.substr(base64.indexOf(",") + 1)
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "postMandationFormToSharepoint"
          );
          throw resp;
        }
      }
      return resp;
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },

    async GetMandationEmbedGuid(documentId) {
      var resp = await ActionManager.getSPMandationForm(documentId);
      return resp.data;
    },

    async postJobGoals(jobGoals) {
      this.fireLoadingAlert("postJobGoals");
      var resp = await ActionManager.postJobGoals(this.customerId, jobGoals);
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(resp.response?.data.detail, "postJobGoals");
          return false;
        }
      }
      this.closeAlert("postJobGoals");
      return true;
    },
    async UpdateAssessmentItemDueDate(actionPlanItemId, reason, dueDate) {
      this.fireLoadingAlert("UpdateAssessmentItemDueDate");
      var resp = await ActionManager.UpdateAssessmentItemDueDate(
        this.customerId,
        actionPlanItemId,
        reason,
        dueDate,
        loggedIn.email
      );
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.response?.data.detail,
            "UpdateAssessmentItemDueDate"
          );
          return false;
        }
      }
      await this.GetActionPlan();
      this.SetMemos("EA|Customer");
      this.closeAlert("UpdateAssessmentItemDueDate");
      return true;
    },
    async StoreAuthToken() {
      this.fireLoadingAlert("StoreAuthToken");
      const resp = await actionManager.PostAuthTokenAsync();
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "StoreAuthToken"
          );
          return;
        }
      }
      this.closeAlert("StoreAuthToken");
      return resp.data;
    },

    async TriggerCTFASurvey() {
      var resp = await customerVoiceManager.TriggerCTFASurvey(this.customerId);
      this.fireLoadingAlert("TriggerCTFASurvey");
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "TriggerCTFASurvey"
          );
          return false;
        }
      }
      this.closeAlert("TriggerCTFASurvey");
      return resp.data;
    },
    async GetCustomerStrandOptions() {
      console.debug(this.customerId);
      var resp = await contactManager.GetCustomerStrandOptions(this.customerId);
      this.fireLoadingAlert("GetCustomerStrandOptions");
      if (resp.name != undefined) {
        if (resp.name == "AxiosError") {
          this.fireErrorAlert(
            resp.message + " - Please try again later.",
            "GetCustomerStrandOptions"
          );
          return false;
        }
      }
      this.closeAlert("GetCustomerStrandOptions");
      this.customerStrandOptions = resp.data;
      return true;
    },

    setManFormData(manFormData) {
      console.log(manFormData);
      this.manFormData = manFormData;
      return false;
    },
    setIsEditingManForm(value) {
      this.isEditingManForm = value;
    },
    setCurrentManFormId(id) {
      this.currentManFormId = id;
    },
    setUcManDirectFormData(data) {
      this.ucManDirectFormData = data;
    },
    setJsaManDirectFormData(data) {
      this.jsaManDirectFormData = data;
    },
    resetManFormData() {
      this.ucManDirectFormData = {};
      this.manFormData.isGroupAppointment = false;
      this.manFormData.Title = "";
      this.manFormData.activityContactName = "";
      this.manFormData.inputValue = "";
      this.manFormData.date = "";
      this.manFormData.Duedate = "";
      this.manFormData.Evidence = "";
      this.manFormData.ComplianceCondition = "";
      this.manFormData.isIssuedFaceToFace = "";
      this.manFormData.isIssuedByPost = "";
    },
  },
  formatDate(date) {
    return [
      date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate(),
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1).toString()
        : date.getMonth() + 1,
      date.getFullYear(),
    ].join("/");
  },
});
