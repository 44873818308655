<template>
  <div class="sms-card-background card">
    <div class="sms-card-body card">
      <div class="sms-card-header">
        <h3 class="sms-card-header">Customer SMS</h3>
        <svg
          @click="$emit('closeSMSModal')"
          style="width: 1.2rem; cursor: pointer"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
          ></path>
        </svg>
      </div>
      <div class="container">
        <div class="sms-to-div">
          <span for="to">Customer Number:</span>
          <input v-model="to" type="text" id="to" name="to" required />
          <div class="error" v-if="errors.to">{{ errors.to }}</div>
          <div>
            <span for="reason">Reason:</span>
          </div>
          <input
            v-model="reason"
            type="text"
            id="reason"
            name="reason"
            required
          />
          <div class="error" v-if="errors.reason">{{ errors.reason }}</div>
        </div>

        <div>
          <span for="text">Text Message:</span>
          <textarea
            class="textarea-body"
            v-model="text"
            id="textbox"
            style="
              min-width: 500px;
              margin-top: 0.5rem;
              display: flex;
              flex-direction: column;
            "
            maxlength="320"
            minlength="1"
          />
          <span
            >{{ text.length }}/320 (Texts over 160 characters may be split into
            multiple texts.)</span
          >
          <div class="error" v-if="errors.text">{{ errors.text }}</div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-around;
            margin-top: 0.6rem;
          "
        >
          <button @click="$emit('closeSMSModal')" class="btn sms-btn">
            Cancel
          </button>
          <button type="button" class="btn sms-btn" @click="sendSMS()">
            Send Text
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { useAdvisorDashboardStore } from "@/store/advisorDashboard";
import { useCommonStore } from "@/store/commonStore";
import { loggedIn } from "@/router/Guard.ts";
import axios from "axios";

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL;
const endpoint = process.env.VUE_APP_TWILIO_SMS_EMAIL;
const subscriptionKey = process.env.VUE_APP_API_GATEWAY_SUBSCRIPTION_KEY
const sender = process.env.VUE_APP_TWILIO_SMS_SENDER;

export default {
  name: "SMS-Component",
  data() {
    return {
      to: "",
      id: "",
      from: sender,
      text: "",
      subject: "SMS",
      reason: "",
      sms: "True",
      email: "False",
      requestedBy: "",
      sentAt: new Date(),
      mimeType: "application/text",
      errors: {},
    };
  },

  props: {},
  computed: {
    ...mapState(useAdvisorDashboardStore, ["getMemoView"]),
    ...mapState(useCommonStore, [
      "getCustomerId",
      "getCustomer",
      "getActionPlan",
    ]),
    characterCount() {
      return this.SMSText().length;
    },
  },
  methods: {
    ...mapActions(useAdvisorDashboardStore, []),
    ...mapActions(useCommonStore, []),

    closeSMSModal() {
      this.$emit("closeSMSModal");
    },

    async sendSMS() {
      this.errors = {};

      if (!this.to) {
        this.errors.to = "Customer number is required";
      }

      if (!this.reason) {
        this.errors.reason = "Reason is required";
      }

      if (!this.text) {
        this.errors.text = "Text is required";
      } else if (this.text.length < 1) {
        this.errors.text = "Text message cannot be this short";
      }

      if (Object.keys(this.errors).length === 0) {
        this.closeSMSModal();
        try {
          var instance = axios.create();

          // Remove bearer authorization as API gateway only allows one type of auth
          // We are using Ocp-Apim-Subscription-Key here
          delete instance.defaults.headers.common['Authorization'];
          
          const resp = await instance({
            method: "POST",
            url: baseUrl + endpoint,
            responseType: "json",
            headers: {
              "Ocp-Apim-Subscription-Key": subscriptionKey,
            },
            data: {
              id: this.id,
              to: this.to,
              from: this.from,
              text: this.text,
              reason: this.reason,
              sms: this.sms,
              email: this.email,
              requestedBy: this.requestedBy,
              sentAt: this.sentAt,
              subject: this.subject,
              mimeType: this.mimeType,
            },
          });
          return resp;
        } catch (error) {
          return error;
        }
      }
    },
  },

  mounted() {
    this.requestedBy += `${this.getCustomer.advisor.id}`;
    this.id += `${this.getCustomerId}`;
    this.to += `${this.getCustomer.mobile}`;
    let content = "";
    content += `Dear ${this.getCustomer.firstName.charAt(0).toUpperCase()}`;
    content += `${this.getCustomer.firstName.slice(1).toLowerCase()},\n`;
    content += `\n\nThanks, `;
    content += loggedIn.name;
    this.text = content;
  },
};
</script>
<style scoped>
.container {
  width: 100% !important;
  justify-content: stretch;
}

.sms-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  border-bottom: 1px solid #ebebeb;
  padding: 0.4rem 1.4rem;
}

.sms-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  min-height: 35%;
  min-width: 29%;
}
.sms-card-background {
  position: fixed;
  z-index: 10;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sms-to-div {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 500px;
}

.error {
  color: red;
}

@media only screen and (min-height: 600px) {
  .textarea-body {
    min-height: 300px;
  }
}

@media only screen and (max-height: 600px) {
  .textarea-body {
    min-height: 110px;
    max-height: 150px;
  }
}
</style>
