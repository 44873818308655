<template>
    <div class="page-btn-container">
        <!-- Previous page button (active) -->
        <div v-if="this.pageIndex > 1" 
            class="page-btn back-page" 
            @click="setPage(this.pageIndex - 1)">  

            <b>&#60;</b>
        </div>

        <!-- Previous page button (inactive) -->
        <div v-else class="page-btn back-page inactive">
            <b>&#60;</b>           
        </div>

        <!-- Render quick access buttons -->
        <div v-for="num in quickAccessPages.length > pageCount ? pageCount : quickAccessPages" :key="num"          
            :class="num == pageIndex? 'selected-page-btn selected-page-btn page-btn' : 'page-btn'" 
            class="page-btn" 
            @click="setPage(num)">
            
            {{num}}
        </div>

        <!-- Display option to show more page numbers, if necessary -->
        <div v-if="quickAccessPages.length < pageCount" 
            class="page-btn select-page-index" 
            ref="page-cont"
            :id="'page-cont'"
            @click="togglePageSelectionView()">

            <b>...</b>
        </div>

        <!-- Next page button (active) -->
        <div v-if="this.pageIndex < pageCount" 
            class="page-btn next-page" 
            @click="setPage(this.pageIndex + 1)">
            
            <b style="display: flex; align-items:center;">&#62;</b>
        </div>

        <!-- Next page button (inactive) -->
        <div v-if="this.pageIndex == pageCount" 
            class="page-btn next-page inactive">

            <b>&#62;</b>
        </div>

        <!-- Page Selection View: Displays all possible page numbers -->
        <div 
            ref="page-select"
            class="page-selector" 
            :class="pageCount <= 7 ? 'page-selctor-1' : pageCount > 7 && pageCount <= 14 ? 'page-selctor-2' : pageCount > 14 ? 'page-selctor-3' : 'page-selctor-1'"       
            :id="'page-select'" hidden>

            <div v-for="index in pageCount" 
                :key="index" 
                @click="{ setPage(index); togglePageSelectionView(); }">

                {{index}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "table-pagination",

    data() {
        return {
            pageIndex: 1,
            itemsPerPage: this.pageSize,
            pageSelectionView: false,
            buttonCount: this.buttons
        }
    },

    props:{
        /**
         * Maximum number of items which can be displayed on each page.
         */
        pageSize: {
            type: Number,
            default: 5,
        },

        /**
         * The items to be handled by the pagination.
         */
        items: {
            type: Array,
            default: () => []
        },

        /**
         * The number of quick access buttons to display.
         */
        buttons: {
            type: Number,
            default: 4,
            validator: value => {           
                return value >= 1 && value <= 10
            }
        },

        /**
         * The items held on the currently selected page.
         */
        modelValue: Array
    },

    watch: { 
        items: function() {
            // Apply data binding when 'items' property changes
            this.$emit("update:modelValue", this.getPaginatedItems())
        },

        itemsPerPage: function() {
            if (this.pageIndex > this.pageCount) {
                this.setPage(this.pageCount)
            } else {
                this.$emit("update:modelValue", this.getPaginatedItems())
            }
        }
    },

    computed: {
        /**
         * Gets the total number of pages for this pagination.
         */
        pageCount() {
            return Math.max(1, Math.ceil(this.items.length / this.itemsPerPage));
        },

        /**
         * Gets a list of page indices which should be displayed as quick access buttons
         */
        quickAccessPages() {
            let indices = []

            // Total number of buttons, excluding the current page
            let count = this.buttonCount - 1

            let start = this.pageIndex - count
            let end = this.pageIndex + (1 - Math.min(1, start))

            let actualStart = Math.max(1, start)
            for (let i = actualStart; i <= end; i++) {
                indices[i - actualStart] = i
            }

            return indices
        }
    },

    methods: {
        /**
         * Sets and loads the specified page.
         * @param {*} index - the page number.
         */
        setPage(index) {
            if (this.pageIndex == index)
                return

            // Clamp index to minimum and maximum allowed values
            this.pageIndex = index < 1 ? 1 : index > this.pageCount ? this.pageCount : index;


            // Update 'modelValue' property for data binding
            this.$emit("update:modelValue", this.getPaginatedItems())
            this.$emit("page-change", this.pageIndex)
        },

        /**
         * Gets the paginated items for the current page.
         */
        getPaginatedItems() {
            return this.items.slice(
                (this.pageIndex - 1) * this.itemsPerPage,
                ((this.pageIndex - 1) * this.itemsPerPage) + this.itemsPerPage
            );
        },

        setPaginatedItems() {
            this.$emit("update:modelValue", this.getPaginatedItems());
            return;
        },

        /**
         * Toggles the page selection view, which shows all page numbers.
         */
        togglePageSelectionView(){
            this.pageSelectionView = !this.pageSelectionView

            var pageSelectElement = this.$refs['page-select'];
            pageSelectElement.hidden = !this.pageSelectionView

            var pageCountElement = this.$refs['page-cont']

            // Apply appropriate styling
            if (this.pageSelectionView) {
                pageCountElement.classList.add("page-cont-active");
            } else {
                pageCountElement.classList.remove("page-cont-active");
            }
        },


        /**
         * Emitting an interface with callable methods from outside
         */
        emitInterface() {
            this.$emit("interface", {
                setPage: (index) => this.setPage(index),
                setPageSize: (size) => {
                    this.itemsPerPage = size 
                }
            });
        },
    },

    mounted() {
        this.$emit("update:modelValue", this.getPaginatedItems())
        
        // Emits on mount
        this.emitInterface();
    },
}
</script>


<style scoped>
    .page-btn-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: right;
        margin: 0 0 1rem 0;
        padding-right: 0.7rem;
    }

    .page-btn {
        width: 2.2rem;
        height: 2.2rem;
        font-size: larger;
        border-radius: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.4rem;
        background-color: rgb(241, 241, 241);
        border: 1px solid rgb(222, 222, 222);
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    }

    .selected-page-btn {
        color: white;
        border: none;
        background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
    }

    .page-btn:hover {
        cursor: pointer;
    }

    .back-page,
    .next-page {
        font-size: larger;
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        margin: 0.1rem 0 0.1rem 0.4rem;
    }

    .inactive {
        background-color: rgb(235 235 235);
        border: 1px solid rgb(236 235 235);
        color: #b2b5bc;
    }

    .inactive:hover {
        cursor: default;
    }
</style>