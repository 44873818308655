<template>
  <div class="p-3 mb-4 bg-white card border-radius-xl js-active" data-animation="FadeIn">
    <div class="save-confirmation card" v-show="showSavePopup && !savePopupCancel" id="savePopup">
      <div class="buttons">
        <h6 style="text-align: center">Saving will mandate this action</h6>
      </div>
      <div class="buttons">
        <button class="button-5" @click="saveMan()">Submit</button>
        <button class="button-5" @click="cancelPopup()">Cancel</button>
      </div>
    </div>
    <form @submit.prevent="">
      <div class="text-center row">
        <div class="mx-auto col-12">
          <h5 class="man-title">
            {{ getCustomer.fullName }}, UC Mandation Form
          </h5>
          <p class="man-sub-title">
            The following information will be used to fill the mandatory
            activity notification for {{ getActionToMandate.subject }}.
          </p>
        </div>
      </div>
      <div class="multisteps-form__content">
        <div class="mt-3 row justify-content-around">
          <div class="mt-4 col-12 col-sm-5 mt-sm-0 text-start">
            <label class="mt-4 man-text">Title</label>
            <template v-if="salutation">
              <input 
                type="text" 
                style="-webkit-appearance: auto" 
                id="choices-title" 
                class="w-50 form-control"
                name="choices-title" 
                v-model="salutation" 
                :disabled="customerData.hasSalutation" 
                required />
            </template>
            <template v-else>
              <select 
                style="-webkit-appearance: auto" 
                id="choices-title" 
                class="w-50 form-control" 
                name="choices-title"
                v-model="salutation" 
                required>
                <option value="" disabled selected>Select title...</option>
                <option v-for="(salutation, index) in SALUTATIONS" :key="index">
                  {{ salutation }}
                </option>
                <option>Prefer not to say/Unspecified</option>
              </select>
            </template>

            <label class="mt-4 man-text">Activity Provider Contact</label>
            <select 
              style="-webkit-appearance: auto" 
              class="w-50 form-control" 
              placeholder="Select advisor..."
              v-model="selectedAdvisorId" 
              @change="setAdvisorDetails($event.target.value); onFieldChanged()" 
              required>
              <option value="" disabled selected>Select advisor...</option>
              <option :value="advisor.contactId" v-for="advisor in filteredAdvisors" :key="advisor.contactId">
                {{ getContactDisplay(advisor) }}
              </option>
            </select>
          </div>
          <div class="mt-4 col-12 col-sm-5 mt-sm-0 text-start">
            <label class="mt-4 man-text">You will be required to show evidence that you have completed the
              mandatory activity by providing...</label>
            <textarea class="w-100 explain-evidence" placeholder="... enter required evidence here" v-model="text"
              rows="4" cols="50" required></textarea>
            <div class="rad-container-condition">
              <label class="pt-2 man-text">MAN will be issued:</label>
              <div class="rad-container">
                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    ref="f2f"
                    name="method"
                    value="F2f"
                    id="F2f"
                    v-model="isF2f"
                    @change="updateIsValid"
                    required
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text" for="F2F">F2F</div>
                </label>
                <label class="rad-label">
                  <input
                    class="rad-input"
                    ref="by-post"
                    type="radio"
                    name="method"
                    value="byPost"
                    id="byPost"
                    v-model="isByPost"
                    @change="updateIsValid"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">By Post</div>
                </label>
              </div>
              <p v-if="!isF2f && !isByPost && !isValid" style="color: red;">Please select a delivery method.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 button-row d-flex" style="justify-content: flex-end">
        <button class="mb-0 btn bg-gradient-dark ms-4 js-btn-next" type="button" title="Cancel" @click="closeForm()">
          Cancel
        </button>
        <button
          class="mb-0 btn bg-gradient-dark ms-4 js-btn-next"
          role="button"
          title="Proceed to Preview"
          @click="goToPreview()"
          :disabled="!isValid"
        >
          Proceed to Preview
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useCommonStore } from "@/store/commonStore";
import { mapState, mapActions } from "pinia";

export default {
  name: "UCMANForm",

  data() {
    return {
      COMPLIANCE_CONDITION_CONTACT_US: "contact us to arrange an appointment and attend the rearranged appointment.",
      COMPLIANCE_CONDITION_UNDERTAKE_TO: "undertake to",
      COMPLIANCE_CONDITION_SUPPLY_WITH: "supply us with",
      COMPLIANCE_CONDITION_AGREE_APPOINTMENT: "agree an appointment date and attend the appointment with",
      selectedAdvisorId: null,
      SALUTATIONS: ["Mr.", "Mrs.", "Miss.", "Ms.", "Mx.", "Dr."],
      customerData: {
        hasSalutation: false,
        location: "",
        activityProvider: this.activityProvider,
        activityContactName: "",
        ActivityContactNumber: this.cscNumber,
        ParticipantPhoneNumber: "",
      },
      showSavePopup: false,
      isF2f: false,
      isByPost: false,
      validationErrors: [],
      text: "N/A",
      option: "contact us to arrange an appointment and attend the rearranged appointment.",
      undertakeCondition: "",
      agreeCondition: "",
      supplyCondition: "",
      method: "",
      inputValue: "",
      salutation: "",
      longDate: "",
      isValid: false,
    };
  },

  watch: {
    text() {
      this.onFieldChanged();
    },
    salutation() {
      this.onFieldChanged();
    },
    isByPost() {
      this.onFieldChanged();
      this.updateIsValid();
    },
    isF2f() {
      this.onFieldChanged();
      this.updateIsValid();
    },
  },

  computed: {
    ...mapState(useCommonStore, [
      "getCustomer",
      "getAllAdvisors",
      "setUcMandationFormVisability",
      "getActionToMandate",
      "getDueDate",
      "getIsEditingManForm",
      "getUc603FormData",
      "getUcManDirectFormData",
      "getCurrentManFormId",
    ]),

    filteredAdvisors() {
      let advisors = this.getAllAdvisors.filter((a) => {
        return this.getContactDisplay(a) !== "";
      });

      return advisors.sort(this.contactSort);
    },

    complianceInput() {
      if (this.option === this.COMPLIANCE_CONDITION_UNDERTAKE_TO)
        return this.undertake;
      if (this.option === this.COMPLIANCE_CONDITION_SUPPLY_WITH)
        return this.supply;
      if (this.option === this.COMPLIANCE_CONDITION_AGREE_APPOINTMENT)
        return this.agree;
      return "";
    },

    currentDate() {
      const date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      const shortDate = `${day}-${month}-${year}`;
      return shortDate;
    },

    formAsDTO() {
      return {
        owningUser: this.selectedAdvisorId == null
          ? this.loggedInAdvisor == null
            ? this.getCustomer.advisor.id
            : this.loggedInAdvisor.id
          : this.selectedAdvisorId,
        mandatableType: "Action",
        includeMap: this.includeMap,
        providerName: this.customerData.activityContactName,
        providerPhone: this.cscNumber,
        locationOverride: "no location",
        startTimeOverride: "0:0:0.0000000",
        isPhoneAppointment: true,
        complianceCondition: this.option,
        complianceDetails: [{ name: "none", value: this.inputValue }],
        isIssuedByPost: this.isByPost,
        isIssuedFaceToFace: this.isF2f,
        isJobSeeker: false,
        requiredEvidence: this.text,
        addressLine1: this.getCustomer.addressLine1,
        addressLine2: this.getCustomer.addressLine2,
        addressLine3: this.getCustomer.addressLine3,
        city: this.getCustomer.city,
        stateOrProvince: this.getCustomer.stateOrProvince,
        county: this.getCustomer.county,
        country: this.getCustomer.country,
        postalCode: this.getCustomer.postalCode,
      };
    },
  },

  methods: {
    ...mapActions(useCommonStore, [
      "GetAllAdvisors",
      "PostMandationForm",
      "setPreviewData",
      "setManFormData",
      "setUcManDirectFormData",
      "setIsEditingManForm",
      "setCurrentManFormId",
      "resetManFormData",
    ]),

    updateIsValid() {
      this.isValid = this.validateForm() && (this.isF2f || this.isByPost);
    },

    goToPreview() {
      if (!this.validateForm()) {
        return;
      }
      this.updateChoices();
      this.$parent.nextStep();
    },

    updateChoices() {
      this.selectedComplianceText();
      this.longDate = this.formatDate(new Date(this.getDueDate));
      const choices = {
        inputValue: this.inputValue,
        Title: this.salutation,
        date: this.currentDate,
        Duedate: this.getDueDate,
        Evidence: this.text,
        ComplianceCondition: this.option + " " + (this.inputValue != undefined ? this.inputValue : ""),
        activityContactName: this.getAdvisorContactName(this.selectedAdvisorId),
        isIssuedFaceToFace: this.isF2f,
        isIssuedByPost: this.isByPost,
        isGroupAppointment: this.isGroupAppointment(),
      };
      const formData = {
        inputValue: this.inputValue,
        salutation: this.salutation,
        currentDate: this.currentDate,
        longDate: this.longDate,
        text: this.text,
        option: this.option,
        selectedAdvisorId: this.selectedAdvisorId,
        isF2f: this.isF2f,
        isByPost: this.isByPost,
        undertakeCondition: this.undertakeCondition,
        supplyCondition: this.supplyCondition,
        agreeCondition: this.agreeCondition,
      };

      this.setUcManDirectFormData(formData);
      this.setManFormData(choices);
    },

    setValuesToEdit() {
      this.inputValue = this.getUcManDirectFormData.inputValue;
      this.salutation = this.getUcManDirectFormData.salutation;
      this.currentDate = this.getUcManDirectFormData.currentDate;
      this.longDate = this.getUcManDirectFormData.longDate;
      this.text = this.getUcManDirectFormData.text;
      this.option = this.getUcManDirectFormData.option;
      this.selectedAdvisorId = this.getUcManDirectFormData.selectedAdvisorId;
      this.isF2f = this.getUcManDirectFormData.isF2f;
      this.isByPost = this.getUcManDirectFormData.isByPost;
      this.supplyCondition = this.getUcManDirectFormData.supplyCondition;
      this.undertakeCondition = this.getUcManDirectFormData.undertakeCondition;
      this.agreeCondition = this.getUcManDirectFormData.agreeCondition;
    },

    selectedComplianceText() {
      if (this.option === this.COMPLIANCE_CONDITION_SUPPLY_WITH) {
        this.inputValue = this.supplyCondition;
      }
      if (this.option === this.COMPLIANCE_CONDITION_AGREE_APPOINTMENT) {
        this.inputValue = this.agreeCondition;
      }
      if (this.option === this.COMPLIANCE_CONDITION_UNDERTAKE_TO) {
        this.inputValue = this.undertakeCondition;
      }
    },

    contactSort(a, b) {
      let aDisplay = this.getContactDisplay(a).toLowerCase();
      let bDisplay = this.getContactDisplay(b).toLowerCase();

      if (aDisplay == bDisplay) {
        return 0;
      }

      return aDisplay < bDisplay ? -1 : 1;
    },

    getContactDisplay(contact) {
      let firstName = contact.firstName != null ? contact.firstName.trim() : "";
      let lastName = contact.lastName != null ? contact.lastName.trim() : "";
      let fullName = contact.fullName != null ? contact.fullName.trim() : "";
      if (fullName == null || fullName === "") {
        fullName = firstName + " " + lastName;
      }
      return fullName.trim();
    },

    getAdvisorContactName(id) {
      var contact = this.getAllAdvisors.find((c) => c.contactId == id);
      if (contact == undefined) {
        console.error("EA could not be found.");
        return;
      }
      return this.getContactDisplay(contact);
    },

    setAdvisorDetails(id) {
      var contact = this.getAllAdvisors.find((c) => c.contactId == id);
      if (contact == undefined) {
        console.error("EA could not be found.");
        return;
      }
      this.customerData.activityContactName = this.getContactDisplay(contact);
      this.selectedAdvisorId = id;
    },

    mandationSaveConfirmation() {
      if (!this.validateForm()) {
        return;
      }
      this.showSavePopup = true;
    },

    cancelPopup() {
      this.showSavePopup = false;
    },

    clearForm() {
      this.salutation = "";
      this.selectedAdvisorId = null;
      this.isF2f = true;
      this.isByPost = false;
      this.validationErrors = [];
      this.option = "contact us to arrange an appointment and attend the rearranged appointment.";
      this.undertakeCondition = "";
      this.agreeCondition = "";
      this.supplyCondition = "";
      this.method = "";
      this.inputValue = "";
      this.option1 = false;
      this.longDate = "";
      this.text = "N/A";
    },

    closeForm() {
      this.clearForm();
      this.updateChoices();
      this.setUcMandationFormVisability(false);
    },

    formatDate(date) {
      const options = { day: "numeric", month: "long", year: "numeric" };
      const formattedDate = date.toLocaleDateString("en-GB", options);
      const day = formattedDate.split(" ")[0];
      const month = formattedDate.split(" ")[1];
      const year = formattedDate.split(" ")[2];
      const suffix = ["th", "st", "nd", "rd"][
        day % 10 > 3 ? 0 : ((day - (day % 10) !== 10) * day) % 10
      ];
      return `${day}${suffix} ${month} ${year}`;
    },

    validateString(value) {
      try {
        var str = value.toString();
        return str != "" && str != null;
      } catch (err) {
        return false;
      }
    },

    validateForm() {
      this.validationErrors = {};

      let activityContactName = this.validateString(this.customerData.activityContactName);
      let activityContactId = this.validateString(this.selectedAdvisorId);
      if (!activityContactName || !activityContactId) {
        this.validationErrors["activity.provider-contact"] = "Required";
      }

      let salutation = this.validateString(this.salutation);
      if (!salutation) {
        this.validationErrors["form.salutation"] = "Required";
      }

      let activityParticipationEvidence = this.validateString(this.text);
      if (!activityParticipationEvidence) {
        this.validationErrors["activity.participation-evidence"] = "Required";
      }

      let complianceMethod = this.validateString(this.option);
      if (!complianceMethod) {
        this.validationErrors["compliance.method"] = "Required";
      }

      if (this.option != this.COMPLIANCE_CONDITION_CONTACT_US) {
        let activityComplianceDetails =
          this.validateString(this.undertakeCondition) ||
          this.validateString(this.supplyCondition) ||
          this.validateString(this.agreeCondition);
        if (!activityComplianceDetails) {
          this.validationErrors["compliance.details"] = "Required";
        }
      }

      let method = this.isByPost || this.isF2f;
      if (!method) {
        this.validationErrors["form.method"] = "Required";
      }

      let issueMethod = this.isByPost || this.isF2f;
      if (!issueMethod) {
        this.validationErrors["form.issue-method"] = "Required";
      }

      return Object.keys(this.validationErrors).length == 0;
    },

    setup() {
      if (this.salutation == null || this.salutation == "" && this.getCustomer.salutation != null && this.getCustomer.salutation != "") {
        let salutation = this.getCustomer.salutation;
        if (salutation[salutation.length - 1] != ".") {
          salutation += ".";
        }
        if (this.SALUTATIONS.includes(salutation)) {
          this.customerData.hasSalutation = true;
          this.salutation = salutation;
        }
      }
    },

    isGroupAppointment() {
      return this.getActionToMandate.classification.ItemTypeCode === "GroupAppointment";
    },

    onFieldChanged() {
      this.updateChoices();
      const valid = this.validateForm();

      if (this.$emit) {
        this.$emit("validation-changed", valid);
      }
    },
  },

  mounted() {
    if (this.getIsEditingManForm && this.getActionToMandate.id === this.getCurrentManFormId) {
      this.setValuesToEdit();
      this.setAdvisorDetails(this.selectedAdvisorId);
    } else {
      this.clearForm();
      this.resetManFormData();
    }
    this.setCurrentManFormId(this.getActionToMandate.id);
    this.setup();
    this.isValid = this.validateForm();
  },
};
</script>


<style scoped>
.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #999;
  animation: spin 1s infinite linear;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rad-container {
  display: flex;
  flex-direction: row;
}

.rad-label {
  display: flex;
  align-items: center;

  border-radius: 50px;
  padding: 6px 8px;
  margin: 5px 0;

  cursor: pointer;
  transition: 0.3s;
}

.rad-label:hover,
.rad-label:focus-within {
  background: hsla(0, 0%, 80%, 0.14);
}

.rad-input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 20px;
  height: 20px;
  border-radius: 100px;

  background-color: #344767;
  position: relative;
}

.rad-design::before {
  content: "";

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: 0.3s;
}

.rad-input:checked+.rad-design::before {
  transform: scale(0);
}

.rad-text {
  color: #595959;
  margin-left: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 900;
  transition: 0.3s;
}

.rad-input:checked~.rad-text {
  color: #344767;
}

.rad-label-condition {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 5px 0;

  cursor: pointer;
  transition: 0.3s;
}

.rad-label-condition:hover,
.rad-label-condition:focus-within {
  background: hsla(0, 0%, 80%, 0.14);
}

.rad-text-condition {
  color: #595959;
  margin-left: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 900;
  transition: 0.3s;
}

.rad-input:checked~.rad-text-condition {
  color: #344767;
}

.rad-input-text-inline {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.rad-container-condition {
  display: flex;
  flex-direction: column;
}

.man-title {
  color: #595959;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: inherit;
}

.man-sub-title {
  color: #595959;
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;
}

.man-text {
  font-size: 0.9rem;
  font-weight: 700;
  color: #595959;
}

.bolder {
  color: #344767;
  font-weight: 900;
}

form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #595959;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d6da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

.form-control:focus {
  color: #595959;
  background-color: #fff;
  border: 2px solid #344767 !important;
  outline: 0;
  box-shadow: 0 3px 9px rgb(50 50 9 / 0%), 3px 4px 8px rgb(94 114 228 / 10%);
}

.explain-evidence:focus {
  border: 2px solid #344767 !important;
  border-color: transparent;
}

.save-confirmation {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  padding: 12px;
  border: 1px solid #2dce89;
  border-radius: 5px;
  background-color: rgba(#fff, 0.4);
  z-index: 20;
  opacity: 1;
  transition: 0.6s;
}

/* .checkbox-input {
  width: 40%;
} */
</style>
