<template>
  <div class="email-card-background card">
    <div class="email-card-body card">
      <div class="email-card-header">
        <h4>Email</h4>
        <svg
          @click="$emit('closeEmailModal')"
          style="width: 1.2rem; cursor: pointer"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
          ></path>
        </svg>
      </div>
      <div @submit="sendEmail" class="add-form">
        <div class="sms-to-div">
          <div>
            <span for="to">To:</span>
          </div>
          <input v-model="to" type="email" id="to" name="to" required />
          <div class="error" v-if="errors.to">{{ errors.to }}</div>
          <div>
            <span for="subject">Subject:</span>
          </div>
          <input
            v-model="subject"
            type="text"
            id="subject"
            name="subject"
            required
          />
          <div class="error" v-if="errors.subject">{{ errors.subject }}</div>
        </div>
        <div style="max-height: 70%">
          <div style="display: flex; flex-direction: column">
            Body:
            <QuillEditor
              class="quill-editor"
              style="
                border: 1px solid #000;
                padding: 5px;
                display: flex;
                flex-direction: column;
                max-height: 45vh;overflow: auto;
              "  
              theme="snow" toolbar="essential"          
              v-model:content="body"
              contentType="html"
            >
            </QuillEditor>
          </div>
          <div>
            <div class="error" v-if="errors.body">{{ errors.body }}</div>
            <div
              style="
                display: flex;
                justify-content: space-around;
                margin-top: 0.6rem;
              "
            >
              <button @click="$emit('closeEmailModal')" class="btn email-btn">
                Cancel
              </button>
              <button
                type="submit"
                value="Submit"
                class="btn email-btn"
                @click="sendEmail()"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { useAdvisorDashboardStore } from "@/store/advisorDashboard";
import { useCommonStore } from "@/store/commonStore";
import { loggedIn } from "@/router/Guard.ts";
import axios from "axios";
import { QuillEditor } from '@vueup/vue-quill';
import "quill/dist/quill.snow.css";

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL;
const endpoint = process.env.VUE_APP_TWILIO_SMS_EMAIL;
const subscriptionKey = process.env.VUE_APP_API_GATEWAY_SUBSCRIPTION_KEY

export default {
  name: "EmailComponent",
  components: {
    QuillEditor,
  },
  data() {
    return {
      to: "",
      id: "",
      from: "",
      subject: "",
      body: "",
      requestedBy: "",
      sentAt: new Date(),
      reason: "",
      mimeType: "text/html",
      email: "True",
      errors: {},
    };
  },
  props: {
    emailItem: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(useAdvisorDashboardStore, ["getMemoView"]),
    ...mapState(useCommonStore, [
      "getCustomerId",
      "getCustomer",
      "getActionPlan",
    ]),
  },
  watch:{
  },
  methods: {
    ...mapActions(useAdvisorDashboardStore, []),
    ...mapActions(useCommonStore, []),

    closeEmailModal() {
      this.$emit("closeEmailModal");
    },

    async sendEmail() {
      this.errors = {};

      if (!this.to) {
        this.errors.to = "Customer Email is required";
      }
      if (!this.subject) {
        this.errors.subject = "Subject Header is required";
      }
      if (!this.body) {
        this.errors.body = "Body Content is required";
      }
      if (Object.keys(this.errors).length === 0) {
        this.closeEmailModal();
        try {
          var instance = axios.create();

          // Remove bearer authorization as API gateway only allows one type of auth
          // We are using Ocp-Apim-Subscription-Key here
          delete instance.defaults.headers.common['Authorization'];
          
          const resp = await instance({
            method: "POST",
            url: baseUrl + endpoint,
            responseType: "json",
            headers: {
              "Ocp-Apim-Subscription-Key": subscriptionKey,
            },
            data: {
              id: this.id,
              to: this.to,
              from: this.from,
              subject: this.subject,
              body: this.body,
              requestedBy: this.requestedBy,
              sentAt: this.sentAt,
              reason: this.reason,
              mimeType: this.mimeType,
              email: this.email,
            },
          });
          return resp;
        } catch (error) {
          return error;
        }
      }
    },
  },
  mounted() {
    let content = "";
    content += `Dear ${this.getCustomer.firstName.charAt(0).toUpperCase()}`;
    content += `${this.getCustomer.firstName.slice(1).toLowerCase()},\n`;
    content += `Here are the details of and any links to your action/s.\n`;
    content += this.emailItem;
    content += `\n\nThanks, `;
    content += loggedIn.name;
    this.body = content;
    this.to += `${this.getCustomer.email}`;
    this.subject += `Notification Of New Action/s`;
    this.requestedBy += `${this.getCustomer.advisor.id}`;
    this.id += `${this.getCustomerId}`;
  },
};
</script>
<style scoped>
.email-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  border-bottom: 1px solid #ebebeb;
  padding: 0.4rem 1.4rem;
}

.email-card-body {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  min-height: 50%;
  min-width: 35%;
  max-width: 550px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.email-card-background {
  position: fixed;
  z-index: 10;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sms-to-div {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 500px;
}

.error {
  color: red;
}

@media only screen and (min-height: 600px) {
  .quill-editor {
    min-width: 500px;
    max-width: 500px;
    border: 1px solid #000; /* Set border color */
    padding: 5px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-height: 600px) {
  .quill-editor {
    min-height: 150px;
    max-height: 150px;
    overflow-y: auto;
  }
}
</style>
