<template>
  <div class="job-interests-cont card">
    <div class="job-interests-sect">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
          d="M320 336c0 8.844-7.156 16-16 16h-96C199.2 352 192 344.8 192 336V288H0v144C0 457.6 22.41 480 48 480h416c25.59 0 48-22.41 48-48V288h-192V336zM464 96H384V48C384 22.41 361.6 0 336 0h-160C150.4 0 128 22.41 128 48V96H48C22.41 96 0 118.4 0 144V256h512V144C512 118.4 489.6 96 464 96zM336 96h-160V48h160V96z" />
      </svg>
      <div>
        <b>Job Goals</b>
      </div>
    </div>
    <div style="padding-left: 1rem" class="w-100">
      <div>
        <div class="job-goals-container">
          <div class="job-goals-option" v-for="definition in definitions" :key="definition">
            <input type="checkbox" @change="toggleOption($event, definition.name)" />
            <p>{{ definition.name }}</p>
          </div>
          <div class="job-goals-option">
            <input type="checkbox" @change="toggleOtherInterests()" />
            <p>Other</p>
          </div>
        </div>
      </div>

      <div style="display: flex; padding-bottom: 1rem">
        <div v-show="showOtherOptions" style="display: flex; flex-direction: column; flex: auto">
          <p>Please specify other interests.</p>
          <input class="other-interests" v-model="otherInterests1" type="text" placeholder="Required" />
          <input class="other-interests" v-model="otherInterests2" type="text" placeholder="Optional" />
          <input class="other-interests" v-model="otherInterests3" type="text" placeholder="Optional" />
        </div>
      </div>
      <div>
        <button class="update-button" @click="validateJobGoals()">
          Update
        </button>
      </div>
      <p v-if="showWarningMessage">
        Please select exactly 3 of the above options
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useCommonStore } from "@/store/commonStore";

export default {
  data() {
    return {
      otherInterests1: "",
      otherInterests2: "",
      otherInterests3: "",
      definitions: [],
      selectedOptions: [],
      showWarningMessage: false,
      showOtherOptions: false,
    };
  },
  computed: {
    ...mapState(useCommonStore, ["sectorDefinitions", "getCustomer"]),
    getJobGoalsToPut() {
      const jobGoals = [];
      if (this.otherInterests1) {
        jobGoals.push({ isCustom: true, name: this.otherInterests1 });
      }
      if (this.otherInterests2) {
        jobGoals.push({ isCustom: true, name: this.otherInterests2 });
      }
      if (this.otherInterests3) {
        jobGoals.push({ isCustom: true, name: this.otherInterests3 });
      }
      return jobGoals;
    },
  },
  methods: {
    ...mapActions(useCommonStore, ["GetSectorDefinitions", "postJobGoals"]),
    validateJobGoals() {
      const selectedOptions = this.selectedOptions.concat(this.getJobGoalsToPut);
      if (selectedOptions.length !== 3) {
        // Handle validation error here.
        this.showWarningMessage = true;
        return;
      }
      this.showWarningMessage = false;
      this.postJobGoals(selectedOptions).then(() =>
        this.$emit("hideEditJobGoals")
      );
    },
    toggleOption(event, option) {
      if (event.target.checked) {
        this.selectedOptions.push({ name: option, isCustom: false });
      } else {
        this.selectedOptions = this.selectedOptions.filter(
          (o) => o.name !== option
        );
      }
    },
    toggleOtherInterests() {
      this.showOtherOptions = !this.showOtherOptions;
    },
  },
  async mounted() {
    await this.GetSectorDefinitions();
    this.definitions = this.sectorDefinitions;
  },
};
</script>

<style scoped>
.job-goals-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}

.job-goals-container p {
  margin-bottom: 0px;
  margin-left: 1rem;
}

.job-goals-option {
  display: flex;
  padding-bottom: 4px;
}

.other-interests {
  margin-bottom: 8px;
  width: 70%;
}

.update-button {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: fit-content;
  white-space: nowrap;
}

.update-button:hover {
  background-color: #2c974b;
}
</style>
