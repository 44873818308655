import Swal, { SweetAlertIcon } from "sweetalert2";

interface AlertOptions {
    title?: string,
    text?: string
}

interface LoadAlertOptions extends AlertOptions { }

export function showLoadingAlert(work: Promise<void>, options: LoadAlertOptions) : Promise<void> {
    const title = options.title ?? "Loading"
    const text = options.text ?? "Please wait..."

    const promise = new Promise<void>((resolve, reject) => {
        const instance = Swal.mixin({
            title: title,
            html: text + '<div class="loader-container"><div class="loader"></div></div>',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                console.debug("Doing work")
                work.then(() => {
                    instance.close()
                    resolve()
                }).catch((err) => {
                    instance.close()
                    reject(err)
                })
            }
          })

        instance.fire()
    });

    return promise;
}

export function showErrorAlert(options: AlertOptions) {
    const promise = new Promise<void>((resolve, reject) => {
        const instance = createAlert("error", options)
        instance.fire().then(() => resolve()).catch((err) => reject(err))
    })

    return promise
}

export function showSuccessAlert(options: AlertOptions) {
    const instance = createAlert("success", options)
    return instance.fire()
}

export function showWarningAlert(options: AlertOptions) {
    const instance = createAlert("warning", options)
    return instance.fire()
}

export function showInfoAlert(options: AlertOptions) {
    const instance = createAlert("info", options)
   return instance.fire()
}

export function showConfirmationAlert(options: AlertOptions) {
    return Swal.mixin({        
        title: options.title,
        text: options.text,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        reverseButtons: true,
        customClass: {
            confirmButton: "btn bg-gradient-dark",
            cancelButton: "btn bg-gradient-dark btn-1",
        },
        buttonsStyling: false
    }).fire()
}

function createAlert(icon: SweetAlertIcon, options: AlertOptions) {
    return Swal.mixin({
        title: options.title,
        text: options.text,
        icon: icon,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn bg-gradient-dark",
        },
        buttonsStyling: false,
      }); 
}