<script>
import { msalInstance } from "@/authConfig";

export default {
  name: "sessionTimeout",

  data: function () {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],

      logoutTimer: null,
    };
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, () => this.resetTimer());
    }, this);

    this.setTimers();
  },

  unmounted() {
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimer);
    });

    this.resetTimer();
  },

  methods: {
    setTimers: function () {
      this.logoutTimer = setTimeout(() => this.logOut(), 900 * 1000); // 15 minutes
    },

    resetTimer: function () {
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },

    async logOut() {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        await msalInstance.logoutRedirect({
          account: accounts[0],
          postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
        });
        location.reload();
      }
    },
  },
};
</script>
