<template>
  <div class="py-4 container-fluid mandation-modal">
    <div class="row" style="min-width: 85%">
      <div class="text-center col-12">
        <div class="multisteps-form">
          <!--progress bar-->
          <div class="row mt-4">
            <div class="mx-auto my-2 col-12 col-lg-12">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn mb-4"
                  type="button"
                  title="JSA M.A.N Form"
                  @click="
                    () => {
                      activeStep > 0 ? setIsEditingManForm(true) : '';
                      activeStep = 0;
                    }
                  "
                  :class="activeStep >= 0 ? activeClass : ''"
                >
                  <p>JSA MAN Form</p>
                </button>
                <button
                  class="multisteps-form__progress-btn mb-4"
                  type="button"
                  title="Preview and Download"
                  :disabled="!this.page1Validated"
                  @click="activeStep = 1"
                  :class="activeStep >= 1 ? activeClass : ''"
                >
                  <p>Preview and Save</p>
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-12 col-lg-12">
              <form class="multisteps-form__form">
                <!--single form panel-->
                <JsaManForm
                  :class="activeClass"
                  @validation-changed="this.onPage1ValidationChange"
                  @closeMandationWizard="$emit('closeMandationWizard')"
                  v-if="activeStep === 0"
                />
                <!--single form panel-->
                <JsaPreviewDownload
                  :class="activeClass"
                  @closeMandationWizard="$emit('closeMandationWizard')"
                  v-if="activeStep === 1"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsaManForm from "./JSA_WizardComponents/JSA_Man_Form.vue";
import JsaPreviewDownload from "./JSA_WizardComponents/JSA_PreviewDownload.vue";
import { useCommonStore } from "@/store/commonStore";
import { mapActions } from "pinia";
export default {
  name: "JSA-Mandation_Wizard",
  components: { JsaManForm, JsaPreviewDownload },
  data() {
    return {
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,

      page1Validated: false
    };
  },
  methods: {
    ...mapActions(useCommonStore, ["setIsEditingManForm"]),
    onPage1ValidationChange(isValid) {
      this.page1Validated = isValid
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
  },
};
</script>

<style scoped>
.mandation-modal {
  position: fixed;
  min-width: 75%;
  z-index: 10;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  height: fit-content;
  width: fit-content;
  max-width: 1050px;
  background-color: rgb(111, 150, 0);
  overflow-y: hidden;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: auto;
}
::-webkit-scrollbar-track-piece {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.174);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}
button:disabled.multisteps-form__progress-btn.mb-4 {
  color: rgb(165, 165, 165);
  -webkit-text-fill-color: #dee2e6;
  cursor: default;
}
button:disabled.multisteps-form__progress-btn.mb-4:before {
  background-color: rgb(165, 165, 165);
}
</style>
