<template>
    <div :id="id">
        <div hidden><button class="clear-values-btn" @click="clearValues()"></button></div>
        <div style="margin-bottom:0.5rem;width:25rem;">Please provide a reason why you are changing the due date of this action</div>
        <div><b>Reason for change:</b></div>
        <textarea v-model="reason" style="width:25rem;height:7rem;"></textarea>
        <div class="text-danger" v-show="reasonError">{{reasonError}}</div>
        <div>
            <button class="btn" disabled v-show="reason == null">Save</button>
            <button class="btn" @click="saveChanges()" v-show="reason != null">Save</button>
        </div>
    </div>
</template>
<script>
    import { useAdvisorDashboardStore } from '@/store/advisorDashboard';
    import { useCommonStore } from '@/store/commonStore';
    import { mapActions, mapState } from 'pinia';
    
    export default {
        name:"update-assessment-item-due",
        data(){
            return{
                reason: null,
                reasonError: null
            }
        },
        props:{
            id:{
                type: String,
                default: "updt-assessment-item-due"
            }
        },
        computed:{
            ...mapState(useAdvisorDashboardStore,["getCurrentActionToUpdate"]),
            ...mapState(useCommonStore,["getUpdateAssessmentItemDueCallback"])
        },
        methods:{
            ...mapActions(useCommonStore,["UpdateAssessmentItemDueDate", "getUTCDateString"]),
            ...mapActions(useAdvisorDashboardStore,["toggleModal"]),
             getDateInputString(date){
                return [date.getFullYear(),
                        date.getMonth()+1 < 10 
                        ? '0' + (date.getMonth()+1).toString() 
                        : date.getMonth()+1,
                        date.getDate() < 10 
                        ? '0' + (date.getDate()).toString() 
                        :date.getDate()].join('-');
            },
            getDateFormatString(date){
                return [[date.getFullYear(),
                        date.getMonth()+1 < 10 
                        ? '0' + (date.getMonth()+1).toString() 
                        : date.getMonth()+1,
                        date.getDate() < 10 
                        ? '0' + (date.getDate()).toString() 
                        :date.getDate()].join('-'),
                        [date.getHours()+1 < 10 
                        ? '0' + (date.getHours()+1).toString() 
                        : date.getHours()+1,
                        date.getMinutes()+1 < 10 
                        ? '0' + (date.getMinutes()+1).toString() 
                        : date.getMinutes()+1].join(':')].join('T');
            },
            saveChanges(){ 
                if(this.reason == null || this.reason == ''){
                    this.reasonError = "Please provide a reason for change";
                    return;
                }
                this.getUpdateAssessmentItemDueCallback(this.updateDueDate)           
            },
            async updateDueDate(newDate){
                await this.UpdateAssessmentItemDueDate(this.getCurrentActionToUpdate.id, this.reason ,this.getUTCDateString(new Date(newDate)));
                this.reasonError = null;
                this.reason = null;
            },
            clearValues(){
                this.reasonError = null;
                this.reason = null;
            }
        },
        mounted(){
            // document.getElementById("modal-close").addEventListener("click", ()=>{
            //     this.newDate = null;
            //     this.error ='';
            // })
        }
    }
</script>
<style scoped>
    input, textarea{
        border: none;
        border-radius: 0.2rem;
        background-color: rgb(243 243 243);
        box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
        color: #67748e;
        font-size: medium;
        padding: 0.2rem 0.5rem;
        margin: 0.8rem 0;
    }
</style>