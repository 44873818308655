<template>
  <div style="padding: 0.5rem 1.5rem;">
    <navBarVue style="background-color: #6F9600;" />
    <div class="all-assessment-results-main card" style="margin: 1rem 0 0.75rem 0;">
      <div class="leave-bar">
        <svg @click="navBack()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3 54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
        </svg>
      </div>
      <div class="all-assessment-results-cont">
        <div class="themed-response-header">
          <div class="header-left">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm64 192c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V160zM320 288c17.7 0 32 14.3 32 32v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32z"/>
            </svg>
            <span>Customer Assessments</span>
          </div>
          <select v-model="selectedSubject" @change="filterAssessments" class="subject-filter-dropdown" style="margin-left: auto;">
            <option value="">All Assessments</option>
            <option v-for="subject in uniqueSubjects" :key="subject" :value="subject">{{ subject }}</option>
          </select>
        </div>
        <div class="themed-response-table">
          <div class="assessment-headers">
            <div class="themed-response-th">Assessment Name</div>
            <div class="themed-response-th">Date</div>
            <div class="themed-response-th">URL</div>
          </div>
          <div v-if="paginatedAssessments.length === 0" class="themed-response-table-row">
            <div class="themed-response-td" colspan="3">No assessments found.</div>
          </div>
          <div v-for="(assessment, index) in paginatedAssessments" :key="index" class="themed-response-table-row">
            <div class="themed-response-td"><b>{{ assessment.assessmentSubject }}</b></div>
            <div class="themed-response-td">{{ formatDate(new Date(assessment.createdOn)) }}</div>
            <div class="themed-response-td">
              <button class="url-button" @click="showModal(assessment)">View More</button>
            </div>
          </div>
        </div>
        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">Previous</button>
          <button v-for="page in totalPages" :key="page" @click="goToPage(page)" :class="['pagination-button', { 'active': page === currentPage }]">{{ page }}</button>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">Next</button>
        </div>
      </div>
    </div>
    <FooterVue />
    <SingleAssessmentView
      :isVisible="isModalVisible"
      :selectedAssessment="selectedAssessment"
      @close="closeModal"
    />
  </div>
</template>
<script>
import navBarVue from '@/components/navBar.vue';
import FooterVue from '@/components/Footer.vue';
import SingleAssessmentView from '@/views/SingleAssessmentView.vue';
import { mapActions, mapState } from 'pinia';
import { useCommonStore } from '@/store/commonStore';
import { NavBackwards } from "@/router/index";

const ITEMS_PER_PAGE = 10;

export default {
  name: "all-assessment-result",
  components: {
    navBarVue,
    FooterVue,
    SingleAssessmentView
  },
  data() {
    return {
      currentPage: 1,
      isModalVisible: false,
      selectedAssessment: null,
      selectedSubject: "",
    };
  },
  computed: {
    ...mapState(useCommonStore, ["getCustomer", "getUnfilteredAssessmentResults"]),
    uniqueSubjects() {
      const assessmentResults = this.getUnfilteredAssessmentResults.assessments || [];
      const subjects = new Set(assessmentResults.map(a => a.assessmentSubject));
      return Array.from(subjects);
    },
    filteredAssessments() {
      const assessments = this.getUnfilteredAssessmentResults.assessments || [];
      if (this.selectedSubject) {
        return assessments.filter(a => a.assessmentSubject === this.selectedSubject);
      }
      return assessments;
    },
    sortedAssessments() {
      return [...this.filteredAssessments].sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
    },
    totalPages() {
      return Math.ceil(this.sortedAssessments.length / ITEMS_PER_PAGE);
    },
    paginatedAssessments() {
      const start = (this.currentPage - 1) * ITEMS_PER_PAGE;
      return this.sortedAssessments.slice(start, start + ITEMS_PER_PAGE);
    },
  },
  methods: {
    ...mapActions(useCommonStore, ["GetCustomer", "SetCustomerId", "GetUnfilteredAssessmentResults"]),
    formatDate(date) {
      return [
        date.getDate().toString().padStart(2, '0'),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getFullYear()
      ].join('/');
    },
    navBack() {
      NavBackwards();
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    showModal(assessment) {
      this.selectedAssessment = assessment;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedAssessment = null;
    },
    filterAssessments() {
      this.currentPage = 1;
    }
  },
  mounted() {
    this.SetCustomerId(this.$route.params.id);
    this.GetCustomer();
    this.GetUnfilteredAssessmentResults();
  }
};
</script>


  
  <style scoped>
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .all-assessment-results-main {
    width: 100%;
    background-image: url('../assets/img/wave2.svg');
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    display: flex;
    font-size: 1rem;
  }
  
  .all-assessment-results-cont {
    width: 100%;
    padding: 5%;
    padding-top: calc(5%-1rem);
  }
  
  .themed-response-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.4rem 0.75rem;
    background-color: #212a33;
    color: #fff;
    font-size: 1.4rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .themed-response-header .header-left {
    display: flex;
    align-items: center;
  }
  
  .themed-response-header svg {
    width: 1.4rem;
    margin-right: 0.3rem;
    fill: #fff;
  }
  
  .subject-filter-dropdown {
    margin-left: auto;
    padding: 0.4rem;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .themed-response-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 1px;
    overflow: hidden;
  }
  
  .assessment-headers {
    display: flex;
    background-color: #3b4252;
    font-weight: bold;
    color: white;
  }
  
  .themed-response-th {
    flex: 1;
    padding: 0.4rem 0.75rem;
    border-right: 1px solid #232c36;
  }
  
  .themed-response-th:last-child {
    border-right: none;
  }
  
  .themed-response-table-row {
    display: flex;
  }
  
  .themed-response-td {
    flex: 1;
    padding: 0.4rem 0.75rem;
    border-right: 1px solid #232c36;
    word-wrap: break-word;
    color: #fff;
  }
  
  .themed-response-td:last-child {
    border-right: none;
  }
  
  .themed-response-td:nth-child(even) {
    background-color: #293541;
  }
  
  .themed-response-td:nth-child(odd) {
    background-color: #26313b;
  }
  
  .themed-response-table-row:last-child .themed-response-td {
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
  }
  
  .leave-bar {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 0.3rem 0.3rem 0 0;
    padding-right: 0.6rem;
  }
  
  .leave-bar svg {
    width: 2rem;
    height: 2rem;
    z-index: 1;
    cursor: pointer;
  }
  
  .leave-bar svg:hover {
    fill: #243147;
  }
  
  .url-button {
    background-color: #6F9600;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .url-button:hover {
    background-color: #5c7b00;
  }
  
  .pagination {
    display: flex;
    justify-content: right;
    margin-top: 1rem;
  }
  
  .pagination-button {
    background-color: #6F9600;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .pagination-button.active, .pagination-button:hover {
    background-color: #5c7b00;
  }
  
  .pagination-button:disabled {
    background-color: #9eac72;
    cursor: not-allowed;
  }
  </style>
  