<template>
    <div :id="id" class="overflow-y-scroll-create" style="max-height: 70vh; padding: 1rem">
        <div style="margin-top: 1rem" class="crte-act-datetime">
            <div class="crte-act-section">
                <div><b>Action*:</b></div>
                <label style="width: 22rem" class="crte-act-input label-appointments">{{
                    action
                }}</label>
            </div>
            <div style="margin-bottom: 1rem">
                <div><b>Location*:</b></div>
                <label style="width: 22rem" class="crte-act-input label-appointments">
                    <span>{{ locationInfo }}</span>
                </label>
            </div>
            <div style="margin-bottom: 1rem; display: flex; align-items: flex-start">
                <div style="margin-right: 1rem">
                    <div><b>Start Time*:</b></div>
                    <label type="time" class="crte-act-input label-appointments" style="width: 6rem">
                        {{ startTime }}</label>
                </div>
                <div>
                    <div><b>End Time*:</b></div>
                    <label type="time" class="crte-act-input label-appointments" style="width: 6rem">
                        {{ endTime }}</label>
                </div>
            </div>
            <div style="margin-bottom: 1rem">
                <div><b>Due Date*:</b></div>
                <label type="time" class="crte-act-input label-appointments" style="width: 6rem">
                    {{ dateDue }}</label>
                <div class="text-danger" v-show="errors.dueDate != ''">
                    {{ errors.dueDate }}
                </div>
            </div>
        </div>
        <div class="crte-act-section">
            <div><b>What will I get from doing this?*:</b></div>
            <textarea style="width: 22rem; height: 7.5rem" class="crte-act-input" v-model="newAction.motivation">
            </textarea>
            <div class="text-danger" v-show="errors.motivation != ''">
                {{ errors.motivation }}
            </div>
        </div>

        <button style="margin: 0; margin-right: 0.75rem; background-color: #ededed" class="btn"
            @click="clearValues(), toggleModal()">
            Cancel
        </button>
        <button style="margin: 0; margin-right: 0.75rem; background-color: #ededed" class="btn crte-act-input"
            @click="createAppointment()">
            Save
        </button>
    </div>
</template>
<script>
import { mapState, mapActions } from "pinia";
import { useAdvisorDashboardStore } from "@/store/advisorDashboard";
import { useCommonStore } from "@/store/commonStore";
import { useMainStore } from "../store/mainStore";

export default {
    name: "create-appointment",
    data() {
        return {
            activitySelected: false,
            showActivityTypeDrpDwn: false,
            showStateCodeDrpDwn: false,
            currentSearchValue: "Search...",
            currentSelectedStatus: "Select status...",
            currentlyExtended: [],
            filteredPrompts: [],
            statusCodes: [
                "Recommended",
                "Current",
                "Completed",
                "Archive",
                "Failed to Complete",
            ],
            newAction: {
                actionUrl: "",
                subject: "",
                location: "",
                description: "",
                motivation: 'Placeholder Appointment Details',
                isMandated: false,
                isRequired: false,
                priorityCode: 0,
                progress: 0,
                estimatedEffort: 1,
                statusCode: "",
                stateCode: "Open",
                scheduledStart: "",
                scheduledEnd: "",
                dueDate: "",
                recommendedActionCatalog: "",
                recommendedAction: "",
            },
            errors: {
                start: "",
                end: "",
                subject: "",
                location: "",
                url: "",
                motivation: "",
                description: "",
                effort: "",
            },
        };
    },
    props: {
        id: {
            type: String,
            default: "create-appointment",
        },
    },
    computed: {
        ...mapState(useAdvisorDashboardStore, [
            "getTargetedActivities",
            "getCurrentActionToAddTask",
            "getModalContentData",
        ]),
        ...mapState(useMainStore, ["getIsReadOnlyActionEnabled"]),
        getFilteredPrompts() {
            if (
                this.filteredPrompts.length == 0 &&
                this.currentSearchValue == "Search..."
            ) {
                return this.getRecommendedActionTemplates;
            } else {
                return this.filteredPrompts;
            }
        },
        action() {
            return this.getModalContentData?.subject || "";
        },
        locationInfo() {
            const location = this.getModalContentData?.location || {};
            const address = location.address || {};
            return `${location.name || ""}, ${address.line1 || ""}, ${address.line2 || ""
                }, ${address.postalCode || ""}`;
        },
        startTime() {
            return this.formatTime(this.getModalContentData?.scheduledStart);
        },
        endTime() {
            return this.formatTime(this.getModalContentData?.scheduledEnd);
        },
        dateDue() {
            return this.formatDate(this.getModalContentData?.scheduledStart);
        },
    },
    mounted() { },
    methods: {
        ...mapActions(useCommonStore, [
            "CreateNewAppointment",
            "CreateNewTask",
            "getUTCDateString",
            "addDaysToDate",
        ]),
        ...mapActions(useAdvisorDashboardStore, [
            "toggleModal",
            "setCurrentActionToAddTask",
        ]),
        clearValues() {
            if (
                document.getElementsByClassName("overflow-y-hidden-create").length > 0
            ) {
                document
                    .getElementsByClassName("overflow-y-hidden-create")[0]
                    .classList.add("overflow-y-scroll-create");
                document
                    .getElementsByClassName("overflow-y-hidden-create")[0]
                    .classList.remove("overflow-y-hidden-create");
            }
            document.getElementsByClassName("activity-type-selector")[0].innerHTML =
                "Select a targeted activity...";
            (this.activitySelected = false),
                (this.showActivityTypeDrpDwn = false),
                (this.showStateCodeDrpDwn = false),
                (this.currentSearchValue = "Search..."),
                (this.currentSelectedStatus = "Select status..."),
                (this.currentlyExtended = []),
                (this.filteredPrompts = []),
                (this.statusCodes = [
                    "Recommended",
                    "Current",
                    "Completed",
                    "Archive",
                    "Failed to Complete",
                ]),
                (this.newAction = {
                    actionUrl: "",
                    subject: "",
                    location: "",
                    description: "",
                    motivation: "",
                    isMandated: false,
                    priorityCode: 0,
                    progress: 0,
                    estimatedEffort: 1,
                    statusCode: "",
                    stateCode: "Open",
                    scheduledStart: "",
                    scheduledEnd: "",
                    dueDate: "",
                    recommendedActionCatalog: "",
                    recommendedAction: "",
                }),
                (this.errors = {
                    start: "",
                    end: "",
                    subject: "",
                    location: "",
                    url: "",
                    motivation: "",
                    description: "",
                    effort: "",
                });
            this.setCurrentActionToAddTask(null);
        },
        getDateInputString(date) {
            return [
                date.getFullYear(),
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1).toString()
                    : date.getMonth() + 1,
                date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate(),
            ].join("-");
        },
        formatTime(dateTimeString) {
            if (!dateTimeString) return "";

            const date = new Date(dateTimeString);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}`;

            return formattedTime;
        },
        formatDate(dateTimeString) {
            if (!dateTimeString) return "";

            const date = new Date(dateTimeString);
            const day = date.getDate().toString().padStart(2, "0");
            const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed, so we add 1
            const year = date.getFullYear();

            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate;
        },
        createAppointment() {
            var isValid = true;
            this.errors = {
                start: "",
                end: "",
                subject: "",
                location: "",
                url: "",
                motivation: "",
                description: "",
                effort: "",
            };
            switch (this.currentSelectedStatus) {
                case "Recommended":
                    this.newAction.stateCode = "Available";
                    break;
                case "Current":
                    this.newAction.stateCode = "Open";
                    break;
                case "Completed":
                    this.newAction.stateCode = "Complete";
                    break;
                case "Archive":
                    this.newAction.stateCode = "Deferred";
                    break;
                case "Failed to Complete":
                    this.newAction.stateCode = "Canceled";
                    break;
            }
            if (this.newAction.motivation == "") {
                this.errors.motivation = "*Required";
                isValid = false;
            }
            if (this.newAction.dateDue == "") {
                this.errors.dueDate = "*Required";
                isValid = false;
            }
            if (isValid) {
                if (this.getCurrentActionToAddTask == null) {
                    let payload = {motivation: this.newAction.motivation}
                    this.CreateNewAppointment(this.getModalContentData?.actvityId, true, payload);
                } else {
                    this.CreateNewTask(
                        this.getCurrentActionToAddTask,
                        this.newAction,
                        true
                    );
                }
                this.clearValues();
                this.toggleModal();
            }
        },
    },
};
</script>
<style>
.overflow-y-scroll-create {
    overflow-y: scroll;
}

.overflow-y-hidden-create {
    overflow-y: hidden;
}

.crte-act-section {
    margin-bottom: 1rem;
}

.crte-act-section div {
    font-size: medium;
}

.crte-act-section input,
.crte-act-section textarea,
.crte-act-datetime input {
    border: none;
    border-radius: 0.2rem;
    background-color: rgb(243 243 243);
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    color: #67748e;
    font-size: medium;
    padding: 0.2rem 0.5rem;
}

.crte-act-datetime b {
    font-size: medium;
    margin-right: 0.4rem;
}

.crte-act-datetime input {
    font-size: medium;
}

.label-appointments,
.crte-act-input {
    display: inline-block;
    padding: 0.5em;
    background-color: #f2f2f2;
    border-radius: 4px;
    cursor: text;
    font-size: 14px;
}
</style>
