<template>
  <div id="surveyCreator" />
</template>

<script>
import { SurveyCreator } from "survey-creator-knockout";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { mapActions, mapState } from "pinia";
import { useCommonStore } from "@/store/commonStore";
import router from "@/router/index.ts";

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
};

export default {
  name: "survey-creator",
  computed: {
    ...mapState(useCommonStore, ["getThemedAssessmentJson", "getUnfilteredAssessmentResults", "getActionSubjectById"])
  },
  methods: {
    ...mapActions(useCommonStore, ["GetThemedAssessmentJson", "GetUnfilteredAssessmentResults", "GetActionSubjectById"]),
    extractInsightAndPathway() {
      if (!this.getUnfilteredAssessmentResults || !this.getUnfilteredAssessmentResults.assessments) {
        console.warn("No unfiltered assessment results available.");
        return { insightAnswers: {}, pathway: null, isReassessment: false };
      }

      const mindsetAssessment = this.getUnfilteredAssessmentResults.assessments.find(
        (assessment) => assessment.assessmentName === process.env.VUE_APP_SURVEY_ASSESSMENT_NAME || process.env.VUE_APP_SURVEY_ASSESSMENT_NAME_TAKE_INITIAL
      );

      if (!mindsetAssessment) {
        console.warn("No 'Mindset and Insight' assessment found.");
        return { insightAnswers: {}, pathway: null, isReassessment: false };
      }

      const insightQuestionSets = mindsetAssessment.questionSets.filter(
        (set) => set.category === process.env.VUE_APP_SURVEY_INSIGHT_CATEGORY
      );

      if (insightQuestionSets.length === 0) {
        console.warn("No 'insight' question set found.");
        return { insightAnswers: {}, pathway: null, isReassessment: false };
      }

      const insightAnswers = {};

      insightQuestionSets.forEach(set => {
        set.questions.forEach(question => {
          if (question && question.id && Array.isArray(question.answer)) {
            insightAnswers[question.id] = question.answer;
          } else {
            console.warn("Found null or incorrectly structured question in set:", set);
          }
        });
      });

      const pathwayQuestionSet = mindsetAssessment.questionSets.find(
        (set) => set.category === process.env.VUE_APP_SURVEY_PATHWAY_CATEGORY
      );

      let pathway = null;
      if (pathwayQuestionSet) {
        const pathwayMatch = pathwayQuestionSet.questions[0]?.answer?.match(/Pathway [A-C]/);
        if (pathwayMatch) {
          pathway = pathwayMatch[0];
        } else {
          console.warn("No valid pathway answer found.");
        }
      } else {
        console.warn("No 'PathwayForCustomerJourney' question set found.");
      }

      const isReassessmentQuestionSet = mindsetAssessment.questionSets.find(
        (set) => set.category === 'IsThisaReAssessment'
      );

      let isReassessment = false;
      if (isReassessmentQuestionSet) {
        isReassessment = isReassessmentQuestionSet.questions[0]?.answer;
      }

      return { insightAnswers, pathway, isReassessment };
    },
    populateSurveyWithAnswers(answers, pathway, isReassessment, surveyMarkup) {
      function updateElements(elements) {
        if (!Array.isArray(elements)) {
          console.warn("Elements is not an array:", elements);
          return;
        }

        elements.forEach(element => {
          if (!element) {
            console.warn("Found null element:", element);
            return;
          }

          if (element.name && answers[element.name] !== undefined) {
            element.defaultValue = answers[element.name];
          } else if (element.name === "CustomerPathwayResult" && pathway) {
            element.defaultValue = pathway;
          } else if (element.name === "IsThisaReAssessment") {
            element.defaultValue = isReassessment = false;
          }

          if (element.elements) {
            updateElements(element.elements);
          }
        });
      }

      function updateAssessments(elements) {
        if (!Array.isArray(elements)) {
          console.warn("Elements is not an array:", elements);
          return;
        }

        elements.forEach(element => {
          if (!element) {
            console.warn("Found null element:", element);
            return;
          }

          if (element.name === "CustomerPathwayResult" && pathway) {
            element.defaultValue = pathway;
          } else if (element.name === "IsThisaReAssessment") {
            element.defaultValue = true;
          }

          if (element.elements) {
            updateAssessments(element.elements);
          }
        });
      }

      if (surveyMarkup && Array.isArray(surveyMarkup.pages)) {
        surveyMarkup.pages.forEach(page => {
          if (this.getActionSubjectById == "Take Themed Assessment RESTART Extension"  && Array.isArray(page.elements)) {
            updateElements(page.elements);
          } else if (this.getActionSubjectById == "Test Mindset" && Array.isArray(page.elements)) {
            updateAssessments(page.elements);
          }
        });
      } else {
        console.warn("Survey data or pages is invalid:", surveyMarkup);
      }

      return surveyMarkup;
    }
  },
  async mounted() {
    const version = router.currentRoute.value.params.version;
    const relatedActionId = router.currentRoute.value.query.relatedActionId;

    await this.GetUnfilteredAssessmentResults();
    await this.GetThemedAssessmentJson(version, relatedActionId);
    await this.GetActionSubjectById(relatedActionId);

    const { insightAnswers, pathway, isReassessment } = this.extractInsightAndPathway();
    let updatedMarkup = this.getThemedAssessmentJson.markup;

    if (Object.keys(insightAnswers).length > 0 || pathway || isReassessment) {
      updatedMarkup = this.populateSurveyWithAnswers(insightAnswers, pathway, isReassessment, updatedMarkup);
    }

    const creator = new SurveyCreator(creatorOptions);
    creator.text = JSON.stringify(updatedMarkup);
    creator.saveSurveyFunc = (saveNo, callback) => {
      window.localStorage.setItem("survey-json", creator.text);
      callback(saveNo, true);
    };
    creator.render("surveyCreator");
  },
};
</script>

<style scoped>
#surveyCreator {
  height: 90vh;
  width: 100vw;
}
</style>
