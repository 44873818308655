<template>
<div :id="id">
    <div hidden><button class="clear-values-btn" @click="clearValues()"></button></div>
    <div><b>Set Start Date:</b></div>
    <input class="start-date-input-upt" type="date" :min="getDateInputString(new Date(new Date().toUTCString()))" v-model="newDate"/>
    <div class="text-danger" v-show="error != ''">{{error}}</div>
    <div>
        <button class="btn" disabled v-show="newDate==null">Save</button>
        <button class="btn" @click="saveStartDate()" v-show="newDate!=null">Save</button>
    </div>
</div>
</template>
<script>
import { useAdvisorDashboardStore } from '@/store/advisorDashboard';
import { useCommonStore } from '@/store/commonStore';
import { mapActions, mapState } from 'pinia';

export default {
    name:"update-action-start",
    data(){
        return{
            newDate: null,
            error: ''
        }
    },
    props:{
        id:{
            type: String,
            default: "updt-act-start"
        }
    },
    computed:{
        ...mapState(useAdvisorDashboardStore,["getCurrentActionToUpdate"])
    },
    methods:{
        ...mapActions(useCommonStore,["UpdateActionStartDate", "UpdateActionStateCode", "UpdateStartThenMoveToCurrent", "getUTCDateString"]),
        ...mapActions(useAdvisorDashboardStore,["setCurrentActionToUpdate", "toggleModal"]),
         getDateInputString(date){
            return [date.getFullYear(),
                    date.getMonth()+1 < 10 
                    ? '0' + (date.getMonth()+1).toString() 
                    : date.getMonth()+1,
                    date.getDate() < 10 
                    ? '0' + (date.getDate()).toString() 
                    :date.getDate()].join('-');
        },
        getDateFormatString(date){
            return [[date.getFullYear(),
                    date.getMonth()+1 < 10 
                    ? '0' + (date.getMonth()+1).toString() 
                    : date.getMonth()+1,
                    date.getDate() < 10 
                    ? '0' + (date.getDate()).toString() 
                    :date.getDate()].join('-'),
                    [date.getHours()+1 < 10 
                    ? '0' + (date.getHours()+1).toString() 
                    : date.getHours()+1,
                    date.getMinutes()+1 < 10 
                    ? '0' + (date.getMinutes()+1).toString() 
                    : date.getMinutes()+1].join(':')].join('T');
        },
        saveStartDate(){
            if(new Date(this.getCurrentActionToUpdate.timeline.scheduledEnd) < new Date(this.getUTCDateString(new Date(this.newDate)))){
                this.error = `Start can't be after due date (${this.getDateInputString(new Date(this.getCurrentActionToUpdate.timeline.scheduledEnd))})`;
                return;
            }
            this.error = '';
            this.UpdateStartThenMoveToCurrent(this.getUTCDateString(new Date(this.newDate)), this.getCurrentActionToUpdate.id, true);
            this.toggleModal();
            this.setCurrentActionToUpdate(null);
            this.newDate = null;
        },
        clearValues(){
            this.newDate = null;
            this.error ='';
        }
    },
    mounted(){
        // document.getElementById("modal-close").addEventListener("click", ()=>{
        //     this.newDate = null;
        //     this.error ='';
        // })
    }
}
</script>
<style scoped>
input{
    border: none;
    border-radius: 0.2rem;
    background-color: rgb(243 243 243);
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    color: #67748e;
    font-size: medium;
    padding: 0.2rem 0.5rem;
    margin: 0.8rem 0;
}
</style>