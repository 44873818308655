import axios from 'axios';
import { TryGetCachedToken } from "../router/TokenManger";
const BaseUrl = process.env.VUE_APP_PIP_BASE_URL;
const envVars = process.env;
export default{
    async GetPipCase(customerId){
        try{
            if(envVars.VUE_APP_AUTH_ON == "true"){
                const token = await TryGetCachedToken();
                axios.interceptors.request.use(config => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
                });
            }
            const resp = await axios({
                method: 'get',
                url: BaseUrl 
                    + envVars.VUE_APP_GET_PIP_CASE.replace("{customerId}", customerId),
                responseType: 'json',
                Headers:{
                    "Access-Control-Allow-Origin": "*"
                }
              });
            return resp;
        }
        catch(error){
            return error;
        }
    }
}