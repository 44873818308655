import { AquireApiAuthToken } from "../router/Guard";
import { cachedBearer } from "../main";

export async function TryGetCachedToken(){
    try{
        if(cachedBearer.value != null){
            if(addMinutes(new Date(cachedBearer.date),+30) > new Date()){
                return cachedBearer.value;
            }
        }
        const newToken = await AquireApiAuthToken();
        cachedBearer.value = newToken; 
        cachedBearer.date = new Date();
        return newToken;
    }
    catch(error){
        return error;
    }
}

function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
}